
/**
 * 实例化时需传入Viewer和 漫游路线route
 * currentStopIndex 当前站点索引，将从此站点开始飞行漫游
 * route 飞行漫游的路线
 * initialHeading 记录上一段飞行的方位角
 * headingAngle 记录节点处转向的角度
 * status 飞行状态，0表示未飞行，1表示飞行中
 * stopArrived 站点到达事件
 */
class FlyManager {
  constructor (context, viewer) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._context = context
    this._viewer = viewer
    this._route = null
    this._currentStopIndex = 0
    this._initialHeading = this.viewer.camera.heading
    this._headingAngle = 0
    this._status = 0
    this._stopArrived = new Cesium.Event()
    // 自定义摄像机高度
    this._cameraHeight = undefined
    // 摄像机镜头偏移
    this._headingOffset = 0
    this._pitchOffset = 0
    this._rollOffset = 0
    // 播放加速
    this._multiplier = 1
    // 转向插值计算开始位置
    this._turnLerp = 0
    // 播放完回调
    this._onFinished = undefined
  }

  get viewer () {
    return this._viewer
  }

  get stopArrived () {
    return this._stopArrived
  }

  get route () {
    return this._route
  }

  set route (v) {
    this._route = v
  }

  get status () {
    return this._status
  }

  set status (v) {
    this._status = v
  }

  get cameraHeight () {
    return this._cameraHeight
  }

  set cameraHeight (v) {
    this._cameraHeight = v
  }

  get headingOffset () {
    return this._headingOffset
  }

  set headingOffset (v) {
    this._headingOffset = v
  }

  get pitchOffset () {
    return this._pitchOffset
  }

  set pitchOffset (v) {
    this._pitchOffset = v
  }

  get rollOffset () {
    return this._rollOffset
  }

  set rollOffset (v) {
    this._rollOffset = v
  }

  get multiplier () {
    return this._multiplier
  }

  set multiplier (v) {
    this._multiplier = v
    this.viewer.clock.multiplier = v
  }

  get turnLerp () {
    return this._turnLerp
  }

  set turnLerp (v) {
    this._turnLerp = v
  }

  get currentStopIndex () {
    return this._currentStopIndex
  }

  set currentStopIndex (v) {
    this._currentStopIndex = v
  }

  // cesium 事件消息
  onCesiumEvent (type, event) {

  }

  play (onFinished) {
    if (!this.status) {
      this.status = 1
      const stopGroup = this.route.stopGroup
      this.setCesiumClock(stopGroup[this.currentStopIndex].duration)
      this.viewer.clock.onTick.addEventListener(this.flyProcess, this)
      this._stopArrived.raiseEvent(stopGroup[this.currentStopIndex])
      this._onFinished = onFinished
    }
  }

  pause () {
    if (this.status) {
      this.viewer.clock.shouldAnimate = false
    }
  }

  resume () {
    if (this.status) {
      this.viewer.clock.shouldAnimate = true
    }
  }

  isPause () {
    return !this.viewer.clock.shouldAnimate
  }

  stop () {
    this.status = 0
    this.currentStopIndex = 0
    this.viewer.clock.onTick.removeEventListener(this.flyProcess, this)
    this.viewer.clock.clockRange = Cesium.ClockRange.UNBOUNDED
    this.viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER
    this.viewer.clock.shouldAnimate = true
    this.multiplier = 1
    this.headingOffset = 0
    this.pitchOffset = 0
    this.rollOffset = 0
    this.cameraHeight = undefined
    this.turnLerp = 0
  }

  isPlaying () {
    return this.status === 1
  }

  /**
   * 设置cesium clock，设置为一个时间段，用于一个点到下一个点的飞行时间或者绕点旋转时间
   * @param timeLength
   */
  setCesiumClock (timeLength) {
    const startTime = Cesium.JulianDate.fromDate(new Date())
    const stopTime = Cesium.JulianDate.addSeconds(startTime, timeLength, new Cesium.JulianDate())
    this.viewer.clock.startTime = startTime.clone() // 开始时间
    this.viewer.clock.stopTime = stopTime.clone() // 结速时间
    this.viewer.clock.currentTime = startTime.clone() // 当前时间
    this.viewer.clock.clockRange = Cesium.ClockRange.CLAMPED // 行为方式
    // this.viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK // 时钟设置为当前系统时间; 忽略所有其他设置。
  }

  /**
   * 从一个点飞行到另一个点的插值处理过程
   * @returns {boolean}
   */
  flyProcess () {
    const nextIndex = this.currentStopIndex + 1
    const stopGroup = this.route.stopGroup
    if (nextIndex === stopGroup.length) {
      return false
    }
    const stepTime = Cesium.JulianDate.secondsDifference(this.viewer.clock.currentTime, this.viewer.clock.startTime)
    const geoCoordinates = this.getGeoCoordinates(stopGroup[this.currentStopIndex].point, stopGroup[nextIndex].point)
    const { originLng, originLat, originHeight, targetLng, targetLat, targetHeight } = {
      ...geoCoordinates
    }
    const duration = stopGroup[this.currentStopIndex].duration
    const endPosition = Cesium.Cartesian3.fromDegrees(
      (originLng + (targetLng - originLng) / duration * stepTime),
      (originLat + (targetLat - originLat) / duration * stepTime),
      (originHeight + (targetHeight - originHeight) / duration * stepTime)
    )
    // 方向插值计算
    let orientation = { heading: 0, pitch: 0, roll: 0 }
    const lerp = stepTime / duration
    if (lerp >= this.turnLerp && this.turnLerp !== 1) {
      const oriLerp = (lerp - this.turnLerp) / (1 - this.turnLerp)
      orientation = this.lerpOrientation(stopGroup[this.currentStopIndex], stopGroup[nextIndex], oriLerp)
    } else {
      orientation = {
        heading: stopGroup[this.currentStopIndex].heading,
        pitch: stopGroup[this.currentStopIndex].pitch,
        roll: stopGroup[this.currentStopIndex].roll
      }
    }

    this.viewer.scene.camera.setView({
      destination: endPosition,
      orientation: {
        heading: Cesium.Math.toRadians(orientation.heading + this.headingOffset),
        pitch: Cesium.Math.toRadians(orientation.pitch + this.pitchOffset),
        roll: Cesium.Math.toRadians(orientation.roll + this.rollOffset)
      }
    })
    if (Cesium.JulianDate.compare(this.viewer.clock.currentTime, this.viewer.clock.stopTime) >= 0) {
      this.viewer.clock.onTick.removeEventListener(this.flyProcess, this)
      if (nextIndex === stopGroup.length - 1) {
        if (!this.route.isFlyLoop) {
          this.status = 0
          this.viewer.clock.clockRange = Cesium.ClockRange.UNBOUNDED
          this.viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER
          this.viewer.clock.shouldAnimate = true
          this.currentStopIndex = 0
          // 播放结束回调
          if (this._onFinished) {
            this._onFinished()
          }
          return false
        }
        this.currentStopIndex = 0
        this.setCesiumClock(stopGroup[this.currentStopIndex].duration)
        this.viewer.clock.onTick.addEventListener(this.flyProcess, this)
        return false
      }
      this._stopArrived.raiseEvent(stopGroup[nextIndex])
      // 直接下一个节点
      this.currentStopIndex++
      this.setCesiumClock(stopGroup[this.currentStopIndex].duration)
      this.viewer.clock.onTick.addEventListener(this.flyProcess, this)
    }
  }

  /**
   * 方向插值计算
   */
  lerpOrientation (startStop, endStop, lerp) {
    let curHeading = startStop.heading
    const nextHeading = endStop.heading
    if (curHeading - nextHeading > 180) {
      curHeading = curHeading - 360
    } else if (curHeading - nextHeading < -180) {
      curHeading = curHeading + 360
    }
    const heading = Cesium.Math.lerp(curHeading, nextHeading, lerp)
    const curPitch = startStop.pitch
    const nextPitch = endStop.pitch
    const pitch = Cesium.Math.lerp(curPitch, nextPitch, lerp)
    // const roll = Cesium.Math.lerp(startStop.roll, endStop.roll, lerp)
    return { heading: heading, pitch: pitch, roll: 0 }
  }

  /**
   * 根据笛卡尔坐标获取对应经纬度坐标
   * @param firstPoint
   * @param secondPoint
   * @returns {{originLng: (Number|number|*), originHeight: number, targetHeight: number, originLat: (Number|number|*), targetLng: (Number|number|*), targetLat: (Number|number|*)}}
   */
  getGeoCoordinates (firstPoint, secondPoint) {
    const originCtg = Cesium.Cartographic.fromCartesian(firstPoint)
    const originLng = Cesium.Math.toDegrees(originCtg.longitude)
    const originLat = Cesium.Math.toDegrees(originCtg.latitude)
    let originHeight = originCtg.height
    const targetCtg = Cesium.Cartographic.fromCartesian(secondPoint)
    const targetLng = Cesium.Math.toDegrees(targetCtg.longitude)
    const targetLat = Cesium.Math.toDegrees(targetCtg.latitude)
    let targetHeight = targetCtg.height
    // 自定义高度
    if (this.cameraHeight !== undefined) {
      originHeight = this.cameraHeight
      targetHeight = this.cameraHeight
    }
    return {
      originLng,
      originLat,
      originHeight,
      targetLng,
      targetLat,
      targetHeight
    }
  }

  /**
   * 根据两点经纬度计算方向
   * @param points
   * @returns {number}
   */
  computeDirection (points) {
    const startLat = Cesium.Math.toRadians(points[0])
    const startLng = Cesium.Math.toRadians(points[1])
    const destLat = Cesium.Math.toRadians(points[2])
    const destLng = Cesium.Math.toRadians(points[3])

    const y = Math.sin(destLng - startLng) * Math.cos(destLat)
    const x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
    const direction = Math.atan2(y, x)
    const directionDegrees = Cesium.Math.toDegrees(direction)
    return (directionDegrees + 360) % 360
  }

  /**
   * 视图定位到站点
   * @param stop 站点
   */
  viewerToStop (stop) {
    this.viewer.scene.camera.setView({
      destination: stop.point,
      orientation: {
        heading: stop.heading,
        pitch: stop.pitch,
        roll: stop.roll
      }
    })
  }

  destroy () {

  }
}
export default FlyManager
