/**
 * 围墙通用纹理
 * @param {Canvas} [options.image] 滚动纹理
 * @param {Cesium.Color} [options.color] 颜色
 * @param {Number} [options.glowPower] 滚动发光条高度
 * @param {Number} [options.speed] 纹理滚动速度
 * @param {Number} [options.width] 一张图片宽度
 * @param {Number} [options.length] 图形长度
 */
function WallCommonMaterailProperty (options) {
  this._definitionChanged = new Cesium.Event()

  const _options = Cesium.defaultValue(options, {})
  this._image = Cesium.defaultValue(_options.image, '../Assets/Textures/baise.png')
  this._color = Cesium.defaultValue(_options.color, Cesium.Color.WHITE)
  this._horizontalSpeed = Cesium.defaultValue(_options.horizontalSpeed, 100) / 1000
  this._verticalSpeed = Cesium.defaultValue(_options.verticalSpeed, 100) / 1000
  this._horizontalRepeat = Cesium.defaultValue(_options.horizontalRepeat, 1)
  this._verticalRepeat = Cesium.defaultValue(_options.verticalRepeat, 1)

  this.time = new Date().getTime()
}

/**
 * 绑定Cesium消息
 */
Object.defineProperties(WallCommonMaterailProperty.prototype, {
  isConstant: {
    get: function () {
      return false
    }
  },
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
  color: Cesium.createPropertyDescriptor('color')
})

/**
 * 返回类型
 */
WallCommonMaterailProperty.prototype.getType = function (time) {
  return Cesium.Material.WallCommonMaterailType
}

WallCommonMaterailProperty.prototype.clone = function (time) {
  return new WallCommonMaterailProperty({
    image: this._image,
    color: this._color.clone(),
    horizontalSpeed: this._horizontalSpeed * 1000,
    verticalSpeed: this._verticalSpeed * 1000,
    horizontalRepeat: this._horizontalRepeat,
    verticalRepeat: this._verticalRepeat
  })
}

WallCommonMaterailProperty.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof WallCommonMaterailProperty &&
      this._image === other._image &&
      Cesium.Color.equals(this._color, other._color) &&
      this._horizontalSpeed === other._horizontalSpeed &&
      this._verticalSpeed === other._verticalSpeed &&
      this._horizontalRepeat === other._horizontalRepeat &&
      this._verticalRepeat === other._verticalRepeat))
}

/**
 * 给定时间获取时间属性返回值 由Cesium中Material调用
 */
WallCommonMaterailProperty.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }

  result.image = this._image
  result.color = this._color
  result.horizontalRepeat = this._horizontalRepeat
  result.verticalRepeat = this._verticalRepeat

  // result.width = this._width
  // result.length = this._length
  // result.speed = this._speed
  if (!this._horizontalSpeed) {
    result.horizontalTime = 0.0
  } else {
    const horizontalTime = (1 / this._horizontalSpeed) * 1000
    result.horizontalTime = ((new Date().getTime() - this.time) % horizontalTime) / horizontalTime
  }

  if (!this._verticalSpeed) {
    result.verticalTime = 0.0
  } else {
    const verticalTime = (1 / this._verticalSpeed) * 1000
    result.verticalTime = ((new Date().getTime() - this.time) % verticalTime) / verticalTime
  }

  // const totalTime = this._totalTime
  // result.time = ((new Date().getTime() - this.time) % totalTime) / totalTime
  return result
}

// Cesium.WallCommonMaterailProperty = WallCommonMaterailProperty;
Cesium.Material.WallCommonMaterailType = 'WallCommonMaterail'
Cesium.Material.WallCommonMaterailLinkSource =
  `uniform sampler2D image;
  uniform vec4 color;
  uniform float horizontalRepeat;
  uniform float verticalRepeat;
  uniform float horizontalTime;
  uniform float verticalTime;

  czm_material czm_getMaterial(czm_materialInput materialInput)
 {
    czm_material material = czm_getDefaultMaterial(materialInput);
    vec2 st = materialInput.st;

    float s = fract((st.s - horizontalTime)/(1.0/horizontalRepeat));
    float t = fract((st.t - verticalTime)/(1.0/verticalRepeat));
    // float s = st.s;
    // float t = st.t;
    vec4 colorImage = texture2D(image, vec2(s,t));
    // vec4 colorImage = texture2D(image, vec2(t,s));
    
    material.alpha = colorImage.a * color.a;
    material.diffuse = colorImage.rgb * color.rgb;
    return material;
 }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.WallCommonMaterailType, {
  fabric: {
    type: Cesium.Material.WallCommonMaterailType,
    uniforms: {
      image: '../Assets/Textures/baise.png',
      color: Cesium.Color.WHITE,
      horizontalSpeed: 0,
      verticalSpeed: 0,
      horizontalRepeat: 1,
      horizontalTime: 0,
      verticalRepeat: 1,
      verticalTime: 0
    },
    source: Cesium.Material.WallCommonMaterailLinkSource
  },

  translucent: function (material) {
    return true
  }
})

Cesium.WallCommonMaterailProperty = WallCommonMaterailProperty
