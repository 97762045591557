import BillboardStyle from '../Marker/MarkerStyle/BillboardStyle'
import BillboardCluster from './BillboardCluster'
// 公告板图元集合
class BillboardBundle {
  #viewer = undefined
  layerId = undefined
  style = undefined
  collection = undefined
  billboardMap = {}
  #collectionChanged = undefined
  billboardCluster = undefined
  constructor (viewer, options) {
    this.#viewer = viewer
    const _options = Cesium.defaultValue(options, {})
    this.layerId = Cesium.defaultValue(_options.id, undefined)
    this.style = Cesium.defaultValue(_options.style, BillboardStyle.Default())
    this.datas = Cesium.defaultValue(_options.datas, [])
    const cluster = Cesium.defaultValue(_options.cluster, false)
    this.collection = new Cesium.BillboardCollection()
    this.collection.layerId = this.layerId
    this.#collectionChanged = new Cesium.Event()
    // 添加数据
    this.datas.forEach(item => {
      this.add({
        position: item.position,
        id: item.id
      })
    })
    this.#viewer.scene.primitives.add(this.collection)
    this.enableCluster = cluster
  }

  get viewer () {
    return this.#viewer
  }

  /**
   * 获取是否聚合
   */
  get enableCluster () {
    if (!this.billboardCluster) {
      return false
    }
    return this.billboardCluster.enabled
  }

  /**
   * 设置是否聚合
   */
  set enableCluster (value) {
    if (value && !this.billboardCluster) {
      this.billboardCluster = new BillboardCluster(this)
    }
    if (this.billboardCluster) {
      this.billboardCluster.enabled = value
    }
  }

  /**
   * 添加一个公告板
   * @param {Objcet} options 参数
   * @param {Objcet} options.id 公告板唯一id
   * @param {Array} options.position [经度, 纬度, 高度] ,经度、纬度为角度格式，高度单位米(可选，默认0)
   */
  add (options) {
    const _options = Cesium.defaultValue(options, {})
    const position = Cesium.defaultValue(_options.position, [0, 0, 0])
    const id = Cesium.defaultValue(_options.id, undefined)
    const billboardId = `billboard-${this.layerId}-${id}`
    const lon = isNaN(parseFloat(position[0])) ? undefined : parseFloat(position[0])
    const lat = isNaN(parseFloat(position[1])) ? undefined : parseFloat(position[1])
    const height = isNaN(parseFloat(position[2])) ? 0 : parseFloat(position[2])
    if (!lon || !lat) {
      console.log('position error---------', id, position)
      return
    }
    const billboard = this.collection.add({
      id: billboardId,
      image: this.style._image,
      color: this.style._color,
      scale: this.style._scale,
      width: this.style._width,
      height: this.style._height,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      position: Cesium.Cartesian3.fromDegrees(lon, lat, height)
    })
    billboard.dataId = id
    this.billboardMap[billboardId] = billboard
  }

  /**
   * 设置公告板样式
   * @param {BillboardStyle} style 公告板样式
   */
  updateStyle (style) {
    this.style = style
    for (let i = 0; i < this.collection.length; ++i) {
      const b = this.collection.get(i)
      b.image = style._image
      b.color = style._color
      b.scale = style._scale
      b.width = style._width
      b.height = style._height
    }
  }

  /**
   * 更新公告板位置
   * @param {Objcet} options.id 公告板唯一id
   * @param {Array} options.position [经度, 纬度, 高度] ,经度、纬度为角度格式，高度单位米(可选，默认0)
   */
  updatePosition (id, position) {
    let billboardId = id
    if (!billboardId.startsWith('billboard-')) {
      billboardId = `billboard-${this.layerId}-${id}`
    }
    if (this.billboardMap[billboardId]) {
      const lon = isNaN(parseFloat(position[0])) ? undefined : parseFloat(position[0])
      const lat = isNaN(parseFloat(position[1])) ? undefined : parseFloat(position[1])
      const height = isNaN(parseFloat(position[2])) ? 0 : parseFloat(position[2])
      if (!lon || !lat) {
        console.log('position error---------', id, position)
        return
      }
      this.billboardMap[billboardId].position = Cesium.Cartesian3.fromDegrees(lon, lat, height)
      if (this.billboardCluster) {
        this.billboardCluster.updateClusterItem()
      }
    }
  }

  // 更新数据
  updateDatas (datas) {
    datas.forEach(item => {
      const billboardId = `billboard-${this.layerId}-${item.id}`
      if (this.billboardMap[billboardId]) {
        this.updatePosition(billboardId, item.position)
      } else {
        this.add(item)
      }
    })
    if (this.billboardCluster) {
      this.billboardCluster.updateClusterItem()
    }
  }

  get show () {
    return this.collection.show
  }

  set show (isShow) {
    this.collection.show = isShow
    if (this.billboardCluster) {
      this.billboardCluster.show = isShow
    }
  }

  /**
   * 移除公告板
   * @param {String} id 公告板id
   */
  remove (id) {
    let billboardId = id
    if (!billboardId.startsWith('billboard-')) {
      billboardId = `billboard-${this.layerId}-${id}`
    }
    if (this.billboardMap[billboardId]) {
      if (this.billboardCluster) {
        this.billboardCluster.removeClusterItem(this.billboardMap[id])
      }
      this.collection.remove(this.billboardMap[billboardId])
      delete this.billboardMap[billboardId]
    }
  }

  /**
   * 获取公告板
   * @param {String} id 公告板id
   * @returns Billboard
   */
  getBillboard (id) {
    let billboardId = id
    if (!billboardId.startsWith('billboard-')) {
      billboardId = `billboard-${this.layerId}-${id}`
    }
    return this.billboardMap[billboardId]
  }

  /**
   * 遍历聚合中所有公告板
   * @param {Function} func 遍历函数
   */
  forEach (func) {
    Object.values(this.billboardMap).forEach(item => {
      func(item)
    })
  }

  /**
   * 销毁所有公告板
   */
  destroy () {
    this.#viewer.scene.primitives.remove(this.collection)
    this.collection = undefined
    this.billboardMap = {}
    this.billboardCluster.destroy()
    this.billboardCluster = undefined
  }
}

export default BillboardBundle
