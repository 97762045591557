import BillboardBundle from './BillboardBundle'

class PrimitiveManager {
  #context = undefined
  #viewer = undefined
  #bundleMap = {}
  constructor (context, viewer) {
    this.#context = context
    this.#viewer = viewer
  }

  /**
   * 创建一个公告板图层
   * @param {Object} options 配置
   * @param {String} options.id 图层id
   * @param {BillboardStyle} options.style 公告板样式
   * @param {Array} options.datas 数据列表[{id:String, position:[longitude, latitude, height]}]
   * @param {Boolean} options.cluster 是否聚合，默认false
   */
  createBillboardBundle (options) {
    const source = new BillboardBundle(this.#viewer, options)
    this.#bundleMap[options.id] = source
  }

  /**
   * 通过图层id获取公告板图层
   * @param {String} id 图层id
   * @returns {BillboardBundle} 返回一个公告板图层
   */
  getBundle (id) {
    return this.#bundleMap[id]
  }

  /**
   * 通过图层id删除公告板图层
   * @param {String} id 图层id
   */
  remvoeBundle (id) {
    if (this.#bundleMap[id]) {
      this.#bundleMap[id].destroy()
      delete this.#bundleMap[id]
    }
  }

  /**
   * 显示/隐藏公告板图层
   * @param {String} id 图层id
   * @param {Boolean} isShow 是否显示图层
   */
  showBundle (id, isShow) {
    if (this.#bundleMap[id]) {
      this.#bundleMap[id].show = isShow
    }
  }
}

export default PrimitiveManager
