/**
 * 点标注编辑类
 */
import BaseEditor from './BaseEditor'
import DrawMode from '../../DrawHandler/DrawMode'
import CustomEvent from '../../Common/CustomEvent'
import markerImage from '../../Assets/Images/marker.png'

class PointEditor extends BaseEditor {
  constructor (context, gisPosition) {
    super(context)
    this._type = DrawMode.Marker
    this._viewEntity = this._context.viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(gisPosition[0], gisPosition[1], gisPosition[2] ? gisPosition[2] : 0),
      billboard: {
        image: markerImage,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        color: Cesium.Color.fromCssColorString('#FF0000')
      }
    })
  }

  // cesium事件
  onCesiumEvent (type, event) {
    if (!this._actvie) {
      return
    }
    if (type === Cesium.ScreenSpaceEventType.LEFT_DOWN) {
      const object = this._context.viewer.scene.pick(event.position)
      if (object !== undefined && object.id !== undefined && object.id instanceof Cesium.Entity && object.id.id === this._viewEntity.id) {
        this.pressLeft = true
      }
    } else if (type === Cesium.ScreenSpaceEventType.MOUSE_MOVE) {
      if (this.pressLeft) {
        this.setTipsText('释放后完成修改')
        const position = this._context.viewer.scene.pickPosition(event.endPosition)
        if (!position) {
          return
        }
        this._viewEntity.position = position
        if (this._markerId) {
          this._context.dispatch(CustomEvent.MarkerPositionChanged, {
            id: this._markerId,
            position: this.getGisPosition()
          })
        }
      } else {
        const object = this._context.viewer.scene.pick(event.endPosition)
        if (object !== undefined && object.id !== undefined && object.id instanceof Cesium.Entity && object.id.id === this._viewEntity.id) {
          this.setTipsText('拖动可修改点的位置')
        } else {
          this.setTipsText('')
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.RIGHT_CLICK) {

    } else if (type === Cesium.ScreenSpaceEventType.LEFT_UP) {
      this.pressLeft = false
    }
    super.onCesiumEvent(type, event)
  }

  /**
   * 获取标注三维坐标
   * @returns 标注三维坐标
   */
  getPosition () {
    return this._viewEntity.position.getValue(Cesium.JulianDate.now())
  }

  /**
   * 获取标注gis坐标
   * @returns 标注gis坐标
   */
  getGisPosition () {
    const pos = this.getPosition()
    const gis = this._context.utils.cartesianToLonLat(pos)
    return [gis.longitude, gis.latitude, gis.height]
  }

  /**
   * 设置标注gis坐标
   * @param {Array} gisPosition 标注gis坐标
   */
  setGisPosition (gisPosition) {
    this._viewEntity.position = Cesium.Cartesian3.fromDegrees(gisPosition[0], gisPosition[1], gisPosition[2] ? gisPosition[2] : 0)
  }

  destroy () {
    super.destroy()
  }

  static fromMarker (context, marker) {
    const editor = new PointEditor(context, marker.getGisPosition())
    editor.markerId = marker.id
    if (!marker.selected) {
      editor.setStyle(marker.curStyles)
    } else {
      editor.setStyle(marker.lastStyles)
    }
    return editor
  }

  static fromGeoJson (context, geoJson) {
    const coordinates = JSON.parse(JSON.stringify(geoJson.geometry.coordinates))
    if (!coordinates[2] && geoJson.properties.height_) {
      coordinates[2] = JSON.parse(geoJson.properties.height_)[0]
    }
    const editor = new PointEditor(context, coordinates)
    editor.markerId = geoJson.properties.mark_id_
    return editor
  }
}

export default PointEditor
