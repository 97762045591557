/**
 * 围墙滚动纹理
 * @param {Canvas} [options.image] 滚动纹理
 * @param {Number} [options.glowPower] 滚动发光条高度
 * @param {Number} [options.speed] 纹理滚动速度
 * @param {Number} [options.width] 一张图片宽度
 * @param {Number} [options.length] 图形长度
 */
function WallRollMaterialProperty (options) {
  this._definitionChanged = new Cesium.Event()

  const _options = Cesium.defaultValue(options, {})
  this._image = Cesium.defaultValue(_options.image, Cesium.Material.DefaultImageId)
  this._color = Cesium.defaultValue(_options.color, Cesium.Color.WHITE)
  this._glowPower = Cesium.defaultValue(_options.glowPower, 0.02)
  this._speed = Cesium.defaultValue(_options.speed, 100) / 1000
  this._width = Cesium.defaultValue(_options.width, 100)
  this._length = Cesium.defaultValue(_options.length, this._width)
  this._totalTime = Cesium.defaultValue(_options.totalTime, 1) * 1000
  this.time = new Date().getTime()
}

/**
 * 绑定Cesium消息
 */
Object.defineProperties(WallRollMaterialProperty.prototype, {
  isConstant: {
    get: function () {
      return false
    }
  },
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
  color: Cesium.createPropertyDescriptor('color')
})

/**
 * 返回类型
 */
WallRollMaterialProperty.prototype.getType = function (time) {
  return Cesium.Material.WallRollMaterialType
}

WallRollMaterialProperty.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof WallRollMaterialProperty &&
      Cesium.Color.equals(this._color, other._color) &&
      this._image === other._image &&
      this._glowPower === other._glowPower &&
      this._totalTime === other._totalTime))
}

/**
 * 给定时间获取时间属性返回值 由Cesium中Material调用
 * 功能描述：实现颜色改变
 * @param {JulianDate} time Cesium内置时间
 * @param {material} result  Cesium内置材质
 */
WallRollMaterialProperty.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }

  result.image = this._image
  result.glowPower = this._glowPower
  result.width = this._width
  result.length = this._length
  result.color = this._color
  // result.length = this._length.getValue(time)
  // result.time = (((new Date()).getTime() - this.time) % this._duration) / this._duration;
  result.speed = this._speed
  const totalTime = this._totalTime
  result.time = ((new Date().getTime() - this.time) % totalTime) / totalTime
  return result
}

// Cesium.WallRollMaterialProperty = WallRollMaterialProperty;
Cesium.Material.WallRollMaterialType = 'WallRollMaterial'
Cesium.Material.WallRollMaterialLinkSource =
  `uniform vec4 color;
  czm_material czm_getMaterial(czm_materialInput materialInput)
 {
    czm_material material = czm_getDefaultMaterial(materialInput);
    vec2 st = materialInput.st;
    float repeat = 1.0;

    float t = fract(abs(st.t - time)/(1.0/repeat));
    vec4 colorImage = texture2D(image, st);
    
    float glow = glowPower / abs(t - 0.5) - (glowPower / 0.5);
    
    vec4 fragColor;
    fragColor.rgb = max(vec3(glow - 1.0 + colorImage.rgb), colorImage.rgb);
    fragColor.a = clamp(0.0, 1.0, glow) * colorImage.a;
    fragColor = czm_gammaCorrect(fragColor);
    
     material.alpha = fragColor.a;
     material.emission = colorImage.rgb * color.rgb;
     return material;
 }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.WallRollMaterialType, {
  fabric: {
    type: Cesium.Material.WallRollMaterialType,
    uniforms: {
      image: Cesium.Material.DefaultImageId,
      color: Cesium.Color.WHITE,
      glowPower: 0.02,
      width: 100,
      length: 100,
      time: 0,
      speed: 100,
      totalTime: 1
    },
    source: Cesium.Material.WallRollMaterialLinkSource
  },

  translucent: function (material) {
    return true
  }
})

Cesium.WallRollMaterialProperty = WallRollMaterialProperty
