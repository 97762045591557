const Cesium = window.Cesium

class FlyPathPlayer {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer
    // 当前路径DataSources
    this.curDataSources = undefined
    // 播放速度
    this.clockMultiplier = 1
    this.stopTime = undefined
    // 摄像机平滑度
    this.smooth = 1
    // 保存摄像机最后位置
    this.lastCameraPos = undefined

    const _self = this
    this.onTrackCamera = function (clock) {
      const _entity = _self._viewer.cameraEntity
      if (_entity === undefined) { return }
      const position = _entity.position.getValue(clock.currentTime)
      const nextTime = Cesium.JulianDate.addSeconds(clock.currentTime, clock.multiplier * _self.smooth, new Cesium.JulianDate())
      let nextPosition = _entity.position.getValue(nextTime)
      if (nextPosition === undefined && _self.stopTime !== undefined) {
        nextPosition = _entity.position.getValue(_self.stopTime)
      }
      if (position === undefined || nextPosition === undefined || Cesium.Cartesian3.equals(position, nextPosition)) { return }
      let dir = Cesium.Cartesian3.subtract(nextPosition, position, new Cesium.Cartesian3())
      dir = Cesium.Cartesian3.normalize(dir, new Cesium.Cartesian3())
      _self._viewer.scene.camera.position = position
      _self._viewer.scene.camera.setView({
        destination: position,
        orientation: {
          direction: dir,
          up: new Cesium.Cartesian3(0, 0, 0)
        }
      })
    }
    this._viewer.clock.onTick.addEventListener(this.onTrackCamera)
  }

  play (flyPath) {
    const czml = flyPath.getCzmlObject()
    this.playFromCzml(czml)
  }

  playFromCzml (czml) {
    const _self = this
    if (this.curDataSources !== undefined) {
      this._viewer.dataSources.remove(this.curDataSources, true)
      this.curDataSources = undefined
      this.lastCameraPos = undefined
      this.stopTime = undefined
    }
    this._viewer.dataSources.add(Cesium.CzmlDataSource.load(czml)).then(function (ds) {
      _self.curDataSources = ds
      if (ds.clock !== undefined) {
        _self.clockMultiplier = ds.clock.multiplier
        _self.stopTime = ds.clock.stopTime
      }
      const entity = ds.entities.getById('point')
      if (entity.properties.track !== undefined && entity.properties.track.getValue(_self._viewer.clock.currentTime) === true) {
        _self.lastCameraPos = {
          position: _self._viewer.scene.camera.position,
          direction: _self._viewer.scene.camera.direction,
          up: _self._viewer.scene.camera.up,
          heading: _self._viewer.scene.camera.heading,
          pitch: _self._viewer.scene.camera.pitch,
          roll: _self._viewer.scene.camera.roll
        }
        _self._viewer.cameraEntity = ds.entities.getById('point')
        _self.smooth = entity.properties.smooth.getValue(_self._viewer.clock.currentTime)
        _self.smooth = Cesium.defaultValue(_self.smooth, 1)
      } else {
        _self._viewer.cameraEntity = undefined
      }
    })
  }

  stop () {
    this._viewer.dataSources.remove(this.curDataSources, true)
    this._viewer.cameraEntity = undefined
    this.curDataSources = undefined
    this.stopTime = undefined
    if (this.lastCameraPos !== undefined) {
      this._viewer.scene.camera.position = this.lastCameraPos.position
      this._viewer.scene.camera.setView({
        orientation: {
          heading: this.lastCameraPos.heading,
          pitch: this.lastCameraPos.pitch,
          roll: this.lastCameraPos.roll
        }
      })
    }
    this.lastCameraPos = undefined
  }
}

export default FlyPathPlayer
