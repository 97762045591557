// 注入cesium内部方法
import './Material/WallRollMaterialProperty'
import './Material/PolylineGlowExMaterialProperty'
import './Material/PolygonRimLightMaterialProperty'
import './Material/PolylineFlowMaterialProperty'
import './Material/WallCommonMaterailProperty'
import './Material/PolylineFlowMaterialProperty2'
import './SkyBox/GroundSkyBox'

import CameraManager from './Camera/CameraManager'
import CanvasPicker from './DrawHandler/CanvasPicker'
import DrawMode from './DrawHandler/DrawMode'
import ClampMode from './Common/ClampMode'
import DrawHandler from './DrawHandler/DrawHandler'
import FlyManager from './FlyManager/FlyManager'
import FlyRoute from './FlyManager/FlyRoute'
import RouteStop from './FlyManager/RouteStop'
import Marker from './Marker/Marker'
import BillboardStyle from './Marker/MarkerStyle/BillboardStyle'
import LabelStyle from './Marker/MarkerStyle/LabelStyle'
import PointStyle from './Marker/MarkerStyle/PointStyle'
import PolygonStyle from './Marker/MarkerStyle/PolygonStyle'
import PolylineStyle from './Marker/MarkerStyle/PolylineStyle'
import WallStyle from './Marker/MarkerStyle/WallStyle'
import HeatStyle from './Marker/MarkerStyle/HeatStyle'
import MarkerManager from './Marker/MarkerManager'
import HeatMap from './HeatMap/HeatMap'
import HeatMapManager from './HeatMap/HeatMapManager'
import ImageryManager from './ImageryLayer/ImageryManager'
import LayerDefine from './ImageryLayer/LayerDefine'
import Cesium3DTilesetManager from './Cesium3DTile/Cesium3DTilesetManager'
import RotateAnimation from './Marker/MarkerEffect/RotateAnimation'
import PinBuilder from './Marker/PinBuilder'
import FlyPathPlayer from './FlyPathManager/FlyPathPlayer'
import FlyPathManager from './FlyPathManager/FlyPathManager'
import FlyPath from './FlyPathManager/FlyPath'
import MarkerEditorFactory from './Marker/MarkerEditor/MarkerEditorFactory'
import PrimitiveManager from './Primitive/PrimitiveManager'

import VolumeMeasure from './Analyze/VolumeMeasure'
import ViewShed3D from './Analyze/ViewShed3D/ViewShed3D'
import CompassView from './Widget/CompassView'
import StatusBar from './Widget/StatusBar'
import MeasuringScaleView from './Widget/MeasuringScaleView'
import CanvasZoom from './Widget/CanvasZoom'

import * as utils from './Common/cesiumUtil'
import CustomEvent from './Common/CustomEvent'
const Cesium = window.Cesium
// cesium 扩展接口
class CesiumPlus {
  static singleInstance = undefined

  constructor (viewer) {
    this._viewer = viewer
    this.utils = utils
    this.cameraManager = new CameraManager(this, this._viewer)
    this.markerManager = new MarkerManager(this, this._viewer)
    this.canvasPicker = new CanvasPicker(this, this._viewer)
    this.flyManager = new FlyManager(this, this._viewer)
    this.drawHandler = new DrawHandler(this, this._viewer, {})
    this.volumeMeasure = new VolumeMeasure(this, this._viewer)
    this.viewShed3D = new ViewShed3D(this, this._viewer)
    this.imageryManager = new ImageryManager(this, this._viewer)
    this.cesium3DTilesetManager = new Cesium3DTilesetManager(this, this._viewer)
    this.compassView = new CompassView(this, this._viewer)
    this.statusBar = new StatusBar(this, this._viewer)
    this.heatMapManager = new HeatMapManager(this, this._viewer)
    this.measuringScaleView = new MeasuringScaleView(this, this._viewer)
    this.flyPathPlayer = new FlyPathPlayer(this, this._viewer)
    this.flyPathManager = new FlyPathManager(this, this._viewer)
    this.markerEditorFactory = new MarkerEditorFactory(this, this._viewer)
    this.canvasZoom = new CanvasZoom(this, this._viewer)
    this.primitiveManager = new PrimitiveManager(this, this._viewer)

    // vue视图
    this._vueView = null

    // 注册cesium 事件
    this._eventHandler = new Cesium.ScreenSpaceEventHandler(this._viewer.canvas)
    const self = this
    this._eventHandler.setInputAction((event) => { self.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_CLICK, event) }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    this._eventHandler.setInputAction((event) => { self.onCesiumEvent(Cesium.ScreenSpaceEventType.MOUSE_MOVE, event) }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    this._eventHandler.setInputAction((event) => { self.onCesiumEvent(Cesium.ScreenSpaceEventType.RIGHT_CLICK, event) }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
    this._eventHandler.setInputAction((event) => { self.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_DOWN, event) }, Cesium.ScreenSpaceEventType.LEFT_DOWN)
    this._eventHandler.setInputAction((event) => { self.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_UP, event) }, Cesium.ScreenSpaceEventType.LEFT_UP)
    this.singleInstance = this
  }

  get viewer () {
    return this._viewer
  }

  get vueView () {
    return this._vueView
  }

  set vueView (value) {
    this._vueView = value
  }

  // cesium 事件分发
  onCesiumEvent (eventType, msg) {
    this.cameraManager.onCesiumEvent(eventType, msg)
    this.markerManager.onCesiumEvent(eventType, msg)
    this.canvasPicker.onCesiumEvent(eventType, msg)
    this.flyManager.onCesiumEvent(eventType, msg)
    this.drawHandler.onCesiumEvent(eventType, msg)
    this.canvasZoom.onCesiumEvent(eventType, msg)
    if (this._vueView && this._vueView.onCesiumEvent) {
      this._vueView.onCesiumEvent(eventType, msg)
    }
  }

  // 分发自定义事件
  dispatch (eventType, msg) {
    this.onCesiumEvent(eventType, msg)
  }

  destroy () {
    this.cameraManager.destroy()
    this.markerManager.destroy()
    this.canvasPicker.destroy()
    this.flyManager.destroy()
    this.drawHandler.destroy()
    this.volumeMeasure.destroy()
    this.viewShed3D.destroy()
    this.imageryManager.destroy()
    this.cesium3DTilesetManager.destroy()
    this.heatMapManager.destroy()
    this.compassView.destroy && this.compassView.destroy()
    this.canvasZoom.destroy()
    this._viewer.destroy()
    this._viewer = null
  }
}

CesiumPlus.FlyRoute = FlyRoute
CesiumPlus.RouteStop = RouteStop
CesiumPlus.Marker = Marker
CesiumPlus.BillboardStyle = BillboardStyle
CesiumPlus.LabelStyle = LabelStyle
CesiumPlus.PointStyle = PointStyle
CesiumPlus.PolygonStyle = PolygonStyle
CesiumPlus.PolylineStyle = PolylineStyle
CesiumPlus.WallStyle = WallStyle
CesiumPlus.HeatStyle = HeatStyle
CesiumPlus.DrawMode = DrawMode
CesiumPlus.ClampMode = ClampMode
CesiumPlus.LayerDefine = LayerDefine
CesiumPlus.HeatMap = HeatMap
CesiumPlus.CustomEvent = CustomEvent
CesiumPlus.RotateAnimation = RotateAnimation
CesiumPlus.PinBuilder = PinBuilder
CesiumPlus.FlyPath = FlyPath

window.CesiumPlus = CesiumPlus
export {
  CompassView
}
export default CesiumPlus
