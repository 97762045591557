import Marker from './Marker'
import * as turf from '@turf/turf'
import PinBuilder from './PinBuilder'
import DrawMode from '../DrawHandler/DrawMode'

// 关闭深度检测距离，摄像机距离大于改距离，关闭深度检测
// const CloseDepthTestDistance = 5000
// 显示标注名称最大距离
const MAX_DISPLAY_DISTANCE = 1000
/**
 * 标注管理类
 */
class MarkerManager {
  #lastSelectedIds = []

  constructor (context, viewer) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._context = context
    this._viewer = viewer
    // 标注列表
    this.markerMap = {}
    this.pinBuilder = new PinBuilder()

    // this._viewer.scene.camera.moveEnd.addEventListener(this.onCameraChanged, this)
  }

  get markerMap () {
    // console.error('++++++++++++++++',this._markerMap)
    return this._markerMap
  }

  set markerMap (valuse) {
    this._markerMap = valuse
    // console.error('----------',this._markerMap)
  }

  // cesium事件
  onCesiumEvent (type, event) {
    // this.foreachMarker((id, marker) => {
    //   marker.onCesiumEvent(type, event)
    // })
  }

  onCameraChanged () {
    this.foreachMarker((id, marker) => {
      if (marker.boundingSphere && marker.boundingSphere.radius > 0) {
        const markerPos = marker.boundingSphere.center
        const cameraPos = this._viewer.scene.camera.position
        const distance = Cesium.Cartesian3.distance(markerPos, cameraPos)
        const maxDistance = marker.boundingSphere.radius * 10
        marker.isInDisplayViewer = distance < maxDistance
        marker.showLabel(distance < maxDistance)
      } else {
        const markerPos = marker.getPosition()
        const cameraPos = this._viewer.scene.camera.position
        const distance = Cesium.Cartesian3.distance(markerPos, cameraPos)
        marker.isInDisplayViewer = distance < MAX_DISPLAY_DISTANCE
        marker.showLabel(distance < MAX_DISPLAY_DISTANCE)
      }
    })
  }

  /**
   * 遍历所有marker
   * @param {Function} call
   */
  foreachMarker (call) {
    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        call(key, marker)
      }
    }
  }

  /**
   * 获取数据集合DataSource
   * @param {String} layerId 层id
   * @returns {DataSource}
   */
  getDataSourceByLayer (layerId) {
    const datasourceList = this._viewer.dataSources.getByName(layerId)
    if (datasourceList === undefined || datasourceList.length <= 0) { return undefined } else { return datasourceList[0] }
  }

  /**
   * 创建数据集合DataSource
   * @param {String} layerId
   * @returns {DataSource}
   */
  createDataSourceByLayer (layerId) {
    let dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource !== undefined) {
      return dataSource
    }
    // dataSource = new Cesium.CustomDataSource(layerId);
    dataSource = new Cesium.GeoJsonDataSource(layerId)
    this._viewer.dataSources.add(dataSource)
    return dataSource
  }

  /**
   * 添加一个标注
   * @param {Object} options 标注属性
   */
  createMarker (options) {
    const marker = new Marker(this._context, options)
    this.markerMap[marker.id] = marker
    const dataSource = this.createDataSourceByLayer(marker.layerId)
    // cesium bug: entities.add 添加不会从前一个EntityCollection中移除
    // dataSource.entities.add(marker.entity)
    // marker.entity.entityCollection = dataSource.entities
    if (marker.entity.entityCollection) {
      marker.entity.entityCollection.remove(marker.entity)
    }
    dataSource.entities.add(marker.entity)
    return marker
  }

  /**
   * 移除地图中所有标注
   * @param {Boolean}} isDestroy 是否销毁
   */
  removeAllMarker (isDestroy) {
    this._viewer.dataSources.removeAll(isDestroy)
    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        marker.destroy()
        delete this.markerMap[key]
      }
    }
    this.markerMap = {}
  }

  /**
   * 通过layerid移除标注
   * @param {String} layerId 层id
   * @param {Boolean}} isDestroy 是否销毁
   */
  removeMarkerByLayer (layerId, isDestroy = false) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource) {
      this._viewer.dataSources.remove(dataSource, isDestroy)
    }
    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        if (marker.layerId === layerId) {
          marker.destroy()
          delete this.markerMap[key]
        }
      }
    }
  }

  /**
   * 通过id移除marker
   * @param {*} id
   */
  removeMarkerById (id) {
    let isInDataSources = false
    for (let i = 0; i < this._viewer.dataSources.length; i++) {
      const entities = this._viewer.dataSources.get(i).entities
      if (entities.getById(id)) {
        entities.removeById(id)
        isInDataSources = true
        break
      }
    }
    if (!isInDataSources) { // 标注层找不到，找临时entity
      const entity = this._viewer.entities.getById(id)
      if (entity) {
        this._viewer.entities.removeById(id)
      }
    }

    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        if (marker.id === id) {
          marker.destroy()
          delete this.markerMap[key]
        }
      }
    }
  }

  /**
   * 通过layerid显示/隐藏标注
   * @param {String} layerId 层id
   * @param {Boolean}} isShow 是否显示
   */
  showMarkerByLayer (layerId, isShow) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource) {
      dataSource.show = isShow
    }
  }

  /**
   * 通过entity Id 获取entity
   * @param {String} id entity id
   * @returns
   */
  getMarkerById (id) {
    if (!id) {
      return undefined
    }
    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        if (marker.id.toString() === id.toString()) {
          return marker
        }
      }
    }
    return undefined
  }

  /**
   * 从图层创建热力图
   * @param {String} layerId 图层id
   */
  createHeatMapFromLayer (layerId, style) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (!dataSource) {
      return
    }
    const positions = []
    const heatData = []
    const entities = dataSource.entities.values
    for (let i = 0; i < entities.length; i++) {
      if (entities[i] && entities[i].position) {
        const cartesianPos = entities[i].position.getValue(new Cesium.JulianDate())
        if (!cartesianPos) {
          continue
        }
        const cartographic = Cesium.Cartographic.fromCartesian(cartesianPos)
        const longitude = Cesium.Math.toDegrees(cartographic.longitude)
        const latitude = Cesium.Math.toDegrees(cartographic.latitude)
        positions.push([longitude, latitude])
        heatData.push({
          x: longitude,
          y: latitude,
          value: 50
        })
      }
    }
    const multPoint = turf.multiPoint(positions)
    const bbox = turf.bbox(multPoint)
    const bounds = { west: bbox[0], east: bbox[2], south: bbox[1], north: bbox[3] }
    // console.log('bbox=', bbox)
    const heatMap = this._context.heatMapManager.getHeatMapById(layerId)
    if (heatMap) {
      // heatMap.setData(heatData)
      // heatMap.setStyle(style)
      this._context.heatMapManager.removeHeatMapById(layerId)
    }
    this._context.heatMapManager.createHeatMap(layerId, {
      bounds: bounds,
      data: heatData,
      radius: style.radius,
      gradient: style.gradient
    })
  }

  /**
   * 显示所有标注名称
   * @param {Boolen} isShow 是否显示
   */
  showAllLabel (isShow) {
    this.foreachMarker((id, marker) => {
      marker.showLabel(isShow)
    })
  }

  // 通过属性id设置label内容
  setLabelFromProperty (layerId, propertyKey, originKey, type, options) {
    const dataSource = this.getDataSourceByLayer(layerId)
    const entities = dataSource.entities.values
    for (let i = 0; i < entities.length; i++) {
      const values = entities[i].properties.getValue(Cesium.JulianDate.now())
      let value = values[propertyKey]
      if (!value) {
        value = values[originKey]
      }
      if (propertyKey === 'empty') {
        value = undefined
      }
      if (type === 'DATE') {
        value = new Date(value).format('yyyy/MM/dd')
      } else if (type === 'DATE_TIME') {
        value = new Date(value).format('yyyy/MM/dd hh:mm:ss')
      } else if (type === 'SELECT') {
        const key = value.split(',')
        let optionValue = ''
        for (let j = 0; j < key.length; j++) {
          const subKey = key[j]
          for (let k = 0; k < options.length; k++) {
            if (subKey.toString() === options[k].id.toString()) {
              optionValue += options[k].value
              if (j < key.length - 1) {
                optionValue += ','
              }
            }
          }
        }
        value = optionValue
      }
      if (value) {
        // entities[i].label.text = values[label]
        this.getMarkerById(entities[i].id).setLabel(value, false)
      } else {
        this.getMarkerById(entities[i].id).setLabel('', false)
      }
    }
  }

  /**
   * 拾取标注
   * @param {Cartesian2} screenPos
   */
  pickMarker (screenPos) {
    const object = this._viewer.scene.pick(screenPos)
    if (object && object.id && object.id instanceof Cesium.Entity) {
      const marker = this.markerMap[object.id.id]
      if (marker && marker.allowPick) {
        return marker
      }
    }
    return undefined
  }

  /**
   * 拾取屏幕矩形区域标注
   * @param {Array} startPos 屏幕坐标
   * @param {Array} endPos 屏幕坐标
   */
  pickCanvas (startPos, endPos) {
    const res = []
    const minX = Math.min(startPos[0], endPos[0])
    const maxX = Math.max(startPos[0], endPos[0])
    const minY = Math.min(startPos[1], endPos[1])
    const maxY = Math.max(startPos[1], endPos[1])
    this.foreachMarker((id, marker) => {
      if (marker.layerId === '_temp' || marker.layerId === 'routeMiddle') {
        return
      }
      if (marker.getPosition() && marker.entity.billboard) {
        const screenPos = this._viewer.scene.cartesianToCanvasCoordinates(marker.getPosition())
        if (screenPos.x >= minX && screenPos.x <= maxX && screenPos.y >= minY && screenPos.y <= maxY) {
          res.push(marker)
        }
      }
    })
    return res
  }

  /**
   * 根据层级更新样式
   * @param {*} layerId
   * @param {*} styleData
   * @returns
   */
  updateStyle (layerId, styleData) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (!dataSource) {
      return
    }
    for (const key in this.markerMap) {
      if (Object.hasOwnProperty.call(this.markerMap, key)) {
        const marker = this.markerMap[key]
        if (marker.layerId === layerId) {
          marker.setStyle(styleData)
        }
      }
    }
  }

  /**
   * 导出kml配置
   * @param {String} layerId
   * @returns
   */
  exportKml (layerId) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (!dataSource) {
      return
    }
    Cesium.exportKml({
      entities: dataSource.entities
    }).then((result) => {
      // console.log(result.externalFiles)
      console.log(result.kml)

      const MIME_TYPE = 'text/HTML'
      var blob = new Blob([result.kml], { type: MIME_TYPE })
      const dlLink = document.createElement('a')
      dlLink.download = 'kml.xml'
      dlLink.href = window.URL.createObjectURL(blob)
      dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':')
      document.body.appendChild(dlLink)
      dlLink.click()
      document.body.removeChild(dlLink)
    })
  }

  /**
   * 导入kml数据
   * @param {String} url kml 链接
   */
  loadKml (url, options) {
    Cesium.KmlDataSource.load(url).then(kmlDataSource => {
      this._viewer.dataSources.add(kmlDataSource)
    })
  }

  /**
   * 添加一个mark到GeoJson
   * @param {Object} geoJsonData
   * @param {MarkerStyle[]} styleData
   */
  addMarkerFromGeoJson (geoJsonData, styleData = []) {
    return new Promise((resolve, reject) => {
      if (this.getMarkerById(geoJsonData.id)) {
        resolve(this.markerMap[geoJsonData.id])
        return
      }
      const fixedJson = MarkerManager.recombineGeoJson(geoJsonData)
      const self = this
      Marker.fromGeoJson(this._context, fixedJson).then(markers => {
        // self._viewer.dataSources.add(markers[0].entity.entityCollection.owner)
        for (let i = 0; i < markers.length; i++) {
          const marker = markers[i]
          self.markerMap[marker.id] = marker
          const dataSource = self.createDataSourceByLayer(marker.layerId)
          dataSource.entities.add(marker.entity)
          marker.setStyle(styleData)
        }
        resolve(markers)
      })
    })

    // return this.addMarkerLayer(geoJsonData, styleData)
  }

  addMarkerLayer (geoJsonData, styleData = []) {
    return new Promise((resolve, reject) => {
      const fixedJson = MarkerManager.recombineGeoJson(geoJsonData)
      const markers = []
      if (fixedJson.type === 'FeatureCollection') {
        const layerId = fixedJson.layerId
        for (let i = 0; i < fixedJson.features.length; i++) {
          const geoJson = fixedJson.features[i]
          const options = {
            layerId: layerId,
            id: geoJson.properties.id,
            properties: geoJson.properties
          }
          if (geoJson.geometry.type === 'Point') {
            options.position = geoJson.geometry.coordinates
            options.drawMode = DrawMode.Marker
            options.billboard = {
              image: styleData[0]._image,
              color: styleData[0]._color,
              width: styleData[0]._width,
              height: styleData[0]._height,
              scale: styleData[0]._scale
            }
          } else if (geoJson.geometry.type === 'LineString') {
            options.drawMode = DrawMode.Polyline
            options.polyline = {
              positions: geoJson.geometry.coordinates,
              material: styleData[0]._material,
              width: styleData[0]._width
            }
          } else if (geoJson.geometry.type === 'Polygon') {
            options.drawMode = DrawMode.Polygon
            options.polygon = {
              positions: geoJson.geometry.coordinates[0],
              material: styleData[0]._material,
              outline: false
            }
            options.polyline = {
              positions: geoJson.geometry.coordinates[0],
              show: styleData[0]._outline,
              material: styleData[0]._outlineMaterial,
              width: styleData[0]._outlineWidth
            }
          }
          const marker = this.createMarker(options)
          markers.push(marker)
        }
      }
      resolve(markers)
    })
  }

  destroy () {
    this.removeAllMarker(true)
    this._eventHandler = null
    this._viewer = null
  }

  /**
   * 创建聚合图标
   * @param {String} text 文字
   * @param {String} color 颜色
   * @param {String} size 大学
   * @returns 图标
   */
  createPinFromText (text, color1, color2, color3, size) {
    const cesColor1 = Cesium.Color.fromCssColorString(color1)
    const cesColor2 = Cesium.Color.fromCssColorString(color2)
    const cesColor3 = Cesium.Color.fromCssColorString(color3)
    return this.pinBuilder.fromText(text, cesColor1, cesColor2, cesColor3, size)
  }

  /**
   * 按默认图标聚合
   * @param {string} layerId 图层id
   * @param {Object} options 可选参数
   * @param {Object} options.enable 是否聚合
   * @param {Object} options.pixelRange 聚合范围大小
   * @param {Object} options.minimumClusterSize 最小聚合数量
   * @param {Object} options.clusterImage 聚合图标,[{count:100, pin:"100.png"},{count:50, pin:"50.png"}] 格式
   */
  clusterIcon (layerId, options) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource === undefined) {
      return
    }
    const cluster = dataSource.clustering
    const _options = Cesium.defaultValue(options, {})
    const enable = Cesium.defaultValue(_options.enable, false)
    const pixelRange = Cesium.defaultValue(_options.pixelRange, 80)
    const minimumClusterSize = Cesium.defaultValue(_options.minimumClusterSize, 2)
    const clusterImage = Cesium.defaultValue(_options.clusterImage, [])
    if (cluster.enabled === enable) {
      return
    }
    if (dataSource.isInitCluster) {
      this.enableCluster(layerId, enable)
      return
    }
    // 创建默认聚合图标
    if (clusterImage.length <= 0) {
      const pin100 = this.createPinFromText('100+', '#f0af6d', '#e08832', '#e8ab74', 50)
      const pin50 = this.createPinFromText('50+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin40 = this.createPinFromText('40+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin30 = this.createPinFromText('30+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin20 = this.createPinFromText('20+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin10 = this.createPinFromText('10+', '#f1e795', '#d1c023', '#e2d67a', 50)
      clusterImage.push({ count: 100, pin: pin100 })
      clusterImage.push({ count: 50, pin: pin50 })
      clusterImage.push({ count: 40, pin: pin40 })
      clusterImage.push({ count: 30, pin: pin30 })
      clusterImage.push({ count: 20, pin: pin20 })
      clusterImage.push({ count: 10, pin: pin10 })
      for (let i = 0; i < 8; ++i) {
        const pin = this.createPinFromText('' + (i + 2), 'rgb(159, 210, 133)', 'rgb(95, 172, 56)', 'rgb(128, 193, 95)', 50)
        clusterImage.push({ count: i + 2, pin: pin })
      }
    }
    clusterImage.sort(function (a, b) {
      return b.count - a.count
    })

    cluster.enabled = enable
    cluster.pixelRange = pixelRange
    cluster.minimumClusterSize = minimumClusterSize
    cluster.clusterEvent.addEventListener(function (clusteredEntities, cluster) {
      cluster.label.show = false
      cluster.billboard.show = true
      cluster.billboard.id = cluster.label.id
      cluster.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY
      for (let i = 0; i < clusterImage.length; i++) {
        if (clusteredEntities.length >= clusterImage[i].count) {
          cluster.billboard.image = clusterImage[i].pin
          break
        }
      }
    })
    dataSource.isInitCluster = true
  }

  /**
   * 按标注数量聚合
   * @param {string} layerId 图层id
   * @param {Object} options 可选参数
   * @param {Object} options.enable 是否聚合
   * @param {Object} options.pixelRange 聚合范围大小
   * @param {Object} options.minimumClusterSize 最小聚合数量
   * @param {String} options.clusterImage 聚合图标
   * @param {LabelStyle} options.labelStyle label样式
   */
  clusterLabel (layerId, options) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource === undefined) {
      return
    }
    const cluster = dataSource.clustering
    const _options = Cesium.defaultValue(options, {})
    const enable = Cesium.defaultValue(_options.enable, false)
    const pixelRange = Cesium.defaultValue(_options.pixelRange, 80)
    const minimumClusterSize = Cesium.defaultValue(_options.minimumClusterSize, 2)
    const clusterImage = Cesium.defaultValue(_options.clusterImage, undefined)
    const labelStyle = Cesium.defaultValue(_options.labelStyle, undefined)
    if (cluster.enabled === enable) {
      return
    }
    if (dataSource.isInitCluster) {
      this.enableCluster(layerId, enable)
      return
    }

    cluster.enabled = enable
    cluster.pixelRange = pixelRange
    cluster.minimumClusterSize = minimumClusterSize
    cluster.clusterEvent.addEventListener(function (clusteredEntities, cluster) {
      cluster.label.show = true
      cluster.label.disableDepthTestDistance = Number.POSITIVE_INFINITY
      // cluster.label.text = clusteredEntities.length.toString()
      if (labelStyle) {
        labelStyle.decorate(cluster)
      }
      if (clusterImage) {
        cluster.billboard.show = true
        cluster.billboard.id = cluster.label.id
        cluster.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY
        cluster.billboard.image = clusterImage
      }
      cluster.type = 'cluster'
      cluster.entities = clusteredEntities
    })
    dataSource.isInitCluster = true
  }

  /**
   * 开启关闭聚合
   * @param {String} layerId 图层id
   * @param {Boolean} enable 是否开启
   */
  enableCluster (layerId, enable) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (dataSource === undefined) {
      return
    }
    dataSource.clustering.enabled = enable
  }

  /**
   * 获取图层聚合状态
   * @param {String} layerId 图层id
   * @returns 是否开启聚合
   */
  getClusterState (layerId) {
    const dataSource = this.getDataSourceByLayer(layerId)
    if (!dataSource || !dataSource.clustering) {
      return false
    }
    return dataSource.clustering.enabled
  }

  /**
   * geoJson格式重组，从properties字段中提取高度数据
   * @param {Object} json geoJson格式
   */
  static recombineGeoJson (jsonData) {
    function recombineFeature (featureJson) {
      if (featureJson.geometry && featureJson.properties && featureJson.properties.height_) {
        const heightArray = JSON.parse(featureJson.properties.height_)
        // const heightArray = featureJson.properties.height_
        if (heightArray.length > 0) {
          if (featureJson.geometry.type === 'Point') {
            if (featureJson.geometry.coordinates.length === 2) {
              const height = isNaN(parseFloat(heightArray[0])) ? 0 : parseFloat(heightArray[0])
              featureJson.geometry.coordinates.push(height)
            }
          } else if (featureJson.geometry.type === 'LineString') {
            for (let i = 0; i < featureJson.geometry.coordinates.length; i++) {
              if (featureJson.geometry.coordinates[i].length === 2 && heightArray.length >= i + 1) {
                const height = isNaN(parseFloat(heightArray[i])) ? 0 : parseFloat(heightArray[i])
                featureJson.geometry.coordinates[i].push(height)
              }
            }
          } else if (featureJson.geometry.type === 'Polygon') {
            // 多边形有高度就不贴地了
            // let heightIndex = 0
            // for(let i=0; i<featureJson.geometry.coordinates.length; i++){
            //   let coord = featureJson.geometry.coordinates[i]
            //   for(let j=0; j<coord.length; j++) {
            //     if(coord[j].length===2 && heightArray.length>=heightIndex+1) {
            //       coord[j].push(heightArray[heightIndex])
            //       heightIndex++
            //     }
            //   }
            // }
          }
        }
      } else {
        // console.error('GeoJson error : ', featureJson)
      }
    }
    const fixedJson = JSON.parse(JSON.stringify(jsonData))
    if (fixedJson.type === 'FeatureCollection') {
      for (let i = 0; i < fixedJson.features.length; i++) {
        recombineFeature(fixedJson.features[i])
      }
    } else if (fixedJson.type === 'Feature') {
      recombineFeature(fixedJson)
    } else {
      console.error('the GeoJson type=', fixedJson.type)
    }

    return fixedJson
  }
}

export default MarkerManager
