/**
 * 标注编辑工厂类
 */
import DrawMode from '../../DrawHandler/DrawMode'
import PointEditor from './PointEditor'
import PolylineEditor from './PolylineEditor'
import CircleEditor from './CircleEditor'
import PolygonEditor from './PolygonEditor'

class MarkerEditorFactory {
  _context = undefined
  constructor (context) {
    this._context = context
  }

  /**
   * 通过Marker创建标注编辑类
   * @param {Marker} marker 标注
   * @returns BaseEditor
   */
  createEditorbyMarker (marker) {
    if (marker._drawMode === DrawMode.Marker || marker._drawMode === DrawMode.Point) {
      return PointEditor.fromMarker(this._context, marker)
    } else if (marker._drawMode === DrawMode.Polyline || marker._drawMode === DrawMode.Curve) {
      return PolylineEditor.fromMarker(this._context, marker)
    } else if (marker._drawMode === DrawMode.Circle) {
      return CircleEditor.fromMarker(this._context, marker)
    } else if (marker._drawMode === DrawMode.Polygon) {
      return PolygonEditor.fromMarker(this._context, marker)
    }
    return undefined
  }

  /**
   * 通过geojson创建标注编辑类
   * @param {Object} param0
   */
  createEditorbyGeoJson ({ id, geoJson }) {
    if (geoJson.geometry.type === 'Point') {
      return PointEditor.fromGeoJson(this._context, geoJson)
    } else if (geoJson.geometry.type === 'LineString') {
      return PolylineEditor.fromGeoJson(this._context, geoJson)
    } else if (geoJson.geometry.type === 'Polygon') {
      const drawMode = geoJson.properties.draw_mode_ ? parseInt(geoJson.properties.draw_mode_) : DrawMode.Polyline
      if (drawMode === DrawMode.Circle) {
        return CircleEditor.fromGeoJson(this._context, geoJson)
      } else {
        return PolygonEditor.fromGeoJson(this._context, geoJson)
      }
    }
    return undefined
  }
}

export default MarkerEditorFactory
