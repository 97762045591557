/**
 * 自定义事件
 */
const CustomEvent = {
  Click: Cesium.ScreenSpaceEventType.LEFT_CLICK,
  MouseMove: Cesium.ScreenSpaceEventType.MOUSE_MOVE,
  DrawState: 'drawState', // 绘制状态改变事件
  MarkerPositionChanged: 'MarkerPositionChanged' // 标注位置更新事件
}
export default Object.freeze(CustomEvent)
