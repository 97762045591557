<template>
  <div id="cesiumViewer" class="cesium-container"></div>
</template>

<script>
import Vue from 'vue'
import CesiumPlus from '@/utils/cesiumExtents/CesiumPlus'
import BillboardStyle from '@/utils/cesiumExtents/Marker/MarkerStyle/BillboardStyle'
import LabelStyle from '@/utils/cesiumExtents/Marker/MarkerStyle/LabelStyle'
import { getModelBaseData, getLivePoints, getFarmPoints } from '@/api/model'

const Cesium = window.Cesium
export default {
  name: 'index',
  data() {
    return {
      modelOptions: null
    }
  },
  mounted() {
    // 初始化三维场景
    this.initViewer()
    // 加载3D模型
    this.load3DTiles()
    // 加载直播点位
    this.loadLivePoints()
    // 加载农场点位
    this.loadFarmPoints()
  },
  methods: {
    // 初始化三维场景场景
    initViewer() {
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(90, 10, 120, 70)
      const viewer = new Cesium.Viewer('cesiumViewer', {
        animation: false, // 隐藏动画器件
        timeline: false, // 隐藏时间轴
        geocoder: false, // 搜索按钮
        homeButton: false, // 主页按钮
        sceneModePicker: false,
        baseLayerPicker: false,
        navigationHelpButton: false,
        navigation: false,
        orderIndependentTranslucency: false, // 用来去掉地球表面的大气效果的黑圈问题
        contextOptions: {
          webgl: {
            alpha: true,
            preserveDrawingBuffer: true,
          },
        },
        shouldAnimate: true,
        fullscreenButton: false,
        infoBox: false, // 点击实体不弹出信息框
        selectionIndicator: false, // 实体不被选中
        imageryProvider: undefined,
      })
      window.cesiumViewer = viewer
      Vue.prototype.$cesiumPlus = new CesiumPlus(window.cesiumViewer)
      Vue.prototype.$cesiumPlus.vueView = this

      viewer.scene.debugShowFramesPerSecond = false // 显示帧数
      viewer._cesiumWidget._creditContainer.style.display = 'none' // 隐藏版权信息
      viewer.scene.globe.enableLighting = false
      viewer.scene.moon.show = false
      // 设置鼠标事件
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
          Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      )

      // 开启深度监测
      // viewer.scene.globe.depthTestAgainstTerrain = true
      // 天地图卫星影像
      this.$cesiumPlus.imageryManager.removeAllImageryLayer()
      this.$cesiumPlus.imageryManager.switchBaseLayer(
          CesiumPlus.LayerDefine.TianDiMapImgLayer
      )
      // 设置区域亮度
      // this._setGlobalColor(1.2)
      // const layer = this.$cesiumPlus.imageryManager.getBaseLayer()
      // layer.brightness = 0.2

      //相机高度的最大最小值
      if (process.env.NODE_ENV !== 'development') {
        viewer.scene.screenSpaceCameraController.maximumZoomDistance = 4000;
        viewer.scene.screenSpaceCameraController.minimumZoomDistance = 100
      }
      this.$cesiumPlus.statusBar.showStatusBar(process.env.NODE_ENV === 'development')

      // viewer.scene.camera.changed.addEventListener(
      //   this.onCameraChanged.bind(this)
      // )

      // 天空盒
      let defaultSkyBox = viewer.scene.skyBox
      let currentSkyBox = new Cesium.GroundSkyBox({
        sources: {
          positiveX: require('../../assets/textures/skybox/east.jpg'),
          negativeX: require('../../assets/textures/skybox/west.jpg'),
          positiveY: require('../../assets/textures/skybox/north.jpg'),
          negativeY: require('../../assets/textures/skybox/south.jpg'),
          positiveZ: require('../../assets/textures/skybox/top.jpg'),
          negativeZ: require('../../assets/textures/skybox/bottom.jpg'),
        },
      })
      //控制高度，在小于5000米的时候显示近景图
      viewer.scene.postRender.addEventListener(() => {
        var e = viewer.camera.position
        if (Cesium.Cartographic.fromCartesian(e).height < 5000) {
          // 显示自定义的天空盒
          viewer.scene.skyBox = currentSkyBox
          viewer.scene.skyAtmosphere.show = false
        } else {
          viewer.scene.skyBox = defaultSkyBox
          viewer.scene.skyAtmosphere.show = true
        }
      })

    },
    // 加载3DTiles模型数据
    async load3DTiles() {
      if (this.isLoaded3dTile) return
      const options = await getModelBaseData()
      this.modelOptions = options
      const self = this
      this.$cesiumPlus.cesium3DTilesetManager.add3dTileset(options.url, {
        maximumScreenSpaceError: 16,
        maximumMemoryUsage: 2048,
        height: options.height || 80,
        isFlyTo: false,
        loaded: () => {
          self.isLoaded3dTile = true
          self.flyToDefault(2)
        },
      })
    },
    // 加载监控点位
    async loadLivePoints() {
      const livePoints = await getLivePoints()
      livePoints.forEach((item, index) => {
        this.$cesiumPlus.markerManager.createMarker({
          layerId: 'live',
          position: item.position,
          label: {
            text: item.name,
            fontsize: '24px'
          },
          properties: {
            liveIndex: index
          },
          billboard: {},
          style: [
            new BillboardStyle({
              image: require('../../assets/images/camera2.png'),
              eyeOffset: new Cesium.Cartesian3(0, 0, -10),
              width: undefined,
              height: undefined,
              scale: 0.8
            }),
            new LabelStyle({
              font: '24px Arial',
              pixelOffset: new Cesium.Cartesian2(0, -80),
              eyeOffset: new Cesium.Cartesian3(0, 0, 1),
              showBackground: false,
              fillColor: Cesium.Color.fromCssColorString('#FFFFFF')
            })
          ]
        })
      })
    },
    // 加载农场点位
    async loadFarmPoints() {
      const farmPoints = await getFarmPoints()
      farmPoints.forEach(item => {
        const image = require(`../../assets/images/${item.type}.png`)
        this.$cesiumPlus.markerManager.createMarker({
          layerId: item.type,
          position: item.position,
          billboard: {},
          style: [
            new BillboardStyle({
              image: image,
              eyeOffset: new Cesium.Cartesian3(0, 0, -10),
              width: undefined,
              height: undefined,
              scale: 1
            })
          ]
        })
      })
    },
    // cesium事件
    onCesiumEvent(type, event) {
      if (type === Cesium.ScreenSpaceEventType.LEFT_CLICK) {
        const marker = this.$cesiumPlus.markerManager.pickMarker(event.position)
        if(marker && marker.layerId === 'live') {
          this.handleLiveClick(marker)
        }
      }
    },
    // 直播标注点击事件
    handleLiveClick(marker) {
      const liveIndex = marker.getPropertyByName('liveIndex')
      this.$emit('liveClick', liveIndex)
    },
    // 设置全局颜色
    _setGlobalColor(brightness) {
      const cesiumViewer = window.cesiumViewer
      const brightnessStage =
          Cesium.PostProcessStageLibrary.createBrightnessStage()
      brightnessStage.uniforms.brightness = brightness
      cesiumViewer.scene.postProcessStages.add(brightnessStage)
    },
    // 飞向相机默认位置
    flyToDefault(duration) {
      this.$cesiumPlus.cameraManager.flyTo({
        position: this.modelOptions.basePosition,
        direction: this.modelOptions.baseOrientation,
        duration: duration ? duration : 0.0,
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.cesium-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>