import * as turf from '@turf/turf'
import markerImage from '../Assets/Images/marker.png'
import BillboardStyle from './MarkerStyle/BillboardStyle'
import PolylineStyle from './MarkerStyle/PolylineStyle'
import PolygonStyle from './MarkerStyle/PolygonStyle'
import { cartesianToLonLat, getEllipsePositions } from '../Common/cesiumUtil'
import DrawMode from '../DrawHandler/DrawMode'

// 关闭深度检测距离，摄像机距离大于改距离，关闭深度检测
// const CloseDepthTestDistance = 5000

/**
 * 标注类
 */
class Marker {
  constructor (context, options) {
    // this._viewer = viewer
    this._context = context
    const _options = Cesium.defaultValue(options, {})
    this._entity = Cesium.defaultValue(options.entity, undefined)
    this._allowPick = Cesium.defaultValue(_options.allowPick, true) // 允许拾取
    this._layerId = Cesium.defaultValue(_options.layerId, '_temp')
    this._drawMode = Cesium.defaultValue(_options.drawMode, DrawMode.Hybird)
    const _degreePosition = Cesium.defaultValue(_options.position, undefined)
    let _position
    if (_degreePosition) {
      _position = Cesium.Cartesian3.fromDegrees(_degreePosition[0], _degreePosition[1], _degreePosition[2] ? _degreePosition[2] : 0)
    }
    this._name = Cesium.defaultValue(_options.name, undefined)
    this._id = Cesium.defaultValue(_options.id, undefined)
    this._properties = Cesium.defaultValue(_options.properties, undefined)
    const _billboard = Cesium.defaultValue(_options.billboard, undefined)
    const _label = Cesium.defaultValue(_options.label, undefined)
    const _polyline = Cesium.defaultValue(_options.polyline, undefined)
    const _polygon = Cesium.defaultValue(_options.polygon, undefined)
    const _point = Cesium.defaultValue(_options.point, undefined)
    const _wall = Cesium.defaultValue(_options.wall, undefined)

    // 标注当前style
    this._curStyles = []
    const _style = Cesium.defaultValue(_options.style, [])
    // 标注上次style
    this._lastStyles = []
    // 是否选中
    this._selected = false
    // 是否高亮
    this._isHover = false

    if (!this._entity) {
      this._entity = new Cesium.Entity({
        name: this._name,
        id: this._id,
        position: _position,
        properties: this._properties
      })
    }
    this._id = this.entity.id
    this._name = this.entity.name
    this._properties = this.entity.properties

    if (_billboard) {
      this.createBillboard(_billboard)
    }
    if (_point) {
      this.createPoint(_point)
    }
    if (_label) {
      this.createLabel(_label)
    }
    if (_polyline) {
      this.createPolyline(_polyline)
    }
    if (_polygon) {
      this.createPolygon(_polygon)
    }
    if (_wall) {
      this.createWall(_wall)
    }
    // 多边形创建描边
    if (this.entity.polygon && !this.entity.polyline) {
      const positions = this.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
      if (positions && positions.length > 0) {
        this.entity.polyline = new Cesium.PolylineGraphics({
          positions: positions,
          show: true,
          clampToGround: true,
          classificationType: Cesium.ClassificationType.BOTH
        })
      }
    }
    // 多边形创建围墙
    // if(this.entity.polygon) {
    //   let _imageCanvas = document.createElement('canvas');
    //   _imageCanvas.width = 100;
    //   _imageCanvas.height = 1;
    //   let ctx = _imageCanvas.getContext('2d');
    //   ctx.globalAlpha = 1.0;
    //   ctx.fillStyle="#fffaff";
    //   ctx.fillRect(0,0,50,1);
    //   ctx.fillStyle="#fffaff";
    //   ctx.fillRect(50,0,50,1);

    //   const positions = this.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
    //   if(positions && positions.length>0) {
    //     let minimumHeights = []
    //     let maximumHeights = []
    //     for(let i=0; i<positions.length; i++) {
    //       minimumHeights.push(0)
    //       maximumHeights.push(20)
    //     }
    //     this.entity.wall = new Cesium.WallGraphics({
    //       positions: positions,
    //       material: new Cesium.WallRollMaterialProperty({
    //         image: _imageCanvas
    //       }),
    //       minimumHeights: minimumHeights,
    //       maximumHeights: maximumHeights
    //     })
    //   }
    // }

    // 线标注存储线坐标
    this.polylinePositions = []
    if (this.entity && this.entity.polyline) {
      const points = this.entity.polyline.positions.getValue(Cesium.JulianDate.now())
      for (let i = 0; i < points.length; i++) {
        // 相邻两个点相同
        if (i > 0 && Cesium.Cartesian3.equals(points[i], points[i - 1])) {
          continue
        }
        const _pos = cartesianToLonLat(points[i])
        this.polylinePositions.push([_pos.longitude, _pos.latitude, _pos.height])
      }
      // 处理曲线
      if (this._drawMode === DrawMode.Curve) {
        this.entity.polyline.positions = this._calculateCurve(points)
      }
    }

    // 如果position不存在，计算geojson中心点
    if (!this.entity.position) {
      const centerPos = this.getCenterPosition()
      if (centerPos) {
        // centerPos = [0,0,0]
        this.setPosition(centerPos)
      }
    }
    // 创建标题
    // if (this.entity.name && !this.entity.label) {
    //   this.createLabel({
    //     text: this.entity.name,
    //     show: false
    //   })
    // }
    // 设置样式
    this.setStyle(_style)
    // 标注特效
    this._effect = undefined
    // 创建包围球
    this.boundingSphere = undefined
    if (this.entity.polygon) {
      const positions = this.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
      this.boundingSphere = Cesium.BoundingSphere.fromPoints(positions)
    } else if (this.entity.polyline) {
      const positions = this.entity.polyline.positions.getValue(Cesium.JulianDate.now())
      this.boundingSphere = Cesium.BoundingSphere.fromPoints(positions)
    }
  }

  /**
   * 计算线条曲线
   * @param position 线段Cartesian3坐标
   * @return 曲线Cartesian3坐标
   */
  _calculateCurve (position) {
    const degreePoints = []
    position.forEach(item => {
      const point = cartesianToLonLat(item)
      degreePoints.push([
        point.longitude,
        point.latitude
      ])
    })
    const line = turf.lineString(degreePoints)
    const curved = turf.bezierSpline(line)
    const curveGid = curved.geometry.coordinates
    const curvePositions = []
    curveGid.forEach(item => {
      curvePositions.push(item[0])
      curvePositions.push(item[1])
      curvePositions.push(0)
    })
    return Cesium.Cartesian3.fromDegreesArrayHeights(curvePositions)
  }

  get entity () {
    return this._entity
  }

  get id () {
    return this._id
  }

  get name () {
    return this._name
  }

  get drawMode () {
    return this._drawMode
  }

  get allowPick () {
    return this._allowPick
  }

  get layerId () {
    return this._layerId
  }

  get properties () {
    return this._properties
  }

  setProperties (property) {
    this._entity.properties = new Cesium.PropertyBag(property)
    this._properties = this._entity.properties
  }

  getProperties (propertyName) {
    if (this.properties && this.properties[propertyName]) {
      return this.properties[propertyName].getValue(Cesium.JulianDate.now())
    }
    return undefined
  }

  get curStyles () {
    return this._curStyles
  }

  get lastStyles () {
    return this._lastStyles
  }

  get selected () {
    return this._selected
  }

  set selected (value) {
    if (value === this._selected) {
      return
    }
    if (value) {
      this.setStyle([
        BillboardStyle.Selected(),
        PolylineStyle.Selected(),
        PolygonStyle.Selected()
      ])
    } else {
      this.setStyle(this.lastStyles)
    }
    this._selected = value
  }

  set hover (value) {
    if (value === this._isHover) {
      return
    }
    if (value) {
      this.setStyle([
        BillboardStyle.Selected(),
        PolylineStyle.Selected(),
        PolygonStyle.Selected()
      ])
    } else {
      this.setStyle(this.lastStyles)
    }
    this._isHover = value
  }

  // 标注是否显示
  isVisibleInScene () {
    return this.entity.show && this.entity.entityCollection.show && this.entity.entityCollection.owner.show
  }

  /**
   *
   * @returns {Cartesian3} 返回三维坐标
   */
  getPosition () {
    if (this.entity.position) {
      return this.entity.position.getValue(Cesium.JulianDate.now())
    }
  }

  /**
   * 设置marker位置
   * @param {Array} pos [经度,纬度,高度]
   */
  setPosition (pos) {
    this.entity.position = Cesium.Cartesian3.fromDegrees(pos[0], pos[1], pos[2] ? pos[2] : 0)
  }

  getGisPosition () {
    if (this.entity.position) {
      const position = this.entity.position.getValue(Cesium.JulianDate.now())
      const pos = cartesianToLonLat(position)
      return [pos.longitude, pos.latitude, pos.height]
    }
  }

  /**
   * 获取marker中心点坐标
   * @returns 中心点经纬度坐标
   */
  getCenterPosition () {
    const geoJson = JSON.parse(this.toGeoJson())
    if (!geoJson || !geoJson.geometry || !geoJson.geometry.type) {
      return undefined
    }
    const center = turf.center(geoJson)
    const centerPos = center.geometry.coordinates
    return centerPos
  }

  /**
   * 获取线段点坐标
   * @returns Array gis坐标
   */
  getPolylineGisPositions () {
    // const positions = []
    // if (this.entity && this.entity.polyline) {
    //   const points = this.entity.polyline.positions.getValue(Cesium.JulianDate.now())
    //   for (let i = 0; i < points.length; i++) {
    //     const _pos = cartesianToLonLat(points[i])
    //     positions.push([_pos.longitude, _pos.latitude, _pos.height])
    //   }
    // }
    // return positions
    return this.polylinePositions
  }

  /**
   * 设置线条坐标
   */
  setPolylineGisPositions (positions) {
    if (!this.entity || !this.entity.polyline) {
      return
    }
    this.polylinePositions = JSON.parse(JSON.stringify(positions))
    const _positions = []
    positions.forEach(item => {
      const pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ? item[2] : 0)
      _positions.push(pos)
    })
    // 处理曲线
    if (this._drawMode === DrawMode.Curve) {
      this.entity.polyline.positions = this._calculateCurve(_positions)
    } else {
      this.entity.polyline.positions = _positions
    }
  }

  /**
   * 获取多边形点坐标
   * @returns Array gis坐标
   */
  getPolygonGisPositions () {
    const positions = []
    if (this.entity && this.entity.polygon) {
      const points = this.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
      for (let i = 0; i < points.length; i++) {
        if (i === points.length - 1 && Cesium.Cartesian3.equals(points[0], points[i])) {
          continue
        }
        const _pos = cartesianToLonLat(points[i])
        positions.push([_pos.longitude, _pos.latitude, _pos.height])
      }
    }
    return positions
  }

  /**
   * 设置多边形坐标
   */
  setPolygonGisPositions (gisPosition) {
    if (!this.entity || !this.entity.polygon) {
      return
    }
    const _positions = []
    gisPosition.forEach(item => {
      const pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ? item[2] : 0)
      _positions.push(pos)
    })
    this.entity.polygon.hierarchy = new Cesium.PolygonHierarchy(_positions)
    if (this.entity.polyline) {
      this.entity.polyline.positions = _positions.concat(_positions[0])
    }
  }

  /**
   * 获取标注屏幕坐标
   * @param {Viewer} cesium viewer
   * @returns {Array} 屏幕坐标[x,y]
   */
  getScreenPos (viewer) {
    if (!viewer) {
      console.error('viewer is null')
      return
    }
    let pos = [-10000, -10000]
    const markCartesian3 = this.entity.position.getValue(Cesium.JulianDate.now())
    const canvasPos = viewer.scene.cartesianToCanvasCoordinates(markCartesian3)
    if (canvasPos !== undefined) {
      pos = [canvasPos.x, canvasPos.y]
    }
    return pos
  }

  /**
   * 创建公告板
   * @param {*} options
   */
  createBillboard (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _image = Cesium.defaultValue(_options.image, markerImage)
    const _heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.NONE)
    const _disableDepthTestDistance = Cesium.defaultValue(_options.disableDepthTestDistance, Number.POSITIVE_INFINITY)
    const _color = Cesium.defaultValue(_options.color, Cesium.Color.fromCssColorString('#FFFFFF'))
    const _scale = Cesium.defaultValue(_options.scale, 1.0)
    const _pixelOffset = Cesium.defaultValue(_options.pixelOffset, new Cesium.Cartesian2(0, 0))
    if (this.entity.billboard) {
      console.error('entity had bilboard, entityId:', this.entity.id)
    }
    this.entity.billboard = new Cesium.BillboardGraphics({
      show: _show,
      image: _image,
      color: _color,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      heightReference: _heightReference,
      disableDepthTestDistance: _disableDepthTestDistance,
      scale: _scale,
      pixelOffset: _pixelOffset
    })
  }

  createPoint (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _pixelSize = Cesium.defaultValue(_options.pixelSize, 3)
    const _heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.NONE)
    const _disableDepthTestDistance = Cesium.defaultValue(_options.disableDepthTestDistance, Number.POSITIVE_INFINITY)
    const _color = Cesium.defaultValue(_options.color, Cesium.Color.fromCssColorString('#FF0000'))
    if (this.entity.point) {
      console.error('entity had point, entityId:', this.entity.id)
    }
    this.entity.point = new Cesium.PointGraphics({
      show: _show,
      pixelSize: _pixelSize,
      color: _color,
      heightReference: _heightReference,
      disableDepthTestDistance: _disableDepthTestDistance
    })
  }

  /**
   * 创建文字
   * @param {*} options
   */
  createLabel (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _text = Cesium.defaultValue(_options.text, '')
    const _font = (_options.labelStyle && _options.labelStyle.labelFont) ? `12px ${_options.labelStyle.labelFont}` : '12px 黑体'
    const _fillColor = (_options.labelStyle && _options.labelStyle.labelColor) ? Cesium.Color.fromCssColorString(_options.labelStyle.labelColor) : Cesium.Color.WHITE
    const _backgroundColor = (_options.labelStyle && _options.labelStyle.labelBackgroundColor) ? Cesium.Color.fromCssColorString(_options.labelStyle.labelBackgroundColor) : Cesium.Color.fromCssColorString('#272829')
    const _disableDepthTestDistance = Cesium.defaultValue(_options.disableDepthTestDistance, Number.POSITIVE_INFINITY)
    const _pixelOffset = _options.pixelOffset ? new Cesium.Cartesian2(_options.pixelOffset[0], _options.pixelOffset[1]) : new Cesium.Cartesian2(0, -50)
    const _showBackground = Cesium.defaultValue(_options.showBackground, true)
    if (this.entity.label) {
      console.error('entity had label, entityId:', this.entity.id)
    }
    this.entity.label = new Cesium.LabelGraphics({
      show: _show,
      text: _text,
      heightReference: Cesium.HeightReference.NONE,
      disableDepthTestDistance: _disableDepthTestDistance,
      font: _font,
      pixelOffset: _pixelOffset,
      eyeOffset: new Cesium.Cartesian3(0, 0, -1), // 文字向屏幕外偏移1米，避免遮挡
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      verticalOrigin: Cesium.HorizontalOrigin.CENTER,
      showBackground: _showBackground,
      fillColor: _fillColor,
      backgroundColor: _backgroundColor,
      backgroundPadding: new Cesium.Cartesian2(15, 7)
    })
  }

  /**
   * 创建线段
   * @param {*} options
   */
  createPolyline (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _degreePosition = Cesium.defaultValue(_options.positions, undefined)
    const _material = Cesium.defaultValue(_options.material, Cesium.Color.WHITE)
    const _width = Cesium.defaultValue(_options.width, 1)
    const _clampToGround = Cesium.defaultValue(_options.clampToGround, true)
    const _positions = []
    if (_degreePosition) {
      for (let i = 0; i < _degreePosition.length; i++) {
        const pos = Cesium.Cartesian3.fromDegrees(_degreePosition[i][0], _degreePosition[i][1], _degreePosition[i][2] ? _degreePosition[i][2] : 0)
        _positions.push(pos)
      }
    }
    if (this.entity.polyline) {
      console.error('entity had polyline, entityId:', this.entity.id)
    }
    this.entity.polyline = new Cesium.PolylineGraphics({
      positions: _positions,
      show: _show,
      clampToGround: _clampToGround,
      classificationType: Cesium.ClassificationType.CESIUM_3D_TILE,
      material: _material,
      depthFailMaterial: _material,
      width: _width
    })
  }

  /**
   * 创建多边形
   * @param {*} options
   */
  createPolygon (options) {
    const _options = Cesium.defaultValue(options, {})
    const _degreePosition = Cesium.defaultValue(_options.positions, undefined)
    const _show = Cesium.defaultValue(_options.show, true)
    const _material = Cesium.defaultValue(_options.material, Cesium.Color.WHITE)
    const _positions = []
    if (_degreePosition) {
      for (let i = 0; i < _degreePosition.length; i++) {
        const pos = Cesium.Cartesian3.fromDegrees(_degreePosition[i][0], _degreePosition[i][1], _degreePosition[i][2] ? _degreePosition[i][2] : 0)
        _positions.push(pos)
      }
    }
    if (this.entity.polygon) {
      console.error('entity had polygon, entityId:', this.entity.id)
    }
    this.entity.polygon = new Cesium.PolygonGraphics({
      show: _show,
      hierarchy: {
        positions: _positions
      },
      outline: false,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      classificationType: Cesium.ClassificationType.BOTH,
      material: _material
    })
  }

  /**
   * 创建围墙
   * @param {*} options
   */
  createWall (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _degreePosition = Cesium.defaultValue(_options.positions, undefined)
    const _material = Cesium.defaultValue(_options.material, Cesium.Color.WHITE)
    const _minimumHeights = Cesium.defaultValue(_options.minimumHeights, 0)
    const _maximumHeights = Cesium.defaultValue(_options.maximumHeights, 10)
    const minHeights = []
    const maxHeights = []
    const _positions = []
    if (_degreePosition) {
      for (let i = 0; i < _degreePosition.length; i++) {
        const pos = Cesium.Cartesian3.fromDegrees(_degreePosition[i][0], _degreePosition[i][1], _degreePosition[i][2] ? _degreePosition[i][2] : 0)
        _positions.push(pos)
        minHeights.push(_minimumHeights)
        maxHeights.push(_maximumHeights)
      }
    }
    if (this.entity.wall) {
      console.error('entity had wall, entityId:', this.entity.id)
    }
    this.entity.wall = new Cesium.WallGraphics({
      show: _show,
      positions: _positions,
      material: _material,
      minimumHeights: minHeights,
      maximumHeights: maxHeights
    })
  }

  setWallPositions (degreePosition) {
    if (!this.entity || !this.entity.wall) {
      return
    }
    this.polylinePositions = JSON.parse(JSON.stringify(degreePosition))
    let _positions = []
    const minHeights = []
    const maxHeights = []
    const curMinHeight = this.entity.wall.minimumHeights ? this.entity.wall.minimumHeights.getValue(Cesium.JulianDate.now())[0] : 0
    const curMaxHeight = this.entity.wall.maximumHeights.getValue(Cesium.JulianDate.now())[0]
    for (let i = 0; i < degreePosition.length; i++) {
      const pos = Cesium.Cartesian3.fromDegrees(degreePosition[i][0], degreePosition[i][1], degreePosition[i][2] ? degreePosition[i][2] : 0)
      _positions.push(pos)
    }
    // 处理曲线
    if (this._drawMode === DrawMode.Curve) {
      _positions = this._calculateCurve(_positions)
    }
    for (let i = 0; i < _positions.length; i++) {
      minHeights.push(curMinHeight)
      maxHeights.push(curMaxHeight)
    }
    this.entity.wall.positions = _positions
    this.entity.wall.minimumHeights = minHeights
    this.entity.wall.maximumHeights = maxHeights
  }

  /**
   * 获取围墙长度
   * @returns 围墙长度
   */
  getWallLength () {
    if (!this.entity.wall) {
      return 0
    }
    let length = 0
    const points = this.entity.wall.positions.getValue(Cesium.JulianDate.now())
    for (let i = 0; i < points.length - 1; i++) {
      const distance = Cesium.Cartesian3.distance(i, i + 1)
      length += distance
    }
    return length
  }

  /**
   * 创建圆/椭圆形
   * @param {*} options
   */
  createEllipse (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _semiMajorAxis = Cesium.defaultValue(_options.radius, 1)
    const _semiMinorAxis = Cesium.defaultValue(_options.radius, 1)
    const _material = Cesium.defaultValue(_options.material, Cesium.Color.fromCssColorString('#1890FF80'))
    const _heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.CLAMP_TO_GROUND)
    this.entity.ellipse = new Cesium.EllipseGraphics({
      show: _show,
      semiMajorAxis: _semiMajorAxis,
      semiMinorAxis: _semiMinorAxis,
      material: _material,
      heightReference: _heightReference
    })
  }

  /**
   * 通过圆/椭圆形创建多边形
   * @param {*} options
   */
  createPolygonFromEllipse (options) {
    const _options = Cesium.defaultValue(options, {})
    const _show = Cesium.defaultValue(_options.show, true)
    const _semiMajorAxis = Cesium.defaultValue(_options.radius, 1)
    const _semiMinorAxis = Cesium.defaultValue(_options.radius, 1)
    const _centerPosition = Cesium.defaultValue(_options.centerPosition, undefined)
    const _material = Cesium.defaultValue(_options.material, Cesium.Color.fromCssColorString('#1890FF80'))
    // const _heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.CLAMP_TO_GROUND)
    let originPos
    if (!_centerPosition) {
      originPos = this.getPosition()
    } else {
      originPos = Cesium.Cartesian3.fromDegrees(_centerPosition[0], _centerPosition[1], _centerPosition[2] ? _centerPosition[2] : 0)
    }

    const cep = Cesium.EllipseGeometryLibrary.computeEllipsePositions({
      center: originPos,
      semiMajorAxis: _semiMajorAxis,
      semiMinorAxis: _semiMinorAxis,
      rotation: 0.0,
      granularity: Cesium.Math.RADIANS_PER_DEGREE
    }, false, true)

    this.entity.polygon = new Cesium.PolygonGraphics({
      show: _show,
      hierarchy: {
        positions: Cesium.Cartesian3.unpackArray(cep.outerPositions)
      },
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      classificationType: Cesium.ClassificationType.BOTH,
      material: _material
    })
    this.entity.polyline = new Cesium.PolylineGraphics({
      positions: Cesium.Cartesian3.unpackArray(cep.outerPositions),
      show: _show,
      clampToGround: true,
      classificationType: Cesium.ClassificationType.BOTH
    })
  }

  /**
   * 设置圆心半径
   * @param {*} centerPos 圆心
   * @param {*} radius 半径
   */
  setCircleGis (centerPos, radius) {
    const positions = getEllipsePositions({
      centerPosition: Cesium.Cartesian3.fromDegrees(centerPos[0], centerPos[1], centerPos[2]),
      radius: radius
    })
    this.entity.polygon.hierarchy = new Cesium.PolygonHierarchy(positions)
    this.entity.polyline.positions = positions.concat([positions[0]])
    if (this.entity.properties.hasProperty('center_position_')) {
      this.entity.properties.removeProperty('center_position_')
    }
    this.entity.properties.addProperty('center_position_', JSON.parse(JSON.stringify(centerPos)))
    if (this.entity.properties.hasProperty('radius_')) {
      this.entity.properties.removeProperty('radius_')
    }
    this.entity.properties.addProperty('radius_', radius)
  }

  /**
   * 设置标注标题
   * @param {String} text
   */
  setLabel (text, isShow, labelStyle) {
    if (!this.entity.label) {
      this.createLabel({
        text: text,
        show: !!isShow,
        labelStyle: labelStyle,
        disableDepthTestDistance: 0
      })
    }
    if (this.entity.label) {
      this.entity.label.fillColor = (labelStyle && labelStyle.labelColor) ? Cesium.Color.fromCssColorString(labelStyle.labelColor) : Cesium.Color.WHITE
      this.entity.label.backgroundColor = (labelStyle && labelStyle.labelBackgroundColor) ? Cesium.Color.fromCssColorString(labelStyle.labelBackgroundColor) : Cesium.Color.fromCssColorString('#272829')
      this.entity.label.font = (labelStyle && labelStyle.labelFont) ? `12px ${labelStyle.labelFont}` : '12px 黑体'
    }
    if (text === this.entity.label.text) {
      return
    }
    this.entity.label.text = text
  }

  show (isShow) {
    this.entity.show = isShow
  }

  showBillboard (isShow) {
    if (!this.entity.billboard) {
      return
    }
    this.entity.billboard.show = isShow
  }

  /**
   * 显示名称
   * @param {Boolen} isShow 是否显示
   */
  showLabel (isShow) {
    if (!this.entity.label) {
      return
    }
    const alway = this.entity.properties && this.entity.properties.labelAlwayShow
    this.entity.label.show = alway || isShow
  }

  showPolyline (isShow) {
    if (!this.entity.polyline) {
      return
    }
    this.entity.polyline.show = isShow
  }

  showPolygon (isShow) {
    if (!this.entity.polygon) {
      return
    }
    this.entity.polygon.show = isShow
  }

  showWall (isShow) {
    if (!this.entity.wall) {
      return
    }
    this.entity.wall.show = isShow
  }

  // cesium事件
  onCesiumEvent (type, event) {

  }

  /**
   * 设置样式
   * @param {MarkerStyle[]} styles
   */
  setStyle (styles) {
    if (styles.length === this._curStyles.length) {
      if (styles.length <= 0) {
        return
      }
      if (styles.length === 1 && styles[0].equals(this._curStyles[0])) {
        return
      }
    }
    this._lastStyles = this._curStyles
    this._curStyles = styles
    for (let i = 0; i < styles.length; i++) {
      const style = styles[i]
      if (style) {
        style.decorate(this.entity)
      } else {
        console.error('setStyle error', style)
      }
    }
  }

  /**
   * 创建标注特效
   * @param {Object} effect 标注特效
   */
  setEffect (effect) {
    if (this._effect) {
      this._effect.destroy()
    }
    this._effect = effect
    this._effect.bindMarker(this)
  }
  /**
   * 通过属性名称获取标注属性
   * @param {String} name 属性名称
   */
  getPropertyByName(name) {
    return this.properties.getValue(Cesium.JulianDate())[name]
  }

  // 通过属性id设置label内容
  setLabelFromProperty (propertyKey, originKey, type, options, labelStyle) {
    if (!this.entity || !this.entity.properties) {
      return
    }
    const values = this.entity.properties.getValue(Cesium.JulianDate.now())
    let value = propertyKey ? values[propertyKey] : undefined
    if (!value && originKey) {
      value = values[originKey]
    }
    if (propertyKey === 'empty') {
      value = undefined
    }
    if (type === 'DATE') {
      value = new Date(value).format('yyyy/MM/dd')
    } else if (type === 'DATE_TIME') {
      value = new Date(value).format('yyyy/MM/dd hh:mm:ss')
    } else if (type === 'SELECT') {
      const key = value.split(',')
      let optionValue = ''
      for (let j = 0; j < key.length; j++) {
        const subKey = key[j]
        for (let k = 0; k < options.length; k++) {
          if (subKey.toString() === options[k].id.toString()) {
            optionValue += options[k].value
            if (j < key.length - 1) {
              optionValue += ','
            }
          }
        }
      }
      value = optionValue
    }
    if (value) {
      this.setLabel(value, false, labelStyle)
    } else {
      this.setLabel('', false, labelStyle)
    }
    // this.setLabel('sdsfds', false, {
    //   labelBackgroundColor: '#272829FF',
    //   labelColor: '#FFFFFF',
    //   labelFont: '黑体'
    // })
  }

  /**
   * entity 转换为geojson格式字符串
   * @returns {String} 转换为geojson格式字符串
   */
  toGeoJson () {
    const geoData = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: undefined,
        coordinates: []
      }
    }
    // let jsonData = {
    //   type: "FeatureCollection",
    //   features: []
    // }
    if (this.entity) {
      let height_ = []
      if ((this.entity.point || this.entity.billboard) && this.entity.position) {
        const _pos = cartesianToLonLat(this.entity.position.getValue(Cesium.JulianDate.now()))
        // let geoData = JSON.parse(JSON.stringify(jsonTmp))
        geoData.geometry.type = 'Point'
        geoData.geometry.coordinates = [_pos.longitude, _pos.latitude, _pos.height]

        geoData.properties.id = this.entity.id
        height_ = [_pos.height]
      } else if (this.entity.polygon) {
        geoData.geometry.type = 'Polygon'
        height_ = []
        const positions = this.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
        const pointList = []
        for (let i = 0; i < positions.length; i++) {
          // 相邻两个点相同
          if (i > 0 && Cesium.Cartesian3.equals(positions[i], positions[i - 1])) {
            continue
          }
          const point = positions[i]
          const _pos = cartesianToLonLat(point)
          pointList.push([_pos.longitude, _pos.latitude, _pos.height])
          height_.push(_pos.height)
        }
        const length = pointList.length
        if (pointList[0][0] !== pointList[length - 1][0] || pointList[0][1] !== pointList[length - 1][1]) {
          pointList.push([pointList[0][0], pointList[0][1], pointList[0][2]])
          height_.push(pointList[0][2])
        }
        geoData.geometry.coordinates.push(pointList)

        geoData.properties.id = this.entity.id
      } else if (this.entity.polyline) {
        geoData.geometry.type = 'LineString'
        height_ = []
        // const positions = this.entity.polyline.positions.getValue(Cesium.JulianDate.now())
        // for (let i = 0; i < positions.length; i++) {
        //   const point = positions[i]
        //   const _pos = cartesianToLonLat(point)
        //   geoData.geometry.coordinates.push([_pos.longitude, _pos.latitude, _pos.height])
        //   height_.push(_pos.height)
        // }
        const positions = this.getPolylineGisPositions()
        for (let i = 0; i < positions.length; i++) {
          geoData.geometry.coordinates.push([positions[i][0], positions[i][1], positions[i][2]])
          height_.push(positions[i][2])
        }
      } else if (this.entity.wall) {
        geoData.geometry.type = 'LineString'
        height_ = []
        // const positions = this.entity.wall.positions.getValue(Cesium.JulianDate.now())
        // for (let i = 0; i < positions.length; i++) {
        //   const point = positions[i]
        //   const _pos = cartesianToLonLat(point)
        //   geoData.geometry.coordinates.push([_pos.longitude, _pos.latitude, _pos.height])
        //   height_.push(_pos.height)
        // }
        const positions = this.getPolylineGisPositions()
        for (let i = 0; i < positions.length; i++) {
          geoData.geometry.coordinates.push([positions[i][0], positions[i][1], positions[i][2]])
          height_.push(positions[i][2])
        }
      }
      geoData.properties.height_ = JSON.stringify(height_)
      geoData.properties.id = this.entity.id
      geoData.properties.draw_mode_ = this._drawMode.toString()
      if (this._drawMode === DrawMode.Circle) {
        if (this.entity.properties.hasProperty('center_position_')) {
          geoData.properties.center_position_ = JSON.stringify(this.entity.properties.center_position_.getValue())
        }
        if (this.entity.properties.hasProperty('radius_')) {
          geoData.properties.radius_ = this.entity.properties.radius_.getValue().toString()
        }
      }
      // jsonData.features.push(geoData)
    }
    return JSON.stringify(geoData)
  }

  /**
   * 销毁marker
   */
  destroy () {
    // Cesium.destroyObject(this);
    if (this._effect) {
      this._effect.destroy()
      this._effect = null
    }
    this._entity = null
    this._curStyles = null
    this._lastStyles = null
  }

  /**
   * 从geoJson格式创建Marker
   * @param {Object} json
   */
  static fromGeoJson (context, json, options) {
    const layerId = json.layerId
    return new Promise((resolve, reject) => {
      Cesium.GeoJsonDataSource.load(json, { clampToGround: true }).then(geoDataSource => {
        const entities = geoDataSource.entities.values
        const markers = []
        for (let i = 0; i < entities.length; i++) {
          // let drawMode = json.properties && json.properties.draw_mode_ ? parseInt(json.properties.draw_mode_) : undefined
          // if (!drawMode || isNaN(drawMode)) {
          //   if (json.geometry.type === 'Point') {
          //     drawMode = DrawMode.Marker
          //   } else if (json.geometry.type === 'LineString') {
          //     drawMode = DrawMode.Polyline
          //   } else if (json.geometry.type === 'Polygon') {
          //     drawMode = DrawMode.Polygon
          //   }
          // }
          let drawMode = entities[i].properties && entities[i].properties.draw_mode_ ? parseInt(entities[i].properties.draw_mode_.getValue(Cesium.JulianDate.now())) : undefined
          if (!drawMode || isNaN(drawMode)) {
            if (entities[i].billboard) {
              drawMode = DrawMode.Marker
            } else if (entities[i].polygon) {
              drawMode = DrawMode.Polygon
            } else if (entities[i].polyline) {
              drawMode = DrawMode.Polyline
            }
          }
          drawMode = drawMode ? parseInt(drawMode) : DrawMode.Hybird
          const marker = new Marker(context, {
            layerId: layerId,
            entity: entities[i],
            drawMode: drawMode
          })
          markers.push(marker)
        }
        resolve(markers)
      })
    })
  }

  static fromGeoJson2 (context, json, options) {
    let drawMode = json.properties && json.properties.draw_mode_ ? parseInt(json.properties.draw_mode_) : undefined
    if (!drawMode || isNaN(drawMode)) {
      if (json.geometry.type === 'Point') {
        drawMode = DrawMode.Marker
      } else if (json.geometry.type === 'LineString') {
        drawMode = DrawMode.Polyline
      } else if (json.geometry.type === 'Point') {
        drawMode = DrawMode.Polygon
      }
    }
    let billboard
    let position
    let polyline
    let polygon
    if (json.geometry.type === 'Point') {
      billboard = {}
      position = json.geometry.coordinates[0]
    } else if (json.geometry.type === 'LineString') {
      drawMode = DrawMode.Polyline
      polyline = {
        positions: json.geometry.coordinates[0]
      }
    } else if (json.geometry.type === 'Polygon') {
      drawMode = DrawMode.Polygon
      polyline = {
        positions: json.geometry.coordinates[0]
      }
      polygon = {
        positions: json.geometry.coordinates[0]
      }
    }
    const marker = new Marker(context, {
      layerId: json.layerId,
      drawMode: drawMode,
      billboard,
      position,
      polyline,
      polygon
    })
    return marker
  }
}

export default Marker
