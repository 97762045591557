import PinBuilder from '../Marker/PinBuilder'
// 公告板聚合
class BillboardCluster {
  #bundle = undefined
  #cluster = undefined
  #viewer = undefined
  #pinBuilder = undefined
  constructor (bundle, options) {
    this.#bundle = bundle
    const _options = options ?? {}
    this.#cluster = new Cesium.EntityCluster(_options)
    this.#viewer = this.#bundle.viewer
    this.#cluster._initialize(this.#viewer.scene)
    this.#viewer.scene.primitives.add(this.#cluster)
    this.#pinBuilder = new PinBuilder()
    // this.#cluster._billboardCollection = this.#bundle.collection
    this.setClusterIcons()
    this.updateClusterItem()
  }

  createPinFromText (text, color1, color2, color3, size) {
    const cesColor1 = Cesium.Color.fromCssColorString(color1)
    const cesColor2 = Cesium.Color.fromCssColorString(color2)
    const cesColor3 = Cesium.Color.fromCssColorString(color3)
    return this.#pinBuilder.fromText(text, cesColor1, cesColor2, cesColor3, size)
  }

  setClusterIcons () {
    const cluster = this.#cluster
    const clusterImage = []
    // 创建默认聚合图标
    if (clusterImage.length <= 0) {
      const pin100 = this.createPinFromText('100+', '#f0af6d', '#e08832', '#e8ab74', 50)
      const pin50 = this.createPinFromText('50+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin40 = this.createPinFromText('40+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin30 = this.createPinFromText('30+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin20 = this.createPinFromText('20+', '#f1e795', '#d1c023', '#e2d67a', 50)
      const pin10 = this.createPinFromText('10+', '#f1e795', '#d1c023', '#e2d67a', 50)
      clusterImage.push({ count: 100, pin: pin100 })
      clusterImage.push({ count: 50, pin: pin50 })
      clusterImage.push({ count: 40, pin: pin40 })
      clusterImage.push({ count: 30, pin: pin30 })
      clusterImage.push({ count: 20, pin: pin20 })
      clusterImage.push({ count: 10, pin: pin10 })
      for (let i = 0; i < 8; ++i) {
        const pin = this.createPinFromText('' + (i + 2), 'rgb(159, 210, 133)', 'rgb(95, 172, 56)', 'rgb(128, 193, 95)', 50)
        clusterImage.push({ count: i + 2, pin: pin })
      }
    }
    clusterImage.sort(function (a, b) {
      return b.count - a.count
    })

    // cluster.enabled = enable
    // cluster.pixelRange = pixelRange
    // cluster.minimumClusterSize = minimumClusterSize
    cluster.clusterEvent.addEventListener((clusteredEntities, cluster) => {
      cluster.label.show = false
      cluster.billboard.show = this.#bundle.show
      cluster.billboard.id = cluster.label.id
      cluster.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY
      for (let i = 0; i < clusterImage.length; i++) {
        if (clusteredEntities.length >= clusterImage[i].count) {
          cluster.billboard.image = clusterImage[i].pin
          break
        }
      }
    })
  }

  set show (value) {
    this.#cluster._clusterDirty = true
  }

  get enabled () {
    return this.#cluster.enabled
  }

  set enabled (value) {
    if (value === this.#cluster.enabled) {
      return
    }
    this.#cluster.enabled = value
    if (value) {
      this.updateClusterItem()
    }
  }

  get cluster () {
    return this.#cluster
  }

  updateClusterItem () {
    if (!this.#cluster.enabled) {
      return
    }
    const cluster = this.#cluster
    this.#bundle.forEach(item => {
      const billboard = cluster.getBillboard(item)
      billboard.originPrimitive = item
      billboard.id = item
      billboard.position = item.position
      billboard.width = item.width
      billboard.height = item.height
      billboard.billboard = item
      billboard.show = item.show
    })
    cluster._clusterDirty = true
  }

  removeClusterItem (billboard) {
    this.#cluster.removeBillboard(billboard)
  }

  destroy () {
    this.#viewer.scene.primitives.remove(this.#cluster)
    this.#cluster = undefined
  }
}

export default BillboardCluster
