<template>
  <div class="weather-container">
    <div id="he-plugin-simple"></div>
    <div class="weather-cover"></div>
  </div>
</template>

<script>
window.WIDGET = {
  "CONFIG": {
    "modules": "102",
    "background": "5",
    "tmpColor": "FFFFFF",
    "tmpSize": "32",
    "cityColor": "FFFFFF",
    "citySize": "32",
    "aqiColor": "FFFFFF",
    "aqiSize": "16",
    "weatherIconSize": "44",
    "alertIconSize": "18",
    "padding": "0",
    "shadow": "0",
    "language": "auto",
    "fixed": "false",
    "vertical": "top",
    "horizontal": "left",
    "key": "740daa3f05bf447baad51e49fc34ad22"
  }
}
export default {
  name: 'index',
  data() {
    return {}
  },
  mounted() {
    this.$nextTick(() => {
      this.load()
    })
  },
  methods: {
    load() {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = 'https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0'
      document.body.appendChild(s)
    }
  }
}
</script>

<style lang="scss" scoped>
  .weather-container {
    position: relative;
    .weather-cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
</style>