class FirstPersonCameraController {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer
    // 是否开启键盘控制
    this._isKeyControl = false
    // 是否开启滚轮
    this._isWheel = false
    // 是否开启第一人称模式
    this._enabled = false
    // 摄像机移动方向
    this._rightDir = []
    this._upDir = []
    // 摄像机移动缩放值
    this.defalutScale = 1
    this.speedScale = this.defalutScale
    // 摄像机旋转灵敏度
    this.rotateSpeed = (2 * Cesium.Math.PI) / 4000
    // 抬高降低摄像机高度速度
    this.heightSpeed = 100
    // shift键移动速度
    this.shiftAmount = 100000
    // 当前摄像机偏移属性
    this.curPitch = this._viewer.camera.pitch
    this.curheading = this._viewer.camera.heading

    const _self = this
    function pointerLockChange () {
      const elem = document.documentElement
      if (
        document.mozPointerLockElement === elem ||
        document.webkitPointerLockElement === elem ||
        document.pointerLockElement === elem
      ) {
        // console.log("指针锁定成功了。");
      } else {
        // console.log("指针锁定已丢失。");
        _self.enabled = false
      }
    }

    document.addEventListener('pointerlockchange', pointerLockChange, false)
    document.addEventListener('mozpointerlockchange', pointerLockChange, false)
    document.addEventListener('webkitpointerlockchange', pointerLockChange, false)

    // 注册鼠标键盘事件
    window.addEventListener('keydown', function (event) {
      _self.onKeyDown(event)
    }, true)
    window.addEventListener('mousemove', function (event) {
      _self.onMouseEvent(event)
    })
    window.addEventListener('wheel', function (event) {
      _self.onWheelEvent(event)
    })
  }

  get viewer () {
    return this._viewer
  }

  get enabled () {
    return this._enabled
  }

  set enabled (value) {
    this._enabled = value
    if (this._enabled) {
      // 当前摄像机偏移属性
      this.curPitch = this._viewer.camera.pitch
      this.curheading = this._viewer.camera.heading
      this.lockPoint()
    }
  }

  get isKeyControl () {
    return this._isKeyControl
  }

  set isKeyControl (value) {
    this._isKeyControl = value
  }

  get isWheel () {
    return this._isWheel
  }

  set isWheel (value) {
    this._isWheel = value
  }

  lockPoint () {
    const elem = document.documentElement
    elem.requestPointerLock = elem.requestPointerLock || elem.mozRequestPointerLock || elem.webkitRequestPointerLock
    elem.requestPointerLock()
  }

  // 计算控制速度
  calculateSpeed () {
    // 屏幕宽高
    const width = this.viewer.scene.canvas.clientWidth
    const height = this.viewer.scene.canvas.clientHeight
    const mid = this.viewer.scene.camera.getPickRay(new Cesium.Cartesian2((width / 2) | 0, height - 1))
    const right = this.viewer.scene.camera.getPickRay(new Cesium.Cartesian2((1 + width / 2) | 0, height - 1))
    const up = this.viewer.scene.camera.getPickRay(new Cesium.Cartesian2((width / 2) | 0, height - 2))
    const midPosition = this.viewer.scene.globe.pick(mid, this.viewer.scene)
    const rightPosition = this.viewer.scene.globe.pick(right, this.viewer.scene)
    const upPosition = this.viewer.scene.globe.pick(up, this.viewer.scene)
    if (!midPosition || !rightPosition || !upPosition) {
      return
    }
    const midCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(midPosition)
    const rightCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(rightPosition)
    const upCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(upPosition)
    this._rightDir[0] = (rightCartographic.longitude - midCartographic.longitude) * 20
    this._rightDir[1] = (rightCartographic.latitude - midCartographic.latitude) * 20
    this._upDir[0] = (upCartographic.longitude - midCartographic.longitude) * 20
    this._upDir[1] = (upCartographic.latitude - midCartographic.latitude) * 20
  }

  // 键盘事件
  onKeyDown (event) {
    if (!this._enabled || !this._isKeyControl) {
      return
    }
    this.calculateSpeed()
    const key = event.which || event.keyCode
    // console.log('press key = ',key)
    switch (key) {
      // 上
      case 87:
      case 38: {
        this.moveToForward(true)
        break
      }
      // 下
      case 83:
      case 40: {
        this.moveToForward(false)
        break
      }
      // 左
      case 65:
      case 37: {
        this.moveToRight(false)
        break
      }
      // 右
      case 68:
      case 39: {
        this.moveToRight(true)
        break
      }
      // 空格
      case 32: {
        this.upDownCamera(this.heightSpeed)
        break
      }
    }
  }

  // 鼠标移动事件
  onMouseEvent (event) {
    if (!this._enabled) {
      return
    }
    const x = event.movementX || event.mozMovementX || event.webkitMovementX || 0
    const y = event.movementY || event.mozMovementY || event.webkitMovementY || 0

    this.curheading = this.curheading + x * this.rotateSpeed
    this.curPitch = this.curPitch - y * this.rotateSpeed
    if (this.curheading > Cesium.Math.TWO_PI) {
      this.curheading = this.curheading - Cesium.Math.TWO_PI
    }
    if (this.curheading < -Cesium.Math.TWO_PI) {
      this.curheading = this.curheading + Cesium.Math.TWO_PI
    }

    if (this.curPitch > Cesium.Math.TWO_PI) {
      this.curPitch = this.curPitch - Cesium.Math.TWO_PI
    }
    if (this.curPitch < -Cesium.Math.TWO_PI) {
      this.curPitch = this.curPitch + Cesium.Math.TWO_PI
    }

    const roll = 0.0
    this._viewer.camera.setView({
      orientation: {
        heading: this.curheading,
        pitch: this.curPitch,
        roll: roll
      }
    })
  }

  // 滚轮事件
  onWheelEvent (event) {
    if (!this._enabled || !this._isWheel) {
      return
    }
    const cameraHeight = this._viewer.camera.positionCartographic.height
    const value = event.wheelDelta / 1000

    if (value > 0) {
      this._viewer.camera.zoomIn(Math.abs(cameraHeight * value))
    } else if (value < 0) {
      this._viewer.camera.zoomOut(Math.abs(cameraHeight * value))
    }
  }

  // 向前移动
  moveToForward (isForward) {
    let lonSpeed = this._upDir[0] * this.speedScale
    let latSpeed = this._upDir[1] * this.speedScale
    if (!isForward) {
      lonSpeed = lonSpeed * -1
      latSpeed = latSpeed * -1
    }
    const heading = this.viewer.camera.heading
    const pitch = this.viewer.camera.pitch
    const positionCartographic = Cesium.Cartographic.fromCartesian(this.viewer.camera.position)
    const movePos = new Cesium.Cartographic(
      positionCartographic.longitude + lonSpeed,
      positionCartographic.latitude + latSpeed,
      positionCartographic.height
    )
    this.viewer.camera.position = Cesium.Cartesian3.fromRadians(movePos.longitude, movePos.latitude, movePos.height)

    this.viewer.camera.setView({
      orientation: {
        heading: heading,
        pitch: pitch,
        roll: 0.0
      }
    })
  }

  // 向右移动
  moveToRight (isRight) {
    let lonSpeed = this._rightDir[0] * this.speedScale
    let latSpeed = this._rightDir[1] * this.speedScale
    if (!isRight) {
      lonSpeed = lonSpeed * -1
      latSpeed = latSpeed * -1
    }
    const heading = this.viewer.camera.heading
    const pitch = this.viewer.camera.pitch
    const positionCartographic = Cesium.Cartographic.fromCartesian(this.viewer.camera.position)
    const movePos = new Cesium.Cartographic(
      positionCartographic.longitude + lonSpeed,
      positionCartographic.latitude + latSpeed,
      positionCartographic.height
    )
    this.viewer.camera.position = Cesium.Cartesian3.fromRadians(movePos.longitude, movePos.latitude, movePos.height)

    this.viewer.camera.setView({
      orientation: {
        heading: heading,
        pitch: pitch,
        roll: 0.0
      }
    })
  }

  // 上下移动摄像机
  upDownCamera (amount) {
    const cameraPos = this.viewer.camera.position
    const cameraCartographic = Cesium.Cartographic.fromCartesian(cameraPos)
    const height = cameraCartographic.height + amount
    this.viewer.camera.position = Cesium.Cartesian3.fromRadians(cameraCartographic.longitude, cameraCartographic.latitude, height)
  }
}

export default FirstPersonCameraController
