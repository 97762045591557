
var defaultColor = Cesium.Color.WHITE
var defaultGlowPower = 0.05
var defaultTaperPower = 1.0

/**
 * PolylineGlow材质修改版，线条自发光
 * @constructor
 *
 * @param {Object} [options] Object with the following properties:
 * @param {Property} [options.color=Color.WHITE] A Property specifying the {@link Color} of the line.
 * @param {Property} [options.glowPower=0.25] A numeric Property specifying the strength of the glow, as a percentage of the total line width.
 * @param {Property} [options.taperPower=1.0] A numeric Property specifying the strength of the tapering effect, as a percentage of the total line length.  If 1.0 or higher, no taper effect is used.
 */
function PolylineGlowExMaterialProperty (options) {
  options = Cesium.defaultValue(options, Cesium.defaultValue.EMPTY_OBJECT)

  this._definitionChanged = new Cesium.Event()
  this._color = undefined
  this._colorSubscription = undefined
  this._glowPower = undefined
  this._glowPowerSubscription = undefined
  this._taperPower = undefined
  this._taperPowerSubscription = undefined

  this.color = options.color
  this.glowPower = options.glowPower
  this.taperPower = options.taperPower
}

Object.defineProperties(PolylineGlowExMaterialProperty.prototype, {
  /**
   * Gets a value indicating if this property is constant.  A property is considered
   * constant if getValue always returns the same result for the current definition.
   * @memberof PolylineGlowExMaterialProperty.prototype
   * @type {Boolean}
   * @readonly
   */
  isConstant: {
    get: function () {
      return Cesium.Property.isConstant(this._color) && Cesium.Property.isConstant(this._glow)
    }
  },
  /**
   * Gets the event that is raised whenever the definition of this property changes.
   * The definition is considered to have changed if a call to getValue would return
   * a different result for the same time.
   * @memberof PolylineGlowExMaterialProperty.prototype
   * @type {Event}
   * @readonly
   */
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
  /**
   * Gets or sets the Property specifying the {@link Color} of the line.
   * @memberof PolylineGlowExMaterialProperty.prototype
   * @type {Property}
   */
  color: Cesium.createPropertyDescriptor('color'),

  /**
   * Gets or sets the numeric Property specifying the strength of the glow, as a percentage of the total line width (less than 1.0).
   * @memberof PolylineGlowExMaterialProperty.prototype
   * @type {Property}
   */
  glowPower: Cesium.createPropertyDescriptor('glowPower'),

  /**
   * Gets or sets the numeric Property specifying the strength of the tapering effect, as a percentage of the total line length.  If 1.0 or higher, no taper effect is used.
   * @memberof PolylineGlowExMaterialProperty.prototype
   * @type {Property}
   */
  taperPower: Cesium.createPropertyDescriptor('taperPower')
})

/**
 * Gets the {@link Material} type at the provided time.
 *
 * @param {JulianDate} time The time for which to retrieve the type.
 * @returns {String} The type of material.
 */
PolylineGlowExMaterialProperty.prototype.getType = function (time) {
  return 'PolylineGlowEx'
}

/**
 * Gets the value of the property at the provided time.
 *
 * @param {JulianDate} time The time for which to retrieve the value.
 * @param {Object} [result] The object to store the value into, if omitted, a new instance is created and returned.
 * @returns {Object} The modified result parameter or a new instance if the result parameter was not supplied.
 */
PolylineGlowExMaterialProperty.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }
  result.color = Cesium.Property.getValueOrClonedDefault(this._color, time, defaultColor, result.color)
  result.glowPower = Cesium.Property.getValueOrDefault(this._glowPower, time, defaultGlowPower, result.glowPower)
  result.taperPower = Cesium.Property.getValueOrDefault(this._taperPower, time, defaultTaperPower, result.taperPower)
  return result
}

/**
 * Compares this property to the provided property and returns
 * <code>true</code> if they are equal, <code>false</code> otherwise.
 *
 * @param {Property} [other] The other property.
 * @returns {Boolean} <code>true</code> if left and right are equal, <code>false</code> otherwise.
 */
PolylineGlowExMaterialProperty.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof PolylineGlowExMaterialProperty &&
      Cesium.Property.equals(this._color, other._color) &&
      Cesium.Property.equals(this._glowPower, other._glowPower) &&
      Cesium.Property.equals(this._taperPower, other._taperPower))
  )
}

Cesium.Material.PolylineGlowExMaterialType = 'PolylineGlowEx'
Cesium.Material.PolylineGlowExMaterialLinkSource =
  `uniform vec4 color;
  uniform float glowPower;
  uniform float taperPower;

  varying float v_width;

  czm_material czm_getMaterial(czm_materialInput materialInput)
  {
      czm_material material = czm_getDefaultMaterial(materialInput);

      vec2 st = materialInput.st;
      float glow = glowPower / abs(st.t - 0.5) - (glowPower / 0.5);

      if (taperPower <= 0.99999) {
          glow *= min(1.0, taperPower / (0.5 - st.s * 0.5) - (taperPower / 0.5));
      }

      vec4 fragColor;
      fragColor.rgb = max(vec3(glow - 1.0 + color.rgb), color.rgb);
      fragColor.a = clamp(0.0, 1.0, glow) * color.a;
      fragColor = czm_gammaCorrect(fragColor);

      material.emission = color.rgb;
      material.alpha = fragColor.a;

      return material;
  }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.PolylineGlowExMaterialType, {
  fabric: {
    type: Cesium.Material.PolylineGlowExMaterialType,
    uniforms: {
      color: new Cesium.Color(0.0, 0.5, 1.0, 1.0),
      glowPower: 0.25,
      taperPower: 1.0
    },
    source: Cesium.Material.PolylineGlowExMaterialLinkSource
  },
  translucent: true
})
Cesium.PolylineGlowExMaterialProperty = PolylineGlowExMaterialProperty
