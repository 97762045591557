<template>
  <div class="page-container bg">
    <!--      <dv-loading v-if="loading">Loading...</dv-loading>-->
    <cesium-viewer ref="cesiumViewer" @liveClick="handleLiveClick"></cesium-viewer>
    <div class="header">
      <h1 class="title">{{ farmInfo.name }}</h1>
      <div class="header-content">
        <div class="left">
          <dv-decoration-3 :color="['#16854c']" style="width:100px;height:50px;"/>
          <weather></weather>
          <div class="timer">
              <span class="text">
                {{ dateDay }}
              </span>
          </div>
        </div>
        <div class="right">
          <dv-decoration-3 style="width:150px;height:50px;" :color="['#16854c']"/>
          <div class="btn" @click="toggleVideoShow">视频宣传
            <span
                class="iconfont"
                :class="videoShow ? 'icon-view' : 'icon-view_off'">
              </span>
          </div>
          <div class="btn" @click="flyToDefault">初始定位
            <span class="iconfont icon-region">
              </span>
          </div>
        </div>
      </div>
    </div>
    <div
        class="live-container"
        v-for="(item, index) in liveList"
        :key="'live' + index"
        :style="getLiveBoxStyle(index)"
    >
      <transition
          name="animate__animated animate__bounce"
          :enter-active-class="index <= 1 ? 'animate__bounceInLeft' : 'animate__bounceInRight'"
          :leave-active-class="index <= 1 ? 'animate__bounceOutLeft' : 'animate__bounceOutRight'"
          appear
      >
        <dv-border-box-8 :color="['#63836F', '#05d068']" v-if="item.liveShow">
          <div class="live-box">
            <div class="box-inner">
              <h1 class="name">{{ item.name }}</h1>
              <video-player
                  :options="item.options"
              ></video-player>
            </div>
          </div>
        </dv-border-box-8>
      </transition>
    </div>

    <transition
        name="animate__animated animate__fade"
        enter-active-class="animate__fadeIn"
        leave-active-class="animate__fadeOut"
        appear
    >
      <div class="video-container" v-if="videoShow">
        <div class="box">
          <video
              :src="farmInfo.videoUrl"
              controls
              autoplay
              loop
          ></video>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import drawMixin from '@/utils/drawMixin'
import {formatTime} from '@/utils/index.js'
import {getLivePoints} from '@/api/model'
import {getFarmInfo} from '@/api/farm'
import Weather from '@/components/Weather'
import CesiumViewer from '@/views/cesium-viewer'
import {videoPlayer} from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import 'videojs-contrib-hls'
import 'videojs-flvjs-es6'


export default {
  mixins: [],
  data() {
    return {
      timing: null, // 时间
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      liveShow: true,
      videoShow: false,
      farmInfo: {
        name: '',
        videoUrl: '',
        videoTime: 180000
      },
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      modelOptions: null,
      videoTimer: null,
      liveList: [] // 直播配置
    }
  },
  components: {
    Weather,
    CesiumViewer,
    videoPlayer
  },
  created() {
    // 加载农场基础信息
    this.loadFarmData()
    // 加载监控数据
    this.loadLiveData()
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
    clearInterval(this.videoTimer)
  },
  methods: {
    async loadFarmData() {
      const data = await getFarmInfo()
      this.farmInfo = data
    },
    // 加载监控画面
    async loadLiveData() {
      const liveData = await getLivePoints()
      this.liveList = liveData.map(item => {
        return {
          name: item.name,
          liveShow: false,
          options: {
            preload: 'auto',
            sources: [{
              type: 'video/x-flv',
              src: item.url
            }],
            width: '480px',
            techOrder: ['html5', 'flvjs'], // 兼容顺序
            autoplay: true,
            controls: true,
            muted: true
          }
        }
      })
    },
    // 时间显示
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH:mm:ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 开启关闭显示视频
    toggleVideoShow() {
      // 重置video时间监听
      clearInterval(this.videoTimer)
      this.videoTimeFn()
      this.videoShow = !this.videoShow
    },
    // 飞往初始位置
    flyToDefault() {
      this.$refs.cesiumViewer.flyToDefault()
    },
    // 设置直播窗口位置
    getLiveBoxStyle(index) {
      // 确定左右位置
      const style1 = index <= 1 ? 'left: 20px;' : 'right: 20px;'
      // 确定距离顶部位置
      const style2 = `top: ${ 220 + (index % 2) * 380}px;`
      return style1 + style2
    },
    // 监控图标点击回调
    handleLiveClick(index) {
      this.liveList[index].liveShow = !this.liveList[index].liveShow
    },
    // 宣传视频定时器
    videoTimeFn() {
      const self = this
      this.videoTimer = setInterval(() =>{
        self.videoShow = !self.videoShow
      }, self.farmInfo.videoTime)
    }
  },
  watch: {
    farmInfo() {
      this.videoTimeFn()
    }
  }
}
</script>

<style lang="scss" scoped>

.page-container {
  color: #fff;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  letter-spacing: 1px;
  user-select: none;
  background-image: url("../../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
  .header {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    top: 0;
    background-image: url("../../assets/header.png");
    background-size: cover;
    background-position: center center;
    z-index: 100;

    .title {
      text-align: center;
      height: 150px;
      line-height: 150px;
      font-size: 68px;
    }

    .header-content {
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      top: 90px;
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        font-weight: bold;

        .timer {
          margin-left: 10px;
          font-size: 34px;
          font-weight: bold;
        }

      }

      .right {
        display: flex;
        align-items: center;
        .btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          margin-left: 10px;
          background: #63836F;
          box-sizing: border-box;
          border-radius: 4px;
          //background-color: #ffb70d;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
          .iconfont {
            font-size: 24px;
          }
        }
      }
    }
  }

  .live-container {
    position: absolute;
    width: 520px;
    line-height: 1;
    z-index: 10;

    .live-box {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 20px 0;
      background: rgba(0, 0, 0, 0.3);

      .box-inner {
        width: 480px;
        height: 320px;
        background-color: rgba(103, 103, 103, 0.6);

        .name {
          position: relative;
          height: 50px;
          line-height: 50px;
          padding-left: 30px;
          font-size: 20px;
          font-style: italic;
          color: #fff;

          &:before {
            content: ' ';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            position: absolute;
            left: 10px;
            top: 21px;
            background-color: #1DB662;
          }
        }

        video {
          width: 420px;
          height: 236px;
        }
      }

    }

  }

  .video-container {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);

    .box {
      //padding: 4px 4px 0 4px;
      video {
        width: 1920px;
        height: 1080px;
      }
    }

  }
}
</style>
