import MarkerStyle from './MarkerStyle'
import markerImage from '../../Assets/Images/marker.png'
/**
 * 点标注style类
 */
class PointStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'point'
    this._color = _options.color ? _options.color : Cesium.Color.fromCssColorString('#FFFFFF')
    this._image = _options.image ? _options.image : markerImage
    this._width = _options.width ? _options.width : 24
    this._height = _options.height ? _options.height : 30
    this._scale = _options.scale ? _options.scale : 30
    this._clampToGround = _options.clampToGround ? _options.clampToGround : false
    this._isCluster = Cesium.defaultValue(_options.isCluster, false)
  }

  decorate (entity) {
    if (!entity.point) {

    }
  }

  equals (other) {
    return this === other || (
      other instanceof PointStyle &&
        this._type === other._type &&
        this._image === other._image &&
        this._width === other._width &&
        this._height === other._height &&
        this._scale === other._scale &&
        this._clampToGround === other._clampToGround &&
        this._isCluster === other._isCluster &&
        Cesium.Color.equals(this._color, other._color)
    )
  }

  static Default () {
    return new PointStyle({
      color: Cesium.Color.fromCssColorString('#FF0000'),
      image: markerImage,
      width: 24,
      height: 30,
      scale: 1
    })
  }

  static Selected () {
    return new PointStyle({
      color: Cesium.Color.fromCssColorString('#0076F6'),
      image: markerImage,
      width: 24,
      height: 30,
      scale: 1
    })
  }
}

export default PointStyle
