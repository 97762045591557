/**
 * 指南针控件
 */
class CompassView {
  #context = undefined
  #viewer = undefined
  #compass = undefined
  #cancel = undefined
  #cancel2 = undefined

  #curLevel = ''
  #curHeading = 0

  #isMoseDown = false;
  #rotateInitialCursorAngle = 0;
  #rotateInitialCameraAngle = 0;
  #rotateFrame = undefined;
  #rotateIsLook = false;

  #navigationLocked = false
  #orbitFrame = undefined
  #isOrbiting = false
  #orbitLastTimestamp = undefined
  #orbitCursorAngle = 0
  #orbitCursorOpacity = 0

  constructor (context, viewer) {
    this.#context = context
    this.#viewer = viewer

    const { container } = viewer.cesiumWidget
    const compass = this.#compassElement()
    container.appendChild(compass)
    // compass.querySelector('#cesium-compass').onclick = this.resetDirection.bind(this)
    compass.querySelector('.navigation-zoomin').onclick = this.zoomIn.bind(this)
    compass.querySelector('.navigation-zoomout').onclick = this.zoomOut.bind(this)
    compass.querySelector('.cesium-compass').onmousedown = this.rotate.bind(this)
    compass.querySelector('.cesium-compass').ontouchstart = this.rotate.bind(this)
    compass.querySelector('.cesium-compass-orbit').onmousedown = this.orbit.bind(this)
    compass.querySelector('.cesium-compass-orbit').ontouchstart = this.orbit.bind(this)

    this.#compass = compass
    // 同步指南针方向
    this.#cancel2 = viewer.scene.preRender.addEventListener(this.onCameraChanged, this)
  }

  get viewer () {
    return this.#viewer
  }

  get compass () {
    return this.#compass
  }

  #compassElement () {
    const compass = document.createElement('div')
    compass.id = 'navigationContainer'
    // this.navigationView.style = 'position: absolute; bottom: 50px; right: 10px; width: 100px; height: 100px;';
    compass.innerHTML =
      `
     <div id="cesium-compass" class="cesium-compass" style="transform:rotate(0deg)">
     </div>
     <div class="cesium-compass-marker"></div>
     <div class="cesium-compass-orbit"></div>
     <div class="navigation-buttons">
       <div class="navigation-zoomin">+</div>
       <div class="navigation-divider"></div>
       <div id="navigation-level" class="navigation-level">0</div>
       <div class="navigation-divider"></div>
       <div class="navigation-zoomout">-</div>
     </div>
     `
    return compass
  }

  show (isShow) {
    this.#compass.hidden = !isShow
  }

  /**
   * 销毁示例移除事件绑定
   */
  destroy () {
    this.#cancel && this.#cancel()
    this.#cancel2 && this.#cancel2()
    this.#cancel = this.#cancel2 = undefined
    this.#compass = undefined
  }

  // 相机转动事件
  onCameraChanged () {
    if (this.compass !== undefined && this.compass.hidden === false) {
      const level = this.GlobeLevel()
      if (level !== this.#curLevel) {
        this.#curLevel = level
        this.compass.querySelector('#navigation-level').textContent = this.#curLevel
      }
      const heading = this.viewer.camera.heading
      if (this.viewer.camera.heading && heading !== this.#curHeading) {
        const headingDegrees = Cesium.Math.toDegrees(heading)
        this.compass.querySelector('#cesium-compass').style.transform = 'rotate(' + -headingDegrees + 'deg)'
        this.#curHeading = heading
      }
      this.compass.querySelector('.cesium-compass-marker').style.transform = `rotate(${-this.#orbitCursorAngle - Math.PI / 4}rad)`
      this.compass.querySelector('.cesium-compass-marker').style.opacity = this.#orbitCursorOpacity
      this.compass.querySelector('.cesium-compass-marker').hidden = !this.#isOrbiting
    }
  }

  /**
  * 重置摄像机方向为正北方,摄像机位置保存不变
  */
  resetDirection () {
    const curPitch = this.viewer.camera.pitch
    const curRoll = this.viewer.camera.roll
    this.viewer.camera.flyTo({
      destination: this.viewer.camera.position,
      orientation: {
        heading: 0.0,
        pitch: curPitch,
        roll: curRoll
      },
      duration: 1.0
    })
  }

  /**
  * 重置摄像机方向为正北方,以屏幕中心为圆心旋转
  */
  resetCamera () {
    const height = this.viewer.camera.positionCartographic.height

    const screenPos = new Cesium.Cartesian2(this.viewer.canvas.clientWidth / 2, this.viewer.canvas.clientHeight / 2)
    const ray = this.viewer.camera.getPickRay(screenPos)
    const intersection = this.viewer.scene.globe.pick(ray, this.viewer.scene)
    const centerPos = intersection

    const curPitch = this.viewer.camera.pitch
    const curRoll = this.viewer.camera.roll

    this.viewer.camera.setView({
      destination: centerPos,
      orientation: {
        heading: 0.0,
        pitch: curPitch,
        roll: curRoll
      },
      duration: 1.0
    })
    this.viewer.scene.camera.moveBackward(height)
  }

  GlobeLevel () {
    const tilesToRender = this.viewer.scene.globe._surface._tilesToRender
    let level = 0
    for (let i = 0; i < tilesToRender.length; i++) {
      const tile = tilesToRender[i]
      if (tile.level > level) {
        level = tile.level
      }
    }
    return level
  }

  zoomIn () {
    const cameraHeight = this.viewer.camera.positionCartographic.height
    this.viewer.camera.zoomIn(cameraHeight / 10)
  }

  zoomOut () {
    const cameraHeight = this.viewer.camera.positionCartographic.height
    this.viewer.camera.zoomOut(cameraHeight / 10)
  }

  getCameraFocus (inWorldCoordinates, result) {
    if (this.viewer.scene.mode === Cesium.SceneMode.MORPHING) {
      return undefined
    }

    if (!Cesium.defined(result)) {
      result = new Cesium.Cartesian3()
    }

    if (Cesium.defined(this.viewer.trackedEntity)) {
      result = this.viewer.trackedEntity.position.getValue(this.viewer.clock.currentTime, result)
    } else {
      const rayScratch = new Cesium.Ray()
      rayScratch.origin = this.viewer.camera.positionWC
      rayScratch.direction = this.viewer.camera.directionWC
      result = this.viewer.scene.globe.pick(rayScratch, this.viewer.scene, result)
    }

    if (!Cesium.defined(result)) {
      return undefined
    }

    if (this.viewer.scene.mode === Cesium.SceneMode.SCENE2D || this.viewer.scene.mode === Cesium.SceneMode.COLUMBUS_VIEW) {
      result = this.viewer.camera.worldToCameraCoordinatesPoint(result, result)

      if (inWorldCoordinates) {
        const cart = this.viewer.scene.mapProjection.unproject(result, new Cesium.Cartographic())
        result = this.viewer.scene.globe.ellipsoid.cartographicToCartesian(cart, result)
      }
    } else {
      if (!inWorldCoordinates) {
        result = this.viewer.camera.worldToCameraCoordinatesPoint(result, result)
      }
    }
    return result
  }

  // 旋转事件
  rotate (event) {
    event.preventDefault()
    event.stopPropagation()

    document.removeEventListener('mousemove', onRotate, false)
    document.removeEventListener('mouseup', onMouseup, false)
    document.removeEventListener('touchmove', onRotate, false)
    document.removeEventListener('touchend', onMouseup, false)
    this.#isMoseDown = true
    const compassRectangle = event.currentTarget.getBoundingClientRect()
    const center = new Cesium.Cartesian2((compassRectangle.right - compassRectangle.left) / 2.0, (compassRectangle.bottom - compassRectangle.top) / 2.0)
    let clientX = 0
    let clientY = 0
    if (event.type === 'touchstart') {
      clientX = event.touches[0].clientX
      clientY = event.touches[0].clientY
    } else {
      clientX = event.clientX
      clientY = event.clientY
    }
    const clickLocation = new Cesium.Cartesian2(clientX - compassRectangle.left, clientY - compassRectangle.top)
    const vector = Cesium.Cartesian2.subtract(clickLocation, center, new Cesium.Cartesian2())
    this.#rotateInitialCursorAngle = Math.atan2(-vector.y, vector.x)
    this.#rotateInitialCameraAngle = -this.viewer.camera.heading

    if (Cesium.defined(this.viewer.trackedEntity)) {
      // when tracking an entity simply use that reference frame
      this.#rotateFrame = undefined
      this.#rotateIsLook = false
    } else {
      const viewCenter = this.getCameraFocus(true)

      if (!Cesium.defined(viewCenter) || (this.viewer.scene.mode === Cesium.SceneMode.COLUMBUS_VIEW)) {
        this.#rotateFrame = Cesium.Transforms.eastNorthUpToFixedFrame(this.viewer.camera.positionWC, this.viewer.scene.globe.ellipsoid, new Cesium.Matrix4())
        this.#rotateIsLook = true
      } else {
        this.#rotateFrame = Cesium.Transforms.eastNorthUpToFixedFrame(viewCenter, this.viewer.scene.globe.ellipsoid, new Cesium.Matrix4())
        this.#rotateIsLook = false
      }
    }

    let oldTransform
    if (Cesium.defined(this.#rotateFrame)) {
      oldTransform = Cesium.Matrix4.clone(this.viewer.camera.transform)
      this.viewer.camera.lookAtTransform(this.#rotateFrame)
    }

    if (Cesium.defined(this.#rotateFrame)) {
      this.viewer.camera.lookAtTransform(oldTransform)
    }
    document.addEventListener('mousemove', onRotate, false)
    document.addEventListener('mouseup', onMouseup, false)
    document.addEventListener('touchmove', onRotate, false)
    document.addEventListener('touchend', onMouseup, false)
    const self = this

    function onRotate (event2) {
      const compassRectangle = self.compass.querySelector('#cesium-compass').getBoundingClientRect()
      const center = new Cesium.Cartesian2((compassRectangle.right - compassRectangle.left) / 2.0, (compassRectangle.bottom - compassRectangle.top) / 2.0)
      let clientX = 0
      let clientY = 0
      if (event2.type === 'touchmove') {
        clientX = event2.touches[0].clientX
        clientY = event2.touches[0].clientY
      } else {
        clientX = event2.clientX
        clientY = event2.clientY
      }
      const clickLocation = new Cesium.Cartesian2(clientX - compassRectangle.left, clientY - compassRectangle.top)
      const vector = Cesium.Cartesian2.subtract(clickLocation, center, new Cesium.Cartesian2())
      const angle = Math.atan2(-vector.y, vector.x)

      const angleDifference = angle - self.#rotateInitialCursorAngle
      const newCameraAngle = Cesium.Math.zeroToTwoPi(self.#rotateInitialCameraAngle - angleDifference)

      let oldTransform
      if (Cesium.defined(self.#rotateFrame)) {
        oldTransform = Cesium.Matrix4.clone(self.viewer.camera.transform)
        self.viewer.camera.lookAtTransform(self.#rotateFrame)
      }
      self.viewer.camera.rotateRight(newCameraAngle + self.viewer.camera.heading)

      if (Cesium.defined(self.#rotateFrame)) {
        self.viewer.camera.lookAtTransform(oldTransform)
      }
    }

    function onMouseup () {
      document.removeEventListener('mousemove', onRotate, false)
      document.removeEventListener('mouseup', onMouseup, false)
      document.removeEventListener('touchmove', onRotate, false)
      document.removeEventListener('touchend', onMouseup, false)
      self.#isMoseDown = false
    }
  }

  // 翻转事件
  orbit (event) {
    event.preventDefault()
    event.stopPropagation()

    const compassRectangle = event.currentTarget.getBoundingClientRect()
    const center = new Cesium.Cartesian2((compassRectangle.right - compassRectangle.left) / 2.0, (compassRectangle.bottom - compassRectangle.top) / 2.0)
    let clientX = 0
    let clientY = 0
    if (event.type === 'touchstart') {
      clientX = event.touches[0].clientX
      clientY = event.touches[0].clientY
    } else {
      clientX = event.clientX
      clientY = event.clientY
    }
    const clickLocation = new Cesium.Cartesian2(clientX - compassRectangle.left, clientY - compassRectangle.top)
    const cursorVector = Cesium.Cartesian2.subtract(clickLocation, center, new Cesium.Cartesian2())

    const scene = this.viewer.scene

    const sscc = scene.screenSpaceCameraController

    // do not orbit if it is disabled
    if (scene.mode === Cesium.SceneMode.MORPHING || !sscc.enableInputs) {
      return
    }
    if (this.#navigationLocked) {
      return true
    }

    switch (scene.mode) {
      case Cesium.SceneMode.COLUMBUS_VIEW:
        if (sscc.enableLook) {
          break
        }

        if (!sscc.enableTranslate || !sscc.enableTilt) {
          return
        }
        break
      case Cesium.SceneMode.SCENE3D:
        if (sscc.enableLook) {
          break
        }

        if (!sscc.enableTilt || !sscc.enableRotate) {
          return
        }
        break
      case Cesium.SceneMode.SCENE2D:
        if (!sscc.enableTranslate) {
          return
        }
        break
    }

    // Remove existing event handlers, if any.
    document.removeEventListener('mousemove', orbitMouseMoveFunction, false)
    document.removeEventListener('mouseup', orbitMouseUpFunction, false)
    document.removeEventListener('touchmove', orbitMouseMoveFunction, false)
    document.removeEventListener('touchend', orbitMouseUpFunction, false)

    if (Cesium.defined(orbitTickFunction)) {
      this.viewer.clock.onTick.removeEventListener(orbitTickFunction)
    }

    // viewModel.orbitMouseMoveFunction = undefined
    // viewModel.orbitMouseUpFunction = undefined
    // viewModel.orbitTickFunction = undefined

    this.#isOrbiting = true
    this.#orbitLastTimestamp = Cesium.getTimestamp()

    const camera = scene.camera

    if (Cesium.defined(this.viewer.trackedEntity)) {
      // when tracking an entity simply use that reference frame
      this.#orbitFrame = undefined
      this._orbitIsLook = false
    } else {
      const center = this.getCameraFocus(true, new Cesium.Cartesian3())

      if (!Cesium.defined(center)) {
        this.#orbitFrame = Cesium.Transforms.eastNorthUpToFixedFrame(camera.positionWC, scene.globe.ellipsoid, new Cesium.Matrix4())
        this._orbitIsLook = true
      } else {
        this.#orbitFrame = Cesium.Transforms.eastNorthUpToFixedFrame(center, scene.globe.ellipsoid, new Cesium.Matrix4())
        this._orbitIsLook = false
      }
    }
    const self = this

    function orbitTickFunction (e) {
      const timestamp = Cesium.getTimestamp()
      const deltaT = timestamp - self.#orbitLastTimestamp
      const rate = (self.#orbitCursorOpacity - 0.5) * 2.5 / 1000
      const distance = deltaT * rate

      const angle = self.#orbitCursorAngle + Cesium.Math.PI_OVER_TWO
      const x = Math.cos(angle) * distance
      const y = Math.sin(angle) * distance

      let oldTransform

      if (self.#navigationLocked) {
        return true
      }

      if (Cesium.defined(self.#orbitFrame)) {
        oldTransform = Cesium.Matrix4.clone(camera.transform, new Cesium.Matrix4())

        camera.lookAtTransform(self.#orbitFrame)
      }

      // do not look up/down or rotate in 2D mode
      if (scene.mode === Cesium.SceneMode.SCENE2D) {
        camera.move(new Cesium.Cartesian3(x, y, 0), Math.max(scene.canvas.clientWidth, scene.canvas.clientHeight) / 100 * camera.positionCartographic.height * distance)
      } else {
        if (self._orbitIsLook) {
          camera.look(Cesium.Cartesian3.UNIT_Z, -x)
          camera.look(camera.right, -y)
        } else {
          camera.rotateLeft(x)
          camera.rotateUp(y)
        }
      }

      if (Cesium.defined(self.#orbitFrame)) {
        camera.lookAtTransform(oldTransform)
      }

      // viewModel.terria.cesium.notifyRepaintRequired();

      self.#orbitLastTimestamp = timestamp
    }

    function updateAngleAndOpacity (vector, compassWidth) {
      const angle = Math.atan2(-vector.y, vector.x)
      self.#orbitCursorAngle = Cesium.Math.zeroToTwoPi(angle - Cesium.Math.PI_OVER_TWO)

      const distance = Cesium.Cartesian2.magnitude(vector)
      const maxDistance = compassWidth / 2.0
      const distanceFraction = Math.min(distance / maxDistance, 1.0)
      const easedOpacity = 0.5 * distanceFraction * distanceFraction + 0.5
      self.#orbitCursorOpacity = easedOpacity

      // viewModel.terria.cesium.notifyRepaintRequired();
    }

    function orbitMouseMoveFunction (e) {
      const compassRectangle = self.compass.querySelector('#cesium-compass').getBoundingClientRect()
      const center = new Cesium.Cartesian2((compassRectangle.right - compassRectangle.left) / 2.0, (compassRectangle.bottom - compassRectangle.top) / 2.0)
      let clientX = 0
      let clientY = 0
      if (e.type === 'touchmove') {
        clientX = e.touches[0].clientX
        clientY = e.touches[0].clientY
      } else {
        clientX = e.clientX
        clientY = e.clientY
      }
      const clickLocation = new Cesium.Cartesian2(clientX - compassRectangle.left, clientY - compassRectangle.top)
      const vector = Cesium.Cartesian2.subtract(clickLocation, center, new Cesium.Cartesian2())
      updateAngleAndOpacity(vector, compassRectangle.width)
    }

    function orbitMouseUpFunction (e) {
      // TODO: if mouse didn't move, reset view to looking down, north is up?

      self.#isOrbiting = false
      document.removeEventListener('mousemove', orbitMouseMoveFunction, false)
      document.removeEventListener('mouseup', orbitMouseUpFunction, false)
      document.removeEventListener('touchmove', orbitMouseMoveFunction, false)
      document.removeEventListener('touchend', orbitMouseUpFunction, false)

      if (Cesium.defined(orbitTickFunction)) {
        self.viewer.clock.onTick.removeEventListener(orbitTickFunction)
      }

      // viewModel.orbitMouseMoveFunction = undefined
      // viewModel.orbitMouseUpFunction = undefined
      // viewModel.orbitTickFunction = undefined
    }

    document.addEventListener('mousemove', orbitMouseMoveFunction, false)
    document.addEventListener('mouseup', orbitMouseUpFunction, false)
    document.addEventListener('touchmove', orbitMouseMoveFunction, false)
    document.addEventListener('touchend', orbitMouseUpFunction, false)
    self.viewer.clock.onTick.addEventListener(orbitTickFunction)

    updateAngleAndOpacity(cursorVector, event.currentTarget.getBoundingClientRect().width)
  }
}
export default CompassView
