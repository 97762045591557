/**
 * 比例尺控件
 */

class MeasuringScaleView {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer

    this.isShow = false
    this.compareView = document.createElement('div')
    this.compareView.id = 'compare_container'
    this.compareView.className = 'distance-legend'
    // this.compareView.style = 'position: absolute; bottom: 50px; right: 10px; width: 100px; height: 100px;';
    this.compareView.innerHTML = '<div class="distance-legend-label" id="distance-legend-label"></div><div class="distance-legend-scale-bar" id="distance-legend-scale-bar" style="width: 87px; left: 24px;"></div>'
    const container = document.getElementsByClassName('cesium-widget')
    if (container && container[0]) {
      container[0].appendChild(this.compareView)
    } else {
      this.viewer.container.appendChild(this.compareView)
    }
    this.distanceLabel = document.getElementById('distance-legend-label')
    this.distanceBar = document.getElementById('distance-legend-scale-bar')
    this.compareView.hidden = !this.isShow

    this.viewer.camera.moveEnd.addEventListener(() => {
      if (this.compareView !== undefined) {
        this.calculateMeasure()
      }
    })
  }

  get viewer () {
    return this._viewer
  }

  get show () {
    return this.isShow
  }

  set show (value) {
    this.isShow = value
  }

  calculateMeasure () {
    // 比例列表
    const distances = [1, 2, 3, 5, 10, 20, 30, 50, 100, 200, 300, 500, 1e3, 2e3, 3e3, 5e3, 1e4, 2e4, 3e4, 5e4, 1e5, 2e5, 3e5, 5e5, 1e6, 2e6, 3e6, 5e6, 1e7, 2e7, 3e7, 5e7]
    // 屏幕宽高
    const width = this.viewer.scene.canvas.clientWidth
    const height = this.viewer.scene.canvas.clientHeight
    // 屏幕上取紧挨着的两个点，创建射线
    const left = this.viewer.scene.camera.getPickRay(new Cesium.Cartesian2(width / 2 | 0, height - 1))
    const right = this.viewer.scene.camera.getPickRay(new Cesium.Cartesian2(1 + width / 2 | 0, height - 1))
    // 射线与地球的焦点，将屏幕坐标转换为三维坐标
    const leftPosition = this.viewer.scene.globe.pick(left, this.viewer.scene)
    const rightPosition = this.viewer.scene.globe.pick(right, this.viewer.scene)
    if (!Cesium.defined(leftPosition) || !Cesium.defined(rightPosition)) {
      this.compareView.hidden = true || !this.isShow
      return
    }
    // 三维坐标转换为地理坐标
    const leftCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(leftPosition)
    const rightCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(rightPosition)
    // 测距
    const geodesic = new Cesium.EllipsoidGeodesic()
    geodesic.setEndPoints(leftCartographic, rightCartographic)
    // 距离即是单位像素的距离
    const pixelDistance = geodesic.surfaceDistance
    // 选择合适的比例尺
    const maxBarWidth = 100
    for (let i = distances.length - 1; i >= 0; --i) {
      if (distances[i] / pixelDistance < maxBarWidth) {
        this.barDistance = distances[i]
        this.barLength = this.barDistance / pixelDistance
        break
      }
    }

    let distanceText = this.barDistance + ' m'
    if (this.barDistance >= 1e3) {
      distanceText = (this.barDistance / 1e3).toString() + ' km'
    }
    this.distanceLabel.innerHTML = distanceText
    this.compareView.hidden = !this.isShow
    this.distanceBar.style.width = this.barLength + 'px'
    this.distanceBar.style.left = (5 + (125 - this.barLength) / 2) + 'px'
  }
}

export default MeasuringScaleView
