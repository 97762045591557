import view3DGLSL from './view3DGLSL'

/**
 * 可视域分析。
 * @alias ViewShedStage
 * @class
 * @param {CesiumPlus} context 执行上下文。
 * @param {Cesium.Viewer} viewer Cesium三维视窗。
 */
class ViewShed3D {
  constructor (context, viewer) {
    this._context = context
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._viewer = viewer
    // 保存原始shadowmap
    this._originShadowMap = this.viewer.scene.shadowMap
  }

  get viewer () {
    return this._viewer
  }

  get viewPosition () {
    return this._viewPosition
  }

  set viewPosition (v) {
    if (!(v instanceof Cesium.Cartesian3)) {
      throw new Error('viewPosition 不是一个标准的Cesium Cartesian3')
    }
    this._viewPosition = v
  }

  get viewPositionEnd () {
    return this._viewPositionEnd
  }

  /**
   * 修改目标点后，需要重新计算距离，方位角，俯仰角
   * @param v
   */
  set viewPositionEnd (v) {
    if (!(v instanceof Cesium.Cartesian3)) {
      throw new Error('viewPositionEnd 不是一个标准的Cesium Cartesian3')
    }
    this._viewDistance = Cesium.Cartesian3.distance(this.viewPosition, v)
    this._viewHeading = this.getHeadingOrPitch(this.viewPosition, v, false)
    this._viewPitch = this.getHeadingOrPitch(this.viewPosition, v, true)
    this._viewPositionEnd = v
  }

  get viewDistance () {
    return this._viewDistance
  }

  set viewDistance (v) {
    this._viewDistance = v
  }

  get viewheight () {
    return this._viewheight
  }

  set viewheight (v) {
    const cartographic = Cesium.Cartographic.fromCartesian(this._viewPosition)
    const orginHeight = cartographic.height - this._viewheight
    const newHeight = orginHeight + v
    this._viewPosition = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, newHeight)
    this._viewheight = v
  }

  get viewHeading () {
    return this._viewHeading
  }

  set viewHeading (v) {
    this._viewHeading = v
  }

  get viewPitch () {
    return this._viewPitch
  }

  set viewPitch (v) {
    this._viewPitch = v
  }

  get horizontalViewAngle () {
    return this._horizontalViewAngle
  }

  set horizontalViewAngle (v) {
    this._horizontalViewAngle = v
  }

  get verticalViewAngle () {
    return this._verticalViewAngle
  }

  set verticalViewAngle (v) {
    this._verticalViewAngle = v
  }

  get visibleAreaColor () {
    return this._visibleAreaColor
  }

  set visibleAreaColor (v) {
    if (!(v instanceof Cesium.Color)) {
      throw new Error('Color 不是一个标准的Cesium Color')
    }
    this._visibleAreaColor = v
  }

  get invisibleAreaColor () {
    return this._invisibleAreaColor
  }

  set invisibleAreaColor (v) {
    if (!(v instanceof Cesium.Color)) {
      throw new Error('Color 不是一个标准的Cesium Color')
    }
    this._invisibleAreaColor = v
  }

  get volumeColor () {
    return this._volumeColor
  }

  get outlineColor () {
    return this._outlineColor
  }

  set outlineColor (v) {
    if (!(v instanceof Cesium.Color)) {
      throw new Error('Color 不是一个标准的Cesium Color')
    }
    this._outlineColor = v
  }

  get enabled () {
    return this._enabled
  }

  set enabled (v) {
    this._enabled = v
  }

  get softShadows () {
    return this._softShadows
  }

  set softShadows (v) {
    this._softShadows = v
  }

  get size () {
    return this._size
  }

  set size (v) {
    this._size = v
  }

  /**
   * 开始进行可视域分析。
   * @param {Object} options 选项。
   * @param {Cesium.Cartesian3} options.viewPosition 观测点位置。
   * @param {Cesium.Cartesian3} options.viewPositionEnd 最远观测点位置（如果设置了观测距离，这个属性可以不设置）。
   * @param {Number} options.viewDistance 观测距离（单位`米`，默认值100）。
   * @param {Number} options.viewheight 观察高度（单位`米`，默认值1.8）。
   * @param {Number} options.viewHeading 航向角（单位`度`，默认值0）。
   * @param {Number} options.viewPitch 俯仰角（单位`度`，默认值0）。
   * @param {Number} options.horizontalViewAngle 可视域水*夹角（单位`度`，默认值90）。
   * @param {Number} options.verticalViewAngle 可视域垂直夹角（单位`度`，默认值60）。
   * @param {Cesium.Color} options.visibleAreaColor 可视区域颜色（默认值`绿色`）。
   * @param {Cesium.Color} options.invisibleAreaColor 不可视区域颜色（默认值`红色`）。
   * @param {Boolean} options.enabled 阴影贴图是否可用。
   * @param {Boolean} options.softShadows 是否启用柔和阴影。
   * @param {Boolean} options.size 每个阴影贴图的大小。
   * @param {Number} options.alpha 贴图透明度
   */
  analyze (options) {
    this._viewPosition = options.viewPosition
    this._viewPositionEnd = options.viewPositionEnd
    this._viewheight = (typeof options.viewheight === 'number') ? options.viewheight : 1.8
    if (this._viewheight) { // 设置了高度
      const cartographic = Cesium.Cartographic.fromCartesian(this._viewPosition)
      const newHeight = cartographic.height + this._viewheight
      this._viewPosition = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, newHeight)
    }
    this._viewDistance = this.viewPositionEnd ? Cesium.Cartesian3.distance(this.viewPosition, this.viewPositionEnd) : (options.viewDistance || 100.0)
    this._viewHeading = this.viewPositionEnd ? this.getHeadingOrPitch(this.viewPosition, this.viewPositionEnd, false) : (options.viewHeading || 0.0)
    this._viewPitch = this.viewPositionEnd ? this.getHeadingOrPitch(this.viewPosition, this.viewPositionEnd, true) : (options.viewPitch || 0.0)
    this._horizontalViewAngle = options.horizontalViewAngle || 90.0
    this._verticalViewAngle = options.verticalViewAngle || 60.0
    this._visibleAreaColor = options.visibleAreaColor || Cesium.Color.GREEN.withAlpha(1)
    this._invisibleAreaColor = options.invisibleAreaColor || Cesium.Color.RED.withAlpha(1)
    this._enabled = (typeof options.enabled === 'boolean') ? options.enabled : true
    this._softShadows = (typeof options.softShadows === 'boolean') ? options.softShadows : true
    this._size = options.size || 2048
    this._volumeColor = options.volumeColor || Cesium.Color.WHITE.withAlpha(0.2)
    this._outlineColor = options.outlineColor || Cesium.Color.WHITE.withAlpha(0.8)
    this._alpha = Cesium.defaultValue(options.alpha, 0.5)

    this.update()
  }

  onChangeOption (data) {
    this.remove()
    this._horizontalViewAngle = data.horizontalViewAngle
    this._verticalViewAngle = data.verticalViewAngle
    this._viewDistance = data.viewDistance
    this._viewHeading = data.viewHeading
    this._viewPitch = data.viewPitch
    this._alpha = data.alpha
    this.update()
  }

  /**
   * 添加元素
   */
  add () {
    this.createLightCamera()
    this.createShadowMap()
    if (!this.postStage) {
      this.createPostStage()
    }
    this.drawSketch()
  }

  /**
   * 更新渲染
   */
  update () {
    this.clear()
    this.add()
  }

  /**
   * 清除元素
   */
  clear () {
    if (this.vision) {
      this.viewer.entities.remove(this.vision)
      this.vision = null
    }
    this.viewer.scene.shadowMap = this._originShadowMap
  }

  remove () {
    this.clear()
    if (this.postStage) {
      this.viewer.scene.postProcessStages.remove(this.postStage)
      this.postStage = null
    }
  }

  /**
   * 创建相机，用于提供视锥体
   */
  createLightCamera () {
    // 相机对象
    if (!this.lightCamera) {
      this.lightCamera = new Cesium.Camera(this.viewer.scene)
    }

    this.lightCamera.position = this.viewPosition
    // if (this.viewPositionEnd) {
    //     let direction = Cesium.Cartesian3.normalize(Cesium.Cartesian3.subtract(this.viewPositionEnd, this.viewPosition, new Cesium.Cartesian3()), new Cesium.Cartesian3());
    //     this.lightCamera.direction = direction; // direction是相机面向的方向
    // }
    // 设置视锥体的最远最近平面
    this.lightCamera.frustum.near = this.viewDistance * 0.001
    this.lightCamera.frustum.far = this.viewDistance
    // 视锥体的水平角度和垂直角度
    const hr = Cesium.Math.toRadians(this.horizontalViewAngle)
    const vr = Cesium.Math.toRadians(this.verticalViewAngle)
    const aspectRatio =
      (this.viewDistance * Math.tan(hr / 2) * 2) /
      (this.viewDistance * Math.tan(vr / 2) * 2)
    this.lightCamera.frustum.aspectRatio = aspectRatio
    if (hr > vr) {
      this.lightCamera.frustum.fov = hr
    } else {
      this.lightCamera.frustum.fov = vr
    }
    // 设置相机位置，位置处于观察点，方位角和俯仰角由目标点确定
    this.lightCamera.setView({
      destination: this.viewPosition,
      orientation: {
        heading: Cesium.Math.toRadians(this.viewHeading || 0),
        pitch: Cesium.Math.toRadians(this.viewPitch || 0),
        roll: 0
      }
    })
  }

  /**
   * 创建场景阴影图
   */
  createShadowMap () {
    if (!this.shadowMap) {
      this.shadowMap = new Cesium.ShadowMap({
        context: this.viewer.scene.context,
        lightCamera: this.lightCamera,
        enabled: this.enabled,
        isPointLight: true,
        pointLightRadius: this.viewDistance,
        cascadesEnabled: false,
        size: this.size,
        softShadows: this.softShadows,
        normalOffset: false,
        fromLightSource: false
      })
      this.viewer.scene.shadowMap = this.shadowMap
    } else {
      this.shadowMap._pointLightRadius = this.viewDistance
      this.viewer.scene.shadowMap = this.shadowMap
    }
  }

  /**
   * 场景后期处理
   */
  createPostStage () {
    // view3DGLSL是片元着色器代码
    const fs = view3DGLSL
    const postStage = new Cesium.PostProcessStage({
      fragmentShader: fs,
      uniforms: {
        shadowMap_textureCube: () => {
          this.shadowMap.update(Reflect.get(this.viewer.scene, '_frameState'))
          return Reflect.get(this.shadowMap, '_shadowMapTexture')
        },
        shadowMap_matrix: () => {
          this.shadowMap.update(Reflect.get(this.viewer.scene, '_frameState'))
          return Reflect.get(this.shadowMap, '_shadowMapMatrix')
        },
        shadowMap_lightPositionEC: () => {
          this.shadowMap.update(Reflect.get(this.viewer.scene, '_frameState'))
          return Reflect.get(this.shadowMap, '_lightPositionEC')
        },
        shadowMap_normalOffsetScaleDistanceMaxDistanceAndDarkness: () => {
          this.shadowMap.update(Reflect.get(this.viewer.scene, '_frameState'))
          const bias = this.shadowMap._pointBias
          return Cesium.Cartesian4.fromElements(
            bias.normalOffsetScale,
            this.shadowMap._distance,
            this.shadowMap.maximumDistance,
            0.0,
            new Cesium.Cartesian4()
          )
        },
        shadowMap_texelSizeDepthBiasAndNormalShadingSmooth: () => {
          this.shadowMap.update(Reflect.get(this.viewer.scene, '_frameState'))
          const bias = this.shadowMap._pointBias
          const scratchTexelStepSize = new Cesium.Cartesian2()
          const texelStepSize = scratchTexelStepSize
          texelStepSize.x = 1.0 / this.shadowMap._textureSize.x
          texelStepSize.y = 1.0 / this.shadowMap._textureSize.y

          return Cesium.Cartesian4.fromElements(
            texelStepSize.x,
            texelStepSize.y,
            bias.depthBias,
            bias.normalShadingSmooth,
            new Cesium.Cartesian4()
          )
        },
        camera_projection_matrix: () => {
          return this.lightCamera.frustum.projectionMatrix
        },
        camera_view_matrix: () => {
          return this.lightCamera.viewMatrix
        },
        helsing_viewDistance: () => {
          return this.viewDistance
        },
        helsing_visibleAreaColor: this.visibleAreaColor,
        helsing_invisibleAreaColor: this.invisibleAreaColor,
        helsing_visibleAlpha: this._alpha
      }
    })
    this.postStage = this.viewer.scene.postProcessStages.add(postStage)
  }

  /**
   * 创建视网对象
   */
  drawSketch () {
    this.vision = this.viewer.entities.add({
      name: 'vision',
      position: this.viewPosition,
      orientation: Cesium.Transforms.headingPitchRollQuaternion(
        this.viewPosition,
        Cesium.HeadingPitchRoll.fromDegrees(this.viewHeading - 90, this.viewPitch, 0.0)
      ),
      ellipsoid: {
        radii: new Cesium.Cartesian3(this.viewDistance,
          this.viewDistance,
          this.viewDistance),
        innerRadii: new Cesium.Cartesian3(0.01, 0.01, 0.01),
        minimumClock: Cesium.Math.toRadians(-this.horizontalViewAngle / 2),
        maximumClock: Cesium.Math.toRadians(this.horizontalViewAngle / 2),
        minimumCone: Cesium.Math.toRadians(this.verticalViewAngle + 7.75),
        maximumCone: Cesium.Math.toRadians(180 - this.verticalViewAngle - 7.75),
        // minimumCone: 0,
        // maximumCone: Cesium.Math.toRadians(this.verticalViewAngle),
        material: this.volumeColor,
        outline: true,
        outlineColor: this.outlineColor,
        fill: true
      }
    })
  }

  /**
   * 计算两点间方位角或者俯仰角，mode为false时计算方位角，mode为true时计算俯仰角
   * @param fromPosition
   * @param toPosition
   * @param mode
   * @returns {Number|number|*}
   */
  getHeadingOrPitch (fromPosition, toPosition, mode) {
    const finalPosition = new Cesium.Cartesian3()
    const matrix4 = Cesium.Transforms.eastNorthUpToFixedFrame(fromPosition)
    Cesium.Matrix4.inverse(matrix4, matrix4)
    Cesium.Matrix4.multiplyByPoint(matrix4, toPosition, finalPosition)
    Cesium.Cartesian3.normalize(finalPosition, finalPosition)
    let value = Cesium.Math.toDegrees(Math.atan2(finalPosition.x, finalPosition.y))
    if (mode) {
      value = Cesium.Math.toDegrees(Math.asin(finalPosition.z))
    }
    return value
  }

  destroy () {

  }
}

export default ViewShed3D
