// 图层id定义

const LayerDefine = {
  // 1~100 影像图层范围
  DefaultLocalImager: 99, // 本地默认底图
  GoogleImgLayer: 1, // 谷歌影像
  GoogleVecLayer: 2, // 谷歌矢量
  AmapImgLayer: 3, // 高德影像
  AmapVecLayer: 4, // 高德矢量
  TencentVecLayer: 5, // 腾讯矢量
  TianDiMapImgLayer: 6, // 天地图影像
  TianDiMapVecLayer: 7, // 天地图矢量
  BaiduVecLayer: 8, // 百度矢量
  BingMapImgVecLayer: 9, // Bing影像
  ArcGisMapImgVecLayer: 10, // ArcGis影像
  BaseImageryLayerEnd: 100, // 影像底图结束

  // 200~299 标注影像图层范围
  TianDiMapLabelLayer: 200, // 天地图标注层
  AmapLabelLayer: 201, // 高德地图标注
  GoogleLabelLayer: 202, // 谷歌地图标注
  // BingLabelLayer:203,         //必应地图标注
  // BaiduLabelLayer:204,        //百度地图标注
  TencentLabelLayer: 205, // 腾讯地图标注
  LabelLayerEnd: 299, // 标注层结束

  CustomLayerBegin: 1000, // 自定义影像层开始id
  CustomLayerEnd: 1999, // 自定义影像层结束id

  // 特殊图层
  CoordinatesLayer: 4001 // 坐标图层
}

export default Object.freeze(LayerDefine)
