/**
 * 飞行路线类
 * isFlyLoop 是否循环飞行
 * isStopVisible 是否显示站点
 * isLineVisible 是否显示路线
 * name 路线名称
 */
import { cartesianToLonLat } from '../Common/cesiumUtil'

/**
 * 站点默认风格
 * @type {{label: {fillColor: Color, outlineWidth: number, verticalOrigin: VerticalOrigin.TOP, outlineColor: Color, horizontalOrigin: HorizontalOrigin.LEFT, style: LabelStyle.FILL_AND_OUTLINE, text: string, pixelOffset: module:cesium.Cartesian2 | Cartesian2 | Cartesian2 | N, font: string}, billboard: {image: string, verticalOrigin: VerticalOrigin.CENTER, horizontalOrigin: HorizontalOrigin.CENTER}}}
 */
const defaultStopStyle = {
  billboard: {
    image: '/static/images/markerPoint.png',
    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 设置图标水平方向居中
    verticalOrigin: Cesium.VerticalOrigin.CENTER // 设置图标图片底部接触场景
  },
  label: {
    text: '',
    outlineColor: Cesium.Color.WHITE,
    fillColor: Cesium.Color.BLACK,
    outlineWidth: 3,
    font: '16px Microsoft YaHei',
    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
    verticalOrigin: Cesium.VerticalOrigin.TOP,
    pixelOffset: new Cesium.Cartesian2(20, -20)
  }
}
/**
 * 飞行路线默认风格
 * @type {{label: {fillColor: Color, outlineWidth: number, verticalOrigin: VerticalOrigin.TOP, outlineColor: Color, horizontalOrigin: HorizontalOrigin.LEFT, style: LabelStyle.FILL_AND_OUTLINE, text: string, pixelOffset: module:cesium.Cartesian2 | Cartesian2 | Cartesian2 | N, font: string}, polyline: {material: Color, width: number}}}
 */
const defaultRouteStyle = {
  polyline: {
    material: Cesium.Color.fromCssColorString('rgb(128,234,7)'),
    width: 3
  },
  label: {
    text: '',
    outlineColor: Cesium.Color.WHITE,
    fillColor: Cesium.Color.BLACK,
    outlineWidth: 3,
    font: '16px Microsoft YaHei',
    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
    verticalOrigin: Cesium.VerticalOrigin.TOP,
    pixelOffset: new Cesium.Cartesian2(0, -50)
  }
}
class FlyRoute {
  constructor (viewer, options = {}) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._viewer = viewer
    this._isStopVisible = options.isStopVisible || false
    this._isFlyLoop = options.isFlyLoop || false
    this._isLineVisible = options.isLineVisible || false
    this._name = options.name || ''
    this._stopGroup = []
    this._pitch = options.pitch || 0
    this._roll = options.roll || 0
    this._stopEntities = []
    this._routeEntity = null
  }

  get viewer () {
    return this._viewer
  }

  get isStopVisible () {
    return this._isStopVisible
  }

  set isStopVisible (v) {
    this._stopEntities.forEach(item => {
      item.show = v
    })
    this._isStopVisible = v
  }

  get isFlyLoop () {
    return this._isFlyLoop
  }

  set isFlyLoop (v) {
    this._isFlyLoop = v
  }

  get isLineVisible () {
    return this._isLineVisible
  }

  set isLineVisible (v) {
    if (this._routeEntity) {
      this._routeEntity.show = v
    }
    this._isLineVisible = v
  }

  get name () {
    return this._name
  }

  set name (v) {
    this._name = v
  }

  get pitch () {
    return this._pitch
  }

  set pitch (v) {
    if (typeof v !== 'number') {
      throw new Error('pitch不是数值类型')
    }
    this._pitch = v
  }

  get roll () {
    return this._roll
  }

  set roll (v) {
    if (typeof v !== 'number') {
      throw new Error('pitch不是数值类型')
    }
    this._roll = v
  }

  get stopGroup () {
    return this._stopGroup
  }

  /**
   * 添加站点
   * @param stop
   */
  addStop (stop) {
    this.stopGroup.push(stop)
    const linePoints = []
    // 获取笛卡尔坐标数组
    this.stopGroup.forEach(item => {
      linePoints.push(item.point)
    })
    // 站点名称显示
    if (stop.name) {
      defaultStopStyle.label.text = stop.name
    }
    const entityOptions = {
      position: stop.point,
      show: this.isStopVisible,
      ...defaultStopStyle
    }
    // 添加站点实体
    const entity = this.viewer.entities.add(new Cesium.Entity(entityOptions))
    this._stopEntities.push(entity)
    // 添加路线实体
    if (this._stopEntities.length >= 2) {
      if (!this._routeEntity) {
        if (this.name) {
          defaultRouteStyle.label.text = this.name
        }
        defaultRouteStyle.polyline.positions = linePoints
        const entityOptions = {
          position: linePoints[0],
          show: this.isLineVisible,
          ...defaultRouteStyle
        }
        this._routeEntity = this.viewer.entities.add(new Cesium.Entity(entityOptions))
      } else {
        this._routeEntity.polyline.positions = linePoints
      }
    }
  }

  /**
   * 获取站点
   * @param index
   * @returns {*}
   */
  getStop (index) {
    return this.stopGroup[index]
  }

  /**
   * 移除站点
   * @param stop
   */
  removeStop (stop) {
    // 移除站点
    const index = this.stopGroup.findIndex(item => {
      return item === stop
    })
    this.stopGroup.splice(index, 1)
    // 删除站点实体
    this.viewer.entities.remove(this._stopEntities[index])
    this._stopEntities.slice(index, 1)
    // 删除路线节点或者路线实体(节点不足2个时)
    if (this.stopGroup.length >= 2) {
      const linePoints = []
      this.stopGroup.forEach(item => {
        linePoints.push(item.point)
      })
      this._routeEntity.polyline.positions = linePoints
    } else {
      this.viewer.entities.remove(this._routeEntity)
      this._routeEntity = null
    }
  }

  /**
   * 根据后一个点的位置计算方向
   */
  calculateDirection () {
    const webMercatorProjection = new Cesium.WebMercatorProjection(this.viewer.scene.globe.ellipsoid)
    for (let i = 0; i < this.stopGroup.length - 1; i++) {
      const startPoint = webMercatorProjection.project(Cesium.Cartographic.fromCartesian(this.stopGroup[i].point))
      startPoint.z = 0
      const endPoint = webMercatorProjection.project(Cesium.Cartographic.fromCartesian(this.stopGroup[i + 1].point))
      endPoint.z = 0
      let lineDir = Cesium.Cartesian3.subtract(endPoint, startPoint, new Cesium.Cartesian3())
      lineDir = Cesium.Cartesian3.normalize(lineDir, new Cesium.Cartesian3())
      const radians = Cesium.Cartesian3.angleBetween(new Cesium.Cartesian3(0, 1, 0), lineDir)
      let azimuthDegree = Cesium.Math.toDegrees(radians)
      if (lineDir.x < 0) { azimuthDegree = 360 - azimuthDegree }
      this.stopGroup[i].heading = azimuthDegree
    }
    if (this.stopGroup.length >= 2) {
      this.stopGroup[this.stopGroup.length - 1].heading = this.stopGroup[this.stopGroup.length - 2].heading
    }
  }

  /**
   * 根据两点距离与速度计算时间
   */
  calculateDuration () {
    for (let i = 0; i < this.stopGroup.length - 1; i++) {
      const startCartesian = this.stopGroup[i].point
      const endCartesian = this.stopGroup[i + 1].point
      const distance = Cesium.Cartesian3.distance(startCartesian, endCartesian)
      let duration = distance / this.stopGroup[i].speed
      if (duration === 0) {
        duration = 0.01
      }
      this.stopGroup[i].duration = duration
    }
    if (this.stopGroup.length >= 2) {
      this.stopGroup[this.stopGroup.length - 1].duration = this.stopGroup[this.stopGroup.length - 2].duration
    }
  }

  /**
   * 根据给定的节点与时间获取摄像机位置与方向
   * @param {} index 路径节点索引
   * @param {*} duration 时间
   * @returns {{position: (Array), orientation: (Array)}}
   */
  getCameraView (index, duration) {
    if (index >= this.stopGroup.length) {
      return
    }
    const stop = this.stopGroup[index]
    const degree = cartesianToLonLat(stop.point)
    return {
      position: [degree.longitude, degree.latitude, degree.height],
      orientation: [Cesium.Math.toDegrees(stop.heading), Cesium.Math.toDegrees(stop.pitch), Cesium.Math.toDegrees(stop.roll)]
    }
  }
}
export default FlyRoute
