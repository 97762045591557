// 地图放大镜
class CanvasZoom {
  #context = undefined
  #viewer = undefined
  #canvas = undefined
  #node = undefined
  // 放大倍数
  #factor = 1
  // 窗口是否跟随鼠标
  #isFollowCursor = true

  constructor (context, viewer) {
    this.#context = context
    this.#viewer = viewer
  }

  get enabled () {
    return !!this.#canvas
  }

  set enabled (value) {
    if (value) {
      this.createCanvas()
    } else {
      this.removeCanvas()
    }
  }

  createCanvas (width = 100, height = 100, factor = 2) {
    if (this.#canvas) {
      return
    }
    this.#factor = factor
    const node = document.createElement('div')
    node.className = 'canvas-zoom'
    node.style = 'position: absolute;'

    this.#canvas = document.createElement('canvas')
    this.#canvas.id = 'drawImg'
    // this.#canvas.style.display = 'none'
    this.#canvas.width = width
    this.#canvas.height = height
    node.appendChild(this.#canvas)
    const border = document.createElement('div')
    border.className = 'inner-border'
    border.innerHTML = '<div></div><div></div><div></div><div></div>'
    node.append(border)
    document.body.appendChild(node)
    this.#node = node
  }

  onCesiumEvent (eventType, msg) {
    if (eventType === Cesium.ScreenSpaceEventType.MOUSE_MOVE) {
      this.updateCanvas(msg.endPosition)
    }
  }

  updateCanvas (pos) {
    if (!this.#canvas) {
      return
    }
    const originWidth = this.#canvas.width / this.#factor
    const originHeight = this.#canvas.height / this.#factor
    const cxt = this.#canvas.getContext('2d')
    const _canvas = this.#viewer.canvas
    cxt.drawImage(_canvas, pos.x - originWidth / 2, pos.y - originHeight / 2, originWidth, originHeight, 0, 0, this.#canvas.width, this.#canvas.height)
    if (this.#isFollowCursor) {
      this.#node.style.left = (pos.x + 10) + 'px'
      this.#node.style.top = (pos.y + 60) + 'px'
    }
  }

  removeCanvas () {
    if (this.#node) {
      document.body.removeChild(this.#node)
      this.#node = null
      this.#canvas = null
      this.#factor = 1
    }
  }

  destroy () {
    this.removeCanvas()
  }
}

export default CanvasZoom
