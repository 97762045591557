import md5 from 'md5'
// 3dtile管理类
class Cesium3DTilesetManager {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer

    // 3dtile 自增id
    this._incremnet3dTilesetId = 0
    // 3dtile列表
    this._tilesetMap = {}
    // 原始坐标列表
    this._originPositionMap = {}
  }

  autoIncrementLayerId () {
    this._incremnet3dTilesetId++
    return this._incremnet3dTilesetId
  }

  /**
   * 添加3dtileset
   * @param {String} url 地址
   * @param {Object} options 参数
   * @param {Number} options.height 可选，高度调整
   * @param {Boolean} options.isFlyTo 是否飞行模型,默认false
   * @param {Number} options.maximumScreenSpaceError 显示精度,默认512，值越小，模型越精致，越消耗性能
   * @param {Function} options.loaded 加载后回调
   */
  add3dTileset (url, options) {
    Cesium.Resource.fetch(url).then(data => {
      if (this._context.isVerification3dTitle) {
        const jsonData = JSON.parse(data)
        if (!jsonData.shineKey || !jsonData.uuid) {
          return
        }
        const shineKey = jsonData.shineKey
        const key = 'd8f7s939w8hf98yafh7u'
        const curKey = md5(jsonData.uuid + key)
        if (curKey !== shineKey) {
          return
        }
      }
      this.load3dTileset(url, options)
    })
  }

  /**
   * 加载3dtileset
   * @param {String} url 地址
   * @param {Object} options 参数
   * @param {Number} options.height 高度调整
   * @param {Number} options.isFlyTo 是否飞行模型
   * @param {Number} options.maximumScreenSpaceError 显示精度
   * @param {Function} options.loaded 加载后回调
   */
  load3dTileset (url, options) {
    const self = this
    options.url = url
    options.maximumScreenSpaceError = Cesium.defaultValue(options.maximumScreenSpaceError, 16)
    options.maximumMemoryUsage = Cesium.defaultValue(options.maximumMemoryUsage, 512)
    // options.skipLevelOfDetail = Cesium.defaultValue(options.skipLevelOfDetail, true)
    // options.skipLevels = Cesium.defaultValue(options.skipLevels, 17)
    const tilesetModel = new Cesium.Cesium3DTileset(options)
    tilesetModel.readyPromise.then(function (tileset) {
      self._viewer.scene.primitives.add(tileset)
      const setId = self.autoIncrementLayerId()
      tileset.setId = setId
      const originPosition = tileset.boundingSphere.center.clone()
      self._originPositionMap[setId] = originPosition
      self._tilesetMap[setId] = tileset
      if (options.height !== undefined) {
        self.changeHeight(setId, options.height)
      }
      if (options.isFlyTo) {
        self.flyToTileset(setId)
      }
      if (options.loaded) {
        options.loaded(setId, tileset)
      }
    })
  }

  /**
   * 通过id获取3dtileset模型
   * @param {Number} setId 模型id
   * @returns {Cesium.Cesium3DTileset} 3dtileset模型
   */
  get3dTileset (setId) {
    return this._tilesetMap[setId]
  }

  /**
   * 调整模型高度
   * @param {Number} setId 模型id
   * @param {Number} height 模型高度
   * @returns
   */
  changeHeight (setId, height) {
    height = Number(height)
    if (isNaN(height)) {
      return
    }
    const _tileset = this._tilesetMap[setId]
    if (!_tileset) {
      return
    }
    const center = this.getOriginPosition(setId)
    if (!center) {
      return
    }
    const cartographic = Cesium.Cartographic.fromCartesian(center)
    const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height)
    const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, height)
    const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())
    _tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
  }

  /**
   * 获取3dtile移动前原始坐标位置
   * @param {Number} setId 模型id
   * @returns
   */
  getOriginPosition (setId) {
    // let _tileset = this._tilesetMap[setId]
    // if(!_tileset){
    //   return
    // }
    // let boundingVolume = _tileset._root._header.boundingVolume
    // let center = new Cesium.Cartesian3(boundingVolume.sphere[0], boundingVolume.sphere[1], boundingVolume.sphere[2])
    // return center
    return this._originPositionMap[setId]
  }

  /**
   * 飞向模型
   * @param {Number} setId 模型id
   */
  flyToTileset (setId) {
    const _tileset = this._tilesetMap[setId]
    if (!_tileset) {
      return
    }
    this._viewer.flyTo(
      _tileset,
      new Cesium.HeadingPitchRange(0.0, -0.5, _tileset.boundingSphere.radius * 2.0)
    )
  }

  /**
   * 通过id移除模型
   * @param {Number} setId 模型id
   * @returns
   */
  remove3dTileset (setId) {
    const _tileset = this._tilesetMap[setId]
    if (!_tileset) {
      return
    }
    self._viewer.scene.primitives.remove(_tileset)
    this._tilesetMap[setId] = null
  }

  /**
   * 移除所有3dtile
   */
  removeAll3dTileset () {
    const ids = Object.keys(this._tilesetMap)
    for (let i = 0; i < ids.length; i++) {
      const tileset = this._tilesetMap[ids[i]]
      this._viewer.scene.primitives.remove(tileset)
    }
    this._tilesetMap = {}
    this._incremnet3dTilesetId = 0
  }

  destroy () {
    this.removeAll3dTileset()
    this._originPositionMap = null
  }
}

export default Cesium3DTilesetManager
