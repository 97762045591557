import * as turf from '@turf/turf'
import HeatMap from './HeatMap'

class HeatMapManager {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer

    this._heatMaps = {}
  }

  /**
   * @param {String} id id
   * @param {Object} options 配置参数
   * @param {Object} options.bounds 热力图显示区域
   * @param {Number} options.valueMin 数据最小值
   * @param {Number} options.valueMax 数据最大值
   * @param {Number} options.blur 模糊度
   * @param {Number} options.radius 半径
   * @param {Number} options.minOpacity 最小透明度
   * @param {Number} options.maxOpacity 最大透明度
   * @param {String} options.xField 数据经度字段名称
   * @param {String} options.yField 数据纬度字段名称
   * @param {String} options.valueField 数据热力值字段名称
   * @param {Number} options.layerType 图层显示位置，0模型与影像都显示，1只影像层显示，2只模型上显示，
   */
  createHeatMap (id, options) {
    if (this.getHeatMapById(id)) {
      console.error('createHeatMap error, same id', id)
      return
    }
    const heatMap = new HeatMap(this._viewer, options)
    if (options.data) {
      heatMap.setData(options.data)
    }
    this._heatMaps[id] = heatMap
  }

  // 从标注数据加载热力图
  createHeatMapFromMarkers (id, markers, options) {
    const _options = Cesium.defaultValue(options, {})
    // const radius = Cesium.defaultValue(_options.radius, undefined)
    const positions = []
    const heatData = []
    for (let i = 0; i < markers.length; i++) {
      const longitude = markers[i][0]
      const latitude = markers[i][1]
      positions.push([longitude, latitude])
      heatData.push({
        x: longitude,
        y: latitude,
        value: 50
      })
    }
    const multPoint = turf.multiPoint(positions)
    const bbox = turf.bbox(multPoint)
    const squared = turf.square(bbox)
    const bounds = { west: squared[0], east: squared[2], south: squared[1], north: squared[3] }
    if (!_options.bounds) {
      _options.bounds = bounds
    }
    _options.data = heatData
    this.createHeatMap(id, _options)
  }

  /**
   * 通过id获取热力图
   * @param {Number} layerId 影像id
   * @returns {Cesium.ImageryLayer} 影像
   */
  getHeatMapById (id) {
    return this._heatMaps[id]
  }

  /**
   * 通过id显示/隐藏热力图
   * @param {Number} layerId 影像id
   * @returns {Cesium.ImageryLayer} 影像
   */
  showHeatMapById (id, isShow) {
    const heatmap = this.getHeatMapById(id)
    if (heatmap) {
      heatmap.show(isShow)
    }
  }

  /**
   * 通过id移除热力图
   * @param {Number} layerId 影像id
   */
  removeHeatMapById (id) {
    if (!this._heatMaps[id]) {
      return
    }
    this._heatMaps[id].destroy()
    this._heatMaps[id] = null
  }

  /**
   * 通过id更新热力图数据
   * @param {Number} layerId 影像id
   */
  updateHeatMapDataById (id, data) {
    if (!this._heatMaps[id]) {
      return
    }
    this._heatMaps[id].setData(data)
  }

  /**
   * 通过id更新热力图属性
   * @param {Number} layerId 影像id
   */
  updateHeatMapPorpertyById (id, config) {
    if (!this._heatMaps[id]) {
      return
    }
    this._heatMaps[id].setData(config)
  }

  /**
   * 移除所有热力图
   */
  removeAllHeatMap () {
    const keys = Object.keys(this._heatMaps)
    for (let i = 0; i < keys.length; i++) {
      this.removeHeatMapById(keys[i])
    }
    this._heatMaps = {}
  }

  destroy () {
    this.removeAllHeatMap()
  }
}

export default HeatMapManager
