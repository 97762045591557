/**
 * 多边形标注编辑类
 */
import BaseEditor from './BaseEditor'
import DrawMode from '../../DrawHandler/DrawMode'
import CustomEvent from '../../Common/CustomEvent'
import * as turf from '@turf/turf'

class PolygonEditor extends BaseEditor {
  // 选中节点
  #selectedNode = undefined
  // 中心节点
  #centerGisPostion = undefined
  // 坐标点
  #gisPosition = []
  // 可编辑节点
  #nodeEntities = []
  // 中心节点
  #centerNodeEntity = undefined
  // 可添加节点
  #middleNodeEntities = []

  constructor (context, gisPosition) {
    super(context)
    this._type = DrawMode.Polygon
    this.#gisPosition = JSON.parse(JSON.stringify(gisPosition))

    function _updatePolylinePositions () {
      const _positions = []
      this.#gisPosition.forEach(item => {
        const pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ?? 0)
        _positions.push(pos)
      })
      return [].concat(_positions, _positions[0])
    }
    function _updatePolygonPositions () {
      const _positions = []
      this.#gisPosition.forEach(item => {
        const pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ?? 0)
        _positions.push(pos)
      })
      return new Cesium.PolygonHierarchy(_positions)
    }
    this._viewEntity = this._context.viewer.entities.add({
      polygon: {
        hierarchy: new Cesium.CallbackProperty(_updatePolygonPositions.bind(this), false),
        material: Cesium.Color.fromCssColorString('#0FF2F580'),
        outline: false
      },
      polyline: {
        positions: new Cesium.CallbackProperty(_updatePolylinePositions.bind(this), false),
        clampToGround: true,
        material: Cesium.Color.fromCssColorString('#1890FF'),
        width: 6,
        zIndex: -1
      }
    })
    // 中心点
    const geoJson = turf.lineString(this.#gisPosition)
    this.#centerGisPostion = turf.centerOfMass(geoJson).geometry.coordinates
    let pos = Cesium.Cartesian3.fromDegrees(this.#centerGisPostion[0], this.#centerGisPostion[1], this.#centerGisPostion[2] ?? 0)
    pos = this._viewer.scene.clampToHeight(pos) ?? pos
    this.#centerNodeEntity = this._context.viewer.entities.add({
      position: pos,
      point: {
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        pixelSize: 12,
        outlineWidth: 1,
        color: Cesium.Color.fromCssColorString('#F54949'),
        outlineColor: Cesium.Color.fromCssColorString('#ffffff')
      }
    })
    // 节点
    this._loadNodeEntities()
  }

  // 加载标注节点
  _loadNodeEntities () {
    this._clearNode()
    for (let i = 0; i < this.#gisPosition.length; i++) {
      const item = this.#gisPosition[i]
      let pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ?? 0)
      pos = this._viewer.scene.clampToHeight(pos) ?? pos
      this.#nodeEntities.push(this._context.viewer.entities.add({
        position: pos,
        point: {
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          pixelSize: 12,
          outlineWidth: 1,
          color: Cesium.Color.fromCssColorString('#222222'),
          outlineColor: Cesium.Color.fromCssColorString('#bbbbbb')
        },
        label: {
          text: (i + 1).toString(),
          font: '12px 黑体',
          pixelOffset: new Cesium.Cartesian2(0, -20),
          fillColor: Cesium.Color.fromCssColorString('#FFFFFF'),
          outlineColor: Cesium.Color.fromCssColorString('#000000'),
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          outlineWidth: 12
        }
      }))
      // 中点
      const start = Cesium.Cartesian3.fromDegrees(this.#gisPosition[i][0], this.#gisPosition[i][1], this.#gisPosition[i][2] ?? 0)
      const endIndex = i === this.#gisPosition.length - 1 ? 0 : i + 1
      const end = Cesium.Cartesian3.fromDegrees(this.#gisPosition[endIndex][0], this.#gisPosition[endIndex][1], this.#gisPosition[endIndex][2] ?? 0)
      let midPos = Cesium.Cartesian3.midpoint(start, end, new Cesium.Cartesian3())
      midPos = this._viewer.scene.clampToHeight(midPos) ?? midPos
      this.#middleNodeEntities.push(this._context.viewer.entities.add({
        position: midPos,
        point: {
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          pixelSize: 12,
          outlineWidth: 1,
          color: Cesium.Color.fromCssColorString('#222222').withAlpha(0.7),
          outlineColor: Cesium.Color.fromCssColorString('#bbbbbb')
        }
      }))
    }
  }

  // 获取拾取entity
  _getPickEntity (position) {
    const object = this._context.viewer.scene.pick(position)
    if (object !== undefined && object.id !== undefined && object.id instanceof Cesium.Entity) {
      if (object.id.id === this.#centerNodeEntity.id) {
        return {
          type: 'center',
          entity: this.#centerNodeEntity
        }
      }
      for (let i = 0; i < this.#nodeEntities.length; i++) {
        const item = this.#nodeEntities[i]
        if (object.id.id === item.id) {
          return {
            type: 'node',
            index: i,
            entity: item
          }
        }
      }
      for (let i = 0; i < this.#middleNodeEntities.length; i++) {
        const item = this.#middleNodeEntities[i]
        if (object.id.id === item.id) {
          return {
            type: 'add',
            index: i,
            entity: item
          }
        }
      }
    }
    return undefined
  }

  // 更新节点与节点中点
  _updateNode () {
    for (let i = 0; i < this.#gisPosition.length; i++) {
      let pos = Cesium.Cartesian3.fromDegrees(this.#gisPosition[i][0], this.#gisPosition[i][1], this.#gisPosition[i][2] ?? 0)
      pos = this._viewer.scene.clampToHeight(pos) ?? pos
      this.#nodeEntities[i].position = pos

      const start = Cesium.Cartesian3.fromDegrees(this.#gisPosition[i][0], this.#gisPosition[i][1], this.#gisPosition[i][2] ?? 0)
      const endIndex = i === this.#gisPosition.length - 1 ? 0 : i + 1
      const end = Cesium.Cartesian3.fromDegrees(this.#gisPosition[endIndex][0], this.#gisPosition[endIndex][1], this.#gisPosition[endIndex][2] ?? 0)
      let midPos = Cesium.Cartesian3.midpoint(start, end, new Cesium.Cartesian3())
      midPos = this._viewer.scene.clampToHeight(midPos) ?? midPos
      this.#middleNodeEntities[i].position = midPos
    }
  }

  // 更新节点中点
  _updateMiddleNode () {
    for (let i = 0; i < this.#gisPosition.length; i++) {
      const start = Cesium.Cartesian3.fromDegrees(this.#gisPosition[i][0], this.#gisPosition[i][1], this.#gisPosition[i][2] ?? 0)
      const endIndex = i === this.#gisPosition.length - 1 ? 0 : i + 1
      const end = Cesium.Cartesian3.fromDegrees(this.#gisPosition[endIndex][0], this.#gisPosition[endIndex][1], this.#gisPosition[endIndex][2] ?? 0)
      let midPos = Cesium.Cartesian3.midpoint(start, end, new Cesium.Cartesian3())
      midPos = this._viewer.scene.clampToHeight(midPos) ?? midPos
      this.#middleNodeEntities[i].position = midPos
    }
  }

  // 更新中心点
  _updateCenterNode () {
    const geoJson = turf.lineString(this.#gisPosition)
    this.#centerGisPostion = turf.centerOfMass(geoJson).geometry.coordinates
    let pos = Cesium.Cartesian3.fromDegrees(this.#centerGisPostion[0], this.#centerGisPostion[1], this.#centerGisPostion[2] ?? 0)
    pos = this._viewer.scene.clampToHeight(pos) ?? pos
    this.#centerNodeEntity.position = pos
  }

  // 修改标注中心
  _changeMarkerCenter (position) {
    this.#centerNodeEntity.position = this._viewer.scene.clampToHeight(position) ?? position
    const originPos = Cesium.Cartesian3.fromDegrees(this.#centerGisPostion[0], this.#centerGisPostion[1], this.#centerGisPostion[2] ?? 0)
    const offset = Cesium.Cartesian3.subtract(position, originPos, new Cesium.Cartesian3())
    for (let i = 0; i < this.#gisPosition.length; i++) {
      const newPos = Cesium.Cartesian3.fromDegrees(this.#gisPosition[i][0], this.#gisPosition[i][1], this.#gisPosition[i][2] ?? 0)
      Cesium.Cartesian3.add(newPos, offset, newPos)
      const newDegree = this._context.utils.cartesianToLonLat(newPos)
      this.#gisPosition[i] = [newDegree.longitude, newDegree.latitude, newDegree.height]
    }
    const newCenter = this._context.utils.cartesianToLonLat(position)
    this.#centerGisPostion = [newCenter.longitude, newCenter.latitude, newCenter.height]
    this._updateNode()
  }

  // 修改标注节点
  _changeMarkerNode (node, position) {
    const point = this._context.utils.cartesianToLonLat(position)
    this.#gisPosition[node.index] = [point.longitude, point.latitude, point.height]
    this.#nodeEntities[node.index].position = position
    this._updateMiddleNode()
    this._updateCenterNode()
  }

  // 修改节点中点
  _changeMiddleNode (node, position) {
    const point = this._context.utils.cartesianToLonLat(position)
    this.#gisPosition[node.index + 1] = [point.longitude, point.latitude, point.height]
    this.#nodeEntities[node.index + 1].position = position
    this._updateMiddleNode()
    this._updateCenterNode()
  }

  // 添加标注节点
  _addMarkerNode (node) {
    const position = this.#middleNodeEntities[node.index].position.getValue(Cesium.JulianDate.now())
    const point = this._context.utils.cartesianToLonLat(position)
    this.#gisPosition.splice(node.index + 1, 0, [point.longitude, point.latitude, point.height])
    this._loadNodeEntities()
  }

  // 移除标注节点
  _remvoeMarkerNode (node) {
    if (this.#gisPosition.length <= 3) {
      return
    }
    this.#gisPosition.splice(node.index, 1)
    this._loadNodeEntities()
    this._updateCenterNode()
  }

  // cesium事件
  onCesiumEvent (type, event) {
    if (!this._actvie) {
      return
    }
    if (type === Cesium.ScreenSpaceEventType.LEFT_DOWN) {
      const res = this._getPickEntity(event.position)
      if (res) {
        this.pressLeft = true
        this.#selectedNode = res
        if (this.#selectedNode.type === 'add') {
          // const position = this._context.viewer.scene.pickPosition(event.position)
          this._addMarkerNode(this.#selectedNode)
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.MOUSE_MOVE) {
      if (this.pressLeft && this.#selectedNode) {
        this.setTipsText('释放后完成修改')
        const position = this._context.viewer.scene.pickPosition(event.endPosition)
        if (!position) {
          return
        }
        if (this.#selectedNode.type === 'center') {
          this._changeMarkerCenter(position)
        } else if (this.#selectedNode.type === 'node') {
          this._changeMarkerNode(this.#selectedNode, position)
        } else if (this.#selectedNode.type === 'add') {
          this._changeMiddleNode(this.#selectedNode, position)
        }

        if (this._markerId) {
          this._context.dispatch(CustomEvent.MarkerPositionChanged, {
            id: this._markerId,
            position: this.getGisPosition()
          })
        }
      } else {
        const res = this._getPickEntity(event.endPosition)
        if (res && res.type === 'center') {
          this.setTipsText('拖动该点整体平移')
        } else if (res && res.type === 'node') {
          this.setTipsText('拖动该点后修改位置<br>右击删除该点')
        } else if (res && res.type === 'add') {
          this.setTipsText('拖动该点增加点')
        } else {
          this.setTipsText('')
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.RIGHT_CLICK) {
      const res = this._getPickEntity(event.position)
      if (res && res.type === 'node') {
        if (this.#gisPosition.length <= 3) {
          this.setTipsText('无法删除，点数量不能少于3个')
          return
        }
        this._remvoeMarkerNode(res)
        if (this._markerId) {
          this._context.dispatch(CustomEvent.MarkerPositionChanged, {
            id: this._markerId,
            position: this.getGisPosition()
          })
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.LEFT_UP) {
      this.pressLeft = false
    }
    super.onCesiumEvent(type, event)
  }

  /**
   * 获取标注三维坐标
   * @returns 标注三维坐标
   */
  getPosition () {
    const _positions = []
    this.#gisPosition.forEach(item => {
      const pos = Cesium.Cartesian3.fromDegrees(item[0], item[1], item[2] ?? 0)
      _positions.push(pos)
    })
    return _positions
  }

  /**
   * 获取标注gis坐标
   * @returns 标注gis坐标
   */
  getGisPosition () {
    return this.#gisPosition
  }

  /**
   * 设置标注gis坐标
   * @param {Array} gisPosition 标注gis坐标
   */
  setGisPosition (gisPosition) {
    const lastCount = this.#gisPosition.length
    this.#gisPosition = JSON.parse(JSON.stringify(gisPosition))
    if (lastCount === this.#gisPosition.length) {
      this._updateNode()
    } else {
      this._loadNodeEntities()
    }
    this._updateCenterNode()
  }

  // 清除节点
  _clearNode () {
    this.#nodeEntities.forEach(item => {
      this._viewer.entities.remove(item)
    })
    this.#nodeEntities = []

    this.#middleNodeEntities.forEach(item => {
      this._viewer.entities.remove(item)
    })
    this.#middleNodeEntities = []
  }

  destroy () {
    if (this.#centerNodeEntity) {
      this._viewer.entities.remove(this.#centerNodeEntity)
      this.#centerNodeEntity = null
    }
    this._clearNode()
    super.destroy()
  }

  static fromMarker (context, marker) {
    const editor = new PolygonEditor(context, marker.getPolygonGisPositions())
    editor.markerId = marker.id
    if (!marker.selected) {
      editor.setStyle(marker.curStyles)
    } else {
      editor.setStyle(marker.lastStyles)
    }
    return editor
  }

  static fromGeoJson (context, geoJson) {
    const coordinates = JSON.parse(JSON.stringify(geoJson.geometry.coordinates[0]))
    if (geoJson.properties.height_) {
      const heights = JSON.parse(geoJson.properties.height_)
      for (let i = 0; i < heights.length; i++) {
        if (!coordinates[i][2]) {
          coordinates[i][2] = heights[i] && !isNaN(parseFloat(heights[i])) ? parseFloat(heights[i]) : 0
        }
      }
    }
    const drawMode = geoJson.properties.draw_mode_ ? parseInt(geoJson.properties.draw_mode_) : DrawMode.Polyline
    const editor = new PolygonEditor(context, coordinates, drawMode)
    editor.markerId = geoJson.properties.mark_id_
    return editor
  }
}

export default PolygonEditor
