import * as turf from '@turf/turf'
// eslint-disable-next-line no-unused-vars
import Marker from '../Marker/Marker'
import PolylineStyle from '../Marker/MarkerStyle/PolylineStyle'
// eslint-disable-next-line no-unused-vars
import PolylinePrimitive from '../Primitive/PolylinePrimitive'

/**
 * 体积测量
 */
class VolumeMeasure {
  constructor (context, viewer) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._context = context
    this._viewer = viewer

    // 正在分析中
    this._analyzing = false
    // 分析多边形id
    this._analyzeMarkerId = undefined
    // 分析结果标注Id
    this._analyzeResultIds = []
  }

  get analyzing () {
    return this._analyzing
  }

  /**
   * 体积测量
  * @param {Object} options 可选参数
  * @param {Marker} options.marker 测量的多边形
  * @param {Number} options.sampleSpace 采样间距，单位米，默认1米
  * @param {Number} options.depth 挖方深度，默认0
  * @param {Function} options.onStart 开始分享回调函数
  * @param {Function} options.onFinish 计算出体积后回调函数
  */
  analyze (options) {
    const _options = Cesium.defaultValue(options, {})
    const marker = Cesium.defaultValue(_options.marker, [])
    const sampleSpace = Cesium.defaultValue(_options.sampleSpace, 1)
    const debug = Cesium.defaultValue(_options.debug, true)
    const depth = Cesium.defaultValue(_options.depth, 0)
    const onStart = Cesium.defaultValue(_options.onStart, undefined)
    const onProgerssing = Cesium.defaultValue(_options.onProgerssing, undefined)
    const onFinish = Cesium.defaultValue(_options.onFinish, undefined)

    this._analyzeMarkerId = marker.id

    const polygonGeoJson = JSON.parse(marker.toGeoJson())
    // eslint-disable-next-line no-unused-vars
    const area = turf.area(polygonGeoJson)
    // 生成矩形包围盒
    // eslint-disable-next-line no-unused-vars
    const enveloped = turf.envelope(polygonGeoJson)
    // 生成边界框
    const bbox = turf.bbox(polygonGeoJson)
    // 生成点网格
    const grid = turf.pointGrid(bbox, sampleSpace, { units: 'meters' })
    // 返回多边形内的点
    const ptsWithin = turf.pointsWithinPolygon(grid, polygonGeoJson)
    const pointArrInput = []
    for (let i = 0; i < ptsWithin.features.length; i++) {
      const lon = ptsWithin.features[i].geometry.coordinates[0]
      const lat = ptsWithin.features[i].geometry.coordinates[1]
      pointArrInput.push(Cesium.Cartographic.fromDegrees(lon, lat, 0))
    }

    const self = this
    const sampledPoint = []
    // 采样点位高度
    function samplePoint (index) {
      if (!self._analyzing) {
        return
      }
      if (index >= pointArrInput.length) {
        const result = self.calculate(pointArrInput, depth, sampleSpace)
        if (onFinish) {
          onFinish(result)
        }
        if (debug) {
          self.samplePointDebug(pointArrInput, depth)
          // self.sampleBoxDebug(polygonGeoJson, sampleSpace, depth)
        }
        self._analyzing = false
        return
      }
      const point = pointArrInput[index]
      self._viewer.scene.sampleHeightMostDetailed([point], [marker.entity]).then((datas) => {
        // 更新进度
        if (onProgerssing) {
          onProgerssing(index / pointArrInput.length)
        }
        // console.log('progerssing====,',index/pointArrInput.length)
        sampledPoint.push(datas[0])
        index++
        samplePoint(index)
      })
    }

    if (onStart) {
      onStart()
    }
    self._analyzing = true
    samplePoint(0)
  }

  /**
   * 停止分析
   */
  stopAnalyze () {
    this._analyzing = false
    this.clear()
  }

  // 绘制采样点位
  samplePointDebug (pointArrInput, depth) {
    for (let i = 0; i < pointArrInput.length; i++) {
      const marker = this._context.markerManager.createMarker({
        layerId: 'volume-measure',
        position: [Cesium.Math.toDegrees(pointArrInput[i].longitude), Cesium.Math.toDegrees(pointArrInput[i].latitude), pointArrInput[i].height],
        polyline: {
          positions: [
            [Cesium.Math.toDegrees(pointArrInput[i].longitude), Cesium.Math.toDegrees(pointArrInput[i].latitude), depth],
            [Cesium.Math.toDegrees(pointArrInput[i].longitude), Cesium.Math.toDegrees(pointArrInput[i].latitude), pointArrInput[i].height]
          ],
          clampToGround: false
        },
        point: {
          show: true
        }
      })
      if (depth < pointArrInput[i].height) {
        marker.setStyle([
          new PolylineStyle({
            material: Cesium.Color.fromCssColorString('#1890FF'),
            width: 3
          })
        ])
      } else {
        marker.setStyle([
          new PolylineStyle({
            material: Cesium.Color.fromCssColorString('#ffffff'),
            width: 3
          })
        ])
      }

      // let positions = []
      // positions.push(Cesium.Cartographic.toCartesian(pointArrInput[i]))
      // let point2 = pointArrInput[i].clone()
      // point2.height = 0
      // positions.push(Cesium.Cartographic.toCartesian(point2))
      // let primitive = new PolylinePrimitive({
      //   positions: positions
      // })
      // this._viewer.scene.primitives.add(primitive)
    }
  }

  // 采样边框
  sampleBoxDebug (polygonGeoJson, sampleSpace, depth) {
    // 生成边界框
    const bbox = turf.bbox(polygonGeoJson)
    const squareGrid = turf.squareGrid(bbox, sampleSpace, { units: 'meters' })
    const features = squareGrid.features
    for (let i = 0; i < features.length; i++) {
      const feature = features[i]
      const coord = feature.geometry.coordinates[0]
      const points = []
      for (let j = 0; j < coord.length; j++) {
        points.push([coord[j][0], coord[j][1], 0])
      }
      const center = turf.center(feature)
      const centerPoint = center.geometry.coordinates
      if (turf.booleanWithin(feature, polygonGeoJson)) {
        this._context.markerManager.createMarker({
          layerId: 'volume-measure',
          polyline: {
            positions: points,
            clampToGround: false
          }
        })
        this._context.markerManager.createMarker({
          layerId: 'volume-measure',
          position: [centerPoint[0], centerPoint[1], depth],
          polyline: {
            positions: [
              [centerPoint[0], centerPoint[1], 0],
              [centerPoint[0], centerPoint[1], depth]
            ],
            clampToGround: false
          },
          point: {
            show: true
          }
        })
      }
    }
  }

  // 计算体积
  calculate (points, depth, cellSize) {
    let excavation = 0 // 挖方
    let fill = 0 // 填方
    let minHeight = 100000
    let maxHeight = -100000
    for (let i = 0; i < points.length; i++) {
      if (points[i].height > maxHeight) {
        maxHeight = points[i].height
      }
      if (points[i].height < minHeight) {
        minHeight = points[i].height
      }
      const height = points[i].height - depth
      const volume = Math.abs(height) * cellSize * cellSize
      if (height > 0) {
        excavation += volume
      } else {
        fill += volume
      }
    }
    return {
      excavation: excavation,
      fill: fill,
      minHeight: minHeight,
      maxHeight: maxHeight
    }
  }

  // 清除结果
  clear () {
    if (this._analyzeMarkerId) {
      this._context.markerManager.removeMarkerById(this._analyzeMarkerId)
      this._analyzeMarkerId = undefined
    }
    this._context.markerManager.removeMarkerByLayer('volume-measure', true)
  }

  destroy () {

  }
}

export default VolumeMeasure
