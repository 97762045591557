import * as turf from '@turf/turf'
import FirstPersonCameraController from './FirstPersonCameraController'

/**
 * 摄像机类
 */
class CameraManager {
  constructor (context, viewer) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._context = context
    this._viewer = viewer
    this.firstPersonCameraController = new FirstPersonCameraController(context, viewer)

    // 摄像机聚集点半径
    this.billboardRadius = 200
    // 摄像机限制范围
    this._cameraLimitRectangle = undefined
    // 上次相机位置
    this._lastCameraPosition = undefined
    // 摄像机改变事件
    this._viewer.scene.camera.percentageChanged = 0.0001
    this._viewer.scene.camera.changed.addEventListener(this.onCameraChanged, this)
  }

  get viewer () {
    return this._viewer
  }

  // 第一人称视角是否可用
  get enableFirstPersion () {
    return this.firstPersonCameraController.enabled
  }

  set enableFirstPersion (value) {
    this.firstPersonCameraController.enabled = value
  }

  // 键盘控制是否可用
  get enableKeyControl () {
    return this.firstPersonCameraController.enabled && this.firstPersonCameraController.isKeyControl
  }

  set enableKeyControl (value) {
    this.firstPersonCameraController.isKeyControl = value
  }

  // 鼠标滚轮是否可用
  get enableWheel () {
    return this.firstPersonCameraController.enabled && this.firstPersonCameraController.isWheel
  }

  set enableWheel (value) {
    this.firstPersonCameraController.isWheel = value
  }

  // 摄像机改变事件
  onCameraChanged () {
    // 相机范围限制
    if (this._cameraLimitRectangle) {
      const curPos = this.viewer.camera.positionCartographic.clone()
      curPos.height = 0
      if (Cesium.Rectangle.contains(this._cameraLimitRectangle, curPos)) {
        this._lastCameraPosition = this.viewer.camera.position.clone()
      } else {
        if (this._lastCameraPosition) {
          this.viewer.camera.setView({
            destination: this._lastCameraPosition
          })
        }
      }
    }
  }

  //
  /**
   * 计算geoJson的摄像机聚焦位置
   * @param {*} geoJson
   * @param {*} options https://cesium.com/learn/cesiumjs/ref-doc/Camera.html?classFilter=ca#flyToBoundingSphere
   */
  zoomToGeometry (geoJson, options = {}) {
    /**
     * scale = 1
     * offset : HeadingPitchRange
     */
    const { scale = 1 } = options
    // 计算包围盒
    const bbox = turf.bbox(geoJson)
    const rectangle = Cesium.Rectangle.fromDegrees(bbox[0], bbox[1], bbox[2], bbox[3])
    const center = Cesium.Rectangle.center(rectangle)
    const centCartesian = Cesium.Cartographic.toCartesian(center)
    // 默认半径
    let radius = this.billboardRadius
    if (rectangle.width > 0.000000001 && rectangle.height > 0.000000001) {
      // 包围盒对角线长度作为半径
      const start = Cesium.Cartographic.fromDegrees(bbox[0], bbox[1])
      const end = Cesium.Cartographic.fromDegrees(bbox[2], bbox[3])
      const geodesic = new Cesium.EllipsoidGeodesic(start, end)
      radius = geodesic.surfaceDistance * scale
    } else {
      // TODO：如果是点，包围球半径不变
      radius = this.billboardRadius * scale
    }
    // 计算包围球
    const bs = new Cesium.BoundingSphere(centCartesian, radius)
    this.viewer.camera.flyToBoundingSphere(bs, {
      duration: 1,
      ...options
    })
  }

  /**
   * 计算点的摄像机聚焦位置
   * @param {Cartesian3} position 点的Cartesian3位置
   */
  zoomToPosition (position, options = {
    duration: 1
  }) {
    const radius = this.billboardRadius
    // 计算包围球
    const bs = new Cesium.BoundingSphere(position, radius)
    this.viewer.camera.flyToBoundingSphere(bs, {
      ...options
    })
  }

  /**
   * 飞向标注位置
   * @param {String} markerId 标注ID
   * @param {Object} options 可选参数
   * @param {Number} options.duration 飞行时长，单位秒，默认1
   */
  flyToMarker (markerId, options) {
    if (!markerId) {
      return
    }
    const marker = this._context.markerManager.getMarkerById(markerId)
    if (marker) {
      const geoJson = JSON.parse(marker.toGeoJson())
      this.zoomToGeometry(geoJson, options)
    } else {
      if (markerId.startsWith('billboard-')) {
        const layerId = markerId.replace('billboard-', '').split('-')[0]
        const bundle = this._context.primitiveManager.getBundle(layerId)
        if (bundle) {
          const primitive = bundle.getBillboard(markerId)
          if (primitive) {
            this.zoomToPosition(primitive.position, options)
          }
        }
      }
    }
  }

  // cesium 事件消息
  onCesiumEvent (type, event) {}
  /**
   * 相机飞向指定位置
   * @param {Object} options 可选参数
   * @param {Array} options.position 飞向目标位置坐标[经度, 纬度, 高度 ]，经纬度为角度格式，高度单位米
   * @param {Array} options.direction 飞向目标时相机方向[偏航角, 俯仰角, 翻滚角 ]，经纬度为角度格式
   * @param {Array} options.duration 飞行时长，单位秒，默认2
   */
  flyTo (options) {
    const _options = Cesium.defaultValue(options, {})
    const position = Cesium.defaultValue(_options.position, [0, 0, 0])
    const direction = Cesium.defaultValue(_options.direction, [0, 0, 0])
    const duration = Cesium.defaultValue(_options.duration, 2)
    const maximumHeight = Cesium.defaultValue(_options.maximumHeight, undefined)
    const cesiumOption = {
      destination: Cesium.Cartesian3.fromDegrees(position[0], position[1], position[2]),
      duration: duration,
      maximumHeight: maximumHeight,
      orientation: {
        heading: Cesium.Math.toRadians(direction[0]),
        pitch: Cesium.Math.toRadians(direction[1]),
        roll: Cesium.Math.toRadians(direction[2])
      }
    }
    this.viewer.camera.flyTo(cesiumOption)
  }

  /**
   * 获取当前摄像机位置
   * @returns {Array} 获取摄像机位置[经度, 纬度, 高度 ]
   */
  getCameraPosition () {
    const curLon = Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.longitude)
    const curLat = Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.latitude)
    const curHeight = this.viewer.camera.positionCartographic.height
    return [curLon, curLat, curHeight]
  }

  /**
   * 获取当前摄像机方向
   * @returns {Array} 获取摄像机方向[heading, pitch, roll]
   */
  getCameraDirection () {
    if (this.viewer.scene.mode !== Cesium.SceneMode.SCENE3D) return [0, 0, 0]
    const heading = Cesium.Math.toDegrees(this.viewer.camera.heading)
    const pitch = Cesium.Math.toDegrees(this.viewer.camera.pitch)
    const roll = Cesium.Math.toDegrees(this.viewer.camera.roll)
    return [heading, pitch, roll]
  }

  /**
   * 获取两点间的摄像机倾斜角与偏航角
   * @param {Cesium.Cartesian3} fromPosition
   * @param {Cesium.Cartesian3} toPosition
   * @returns
   */
  getHeadingPitch (fromPosition, toPosition) {
    const finalPosition = new Cesium.Cartesian3()
    const matrix4 = Cesium.Transforms.eastNorthUpToFixedFrame(fromPosition)
    Cesium.Matrix4.inverse(matrix4, matrix4)
    Cesium.Matrix4.multiplyByPoint(matrix4, toPosition, finalPosition)
    Cesium.Cartesian3.normalize(finalPosition, finalPosition)
    const heading = Cesium.Math.toDegrees(Math.atan2(finalPosition.x, finalPosition.y))
    const pitch = Cesium.Math.toDegrees(Math.asin(finalPosition.z))
    return [heading, pitch]
  }

  /**
   * 设置相机视角
   * @param {Object} options 参数
   * @param {Cesium.Cartesian3} options.originCartesian3 摄像机起点
   * @param {Cesium.Cartesian3} options.desCartesian3 摄像机看向的终点
   */
  setView (options) {
    const _options = Cesium.defaultValue(options, {})
    const originCartesian3 = Cesium.defaultValue(_options.originCartesian3, Cesium.Cartesian3.ZERO)
    const desCartesian3 = Cesium.defaultValue(_options.desCartesian3, Cesium.Cartesian3.ONE)
    // let originCartesian3 = new Cesium.Cartesian3.fromDegrees(originPosition[0], originPosition[1], originPosition[2])
    // let desCartesian3 = new Cesium.Cartesian3.fromDegrees(destination[0], destination[1], destination[2])
    // 起点插值，避免起点在模型里面
    const distance = Cesium.Cartesian3.distance(originCartesian3, desCartesian3)
    const lerpOrigin = Cesium.Cartesian3.lerp(originCartesian3, desCartesian3, 1 / distance, new Cesium.Cartesian3())
    const headingPitch = this.getHeadingPitch(lerpOrigin, desCartesian3)
    this._viewer.camera.setView({
      destination: lerpOrigin,
      orientation: {
        heading: Cesium.Math.toRadians(headingPitch[0]),
        pitch: Cesium.Math.toRadians(headingPitch[1]),
        roll: 0.0
      }
    })
  }

  limitRectangle (cameraRectangel) {
    this._cameraLimitRectangle = cameraRectangel
  }

  /**
   * 设置相机中心旋转
   * @param {Object} options 参数
   * @param {Number} options.pitch 相机倾斜角，单位角度
   * @param {Number} options.period 旋转周期，单位秒
   * @param {Number} options.height 相机高度，单位米
   * @param {Array} options.headingRange 偏移范围
   * @param {Cesium.Cartesian3} options.centerPos 相机围绕的中心坐标
   * @param {Number} options.rotateType 旋转方式，0:loop；1:pingpong
   */
  rotateCenter (options) {
    this.stopRotate()
    const _options = Cesium.defaultValue(options, {})
    let centerPos = Cesium.defaultValue(_options.centerPos, undefined)
    const pitch = _options.pitch ? Cesium.Math.toRadians(_options.pitch) : this.viewer.camera.pitch
    const period = Cesium.defaultValue(_options.period, 60)
    // const height = Cesium.defaultValue(_options.height, this.viewer.camera.positionCartographic.height)
    const headingRange = Cesium.defaultValue(_options.headingRange, [0, 360])
    const rotateType = Cesium.defaultValue(_options.rotateType, 0)
    if (!centerPos) {
      const screenPos = new Cesium.Cartesian2(this.viewer.canvas.clientWidth / 2, this.viewer.canvas.clientHeight / 2)
      const ray = this.viewer.camera.getPickRay(screenPos)
      const intersection = this.viewer.scene.globe.pick(ray, this.viewer.scene)
      centerPos = intersection
    }
    const originPosition = this.viewer.scene.camera.position
    const distance = Cesium.Cartesian3.distance(originPosition, centerPos)

    const _self = this
    const interval = 10
    const totalCount = (period * 1000) / 10
    const value = (headingRange[1] - headingRange[0]) / totalCount
    const originHeading = Cesium.Math.toDegrees(this.viewer.scene.camera.heading)
    let curHeading = originHeading
    let headingOffset = 0
    let isReverse = false
    this._rotateTimer = setInterval(function () {
      if (rotateType === 0) { // loop
        headingOffset = headingOffset + value
        if (headingOffset > headingRange[1]) {
          headingOffset = headingRange[0]
        }
      } else if (rotateType === 1) { // pingpong
        if (isReverse) {
          headingOffset = headingOffset - value
        } else {
          headingOffset = headingOffset + value
        }
        if (headingOffset > headingRange[1]) {
          isReverse = true
        }
        if (headingOffset < headingRange[0]) {
          isReverse = false
        }
      }
      curHeading = originHeading + headingOffset
      // if (curHeading > 360) {
      //   curHeading = curHeading - 360
      // }
      // if (curHeading < -360) {
      //   curHeading = curHeading + 360
      // }
      const heading = Cesium.Math.toRadians(curHeading)
      _self.viewer.scene.camera.setView({
        destination: centerPos, // 点的坐标
        orientation: {
          heading: heading,
          pitch: pitch,
          roll: 0
        }
      })
      _self.viewer.scene.camera.moveBackward(distance)
    }, interval)
  }

  /**
   * 停止旋转
   */
  stopRotate () {
    if (this._rotateTimer) {
      clearInterval(this._rotateTimer)
      this._rotateTimer = undefined
    }
  }

  destroy () {}
}
export default CameraManager
