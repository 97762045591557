
class MarkerStyle {
  constructor () {
    this._type = undefined
  }

  get type () {
    return this._type
  }

  decorate (entity) {

  }

  equals (style) {

  }
}

export default MarkerStyle
