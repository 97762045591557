import MarkerStyle from './MarkerStyle'
/**
 * 线条标注style类
 */
class PolylineStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'polyline'
    this._material = Cesium.defaultValue(_options.material, Cesium.Color.fromCssColorString('#1890FF'))
    this._clampToGround = Cesium.defaultValue(_options.clampToGround, true)
    this._width = Cesium.defaultValue(_options.width, 1)
  }

  decorate (entity) {
    if (!entity.polyline) {
      return
    }
    entity.polyline.material = this._material
    entity.polyline.depthFailMaterial = this._material
    entity.polyline.width = this._width
  }

  equals (other) {
    return this === other || (
      other instanceof PolylineStyle &&
        this._type === other._type &&
        this._clampToGround === other._clampToGround &&
        this._width === other._width &&
        this._material.equals(other._material)
    )
  }

  static Default () {
    return new PolylineStyle({
      material: Cesium.Color.fromCssColorString('#1890FF'),
      width: 4
    })
  }

  static Measure () {
    return new PolylineStyle({
      material: Cesium.Color.fromCssColorString('#F2AB3F'),
      width: 4
    })
  }

  static Selected () {
    return new PolylineStyle({
      material: new Cesium.PolylineOutlineMaterialProperty({
        color: Cesium.Color.fromCssColorString('#0FF2F5'),
        outlineWidth: 4,
        outlineColor: Cesium.Color.fromCssColorString('#1890FF')
      }),
      width: 6
    })
  }
}

export default PolylineStyle
