import MarkerStyle from './MarkerStyle'
/**
 * 围墙标注style类
 */
class WallStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'wall'
    this._material = Cesium.defaultValue(_options.material, Cesium.Color.fromCssColorString('#1890FF'))
    this._outline = Cesium.defaultValue(_options.outline, false)
    this._outlineColor = Cesium.defaultValue(_options.outlineColor, Cesium.Color.fromCssColorString('#ffffff'))
    this._outlineWidth = Cesium.defaultValue(_options.outlineWidth, 1.0)
    this._maximumHeights = Cesium.defaultValue(_options.maximumHeights, undefined)
  }

  decorate (entity) {
    if (!entity.wall) {
      return
    }
    let wallLength = 0
    entity.wall.outline = this._outline
    entity.wall.outlineColor = this._outlineColor
    entity.wall.outlineWidth = this._outlineWidth
    if (this._maximumHeights) {
      const heights = []
      const positions = entity.wall.positions.getValue(Cesium.JulianDate.now())
      for (let i = 0; i < positions.length; i++) {
        heights.push(this._maximumHeights)
        if (i < positions.length - 1) {
          const distance = Cesium.Cartesian3.distance(positions[i], positions[i + 1])
          wallLength += distance
        }
      }
      entity.wall.maximumHeights = heights
      const _horizontalRepeat = wallLength / this._maximumHeights
      this._material = this._material.clone()
      this._material._horizontalRepeat = _horizontalRepeat
      // this._material._horizontalRepeat = wallLength / this._maximumHeights
    }
    entity.wall.material = this._material
  }

  equals (other) {
    // return this === other || (
    //   other instanceof WallStyle &&
    //     this._type === other._type &&
    //     this._outline === other._outline &&
    //     this._outlineWidth === other._outlineWidth &&
    //     this._maximumHeights === other._maximumHeights &&
    //     Cesium.Color.equals(this._outlineColor, other._outlineColor) &&
    //     this._material.equals(other._material)
    // )
    return false
  }

  static Default () {
    return new WallStyle({
      material: Cesium.Color.fromCssColorString('#1890FF')
    })
  }

  static Selected () {
    return new WallStyle({
      material: Cesium.Color.fromCssColorString('#1890FF')
    })
  }
}

export default WallStyle
