
var defaultColor = Cesium.Color.WHITE
var defaultFlowLength = 0.1
var defaultTotalTime = 2

/**
 * 流动线条纹理
 * @constructor
 *
 * @param {Object} [options] Object with the following properties:
 * @param {Property} [options.color=Color.WHITE] 线条颜色
 * @param {Property} [options.flowLength=0.1] 流动可见区域长度.
 * @param {Property} [options.totalTime=2] 流动一个周期消耗时间
 */
function PolylineFlowMaterialProperty (options) {
  options = Cesium.defaultValue(options, Cesium.defaultValue.EMPTY_OBJECT)

  this._definitionChanged = new Cesium.Event()
  this._color = Cesium.defaultValue(options.color, defaultColor)
  this._flowLength = Cesium.defaultValue(options.flowLength, defaultFlowLength)
  this._totalTime = Cesium.defaultValue(options.totalTime, defaultTotalTime)

  this._time = new Date().getTime()
}

Object.defineProperties(PolylineFlowMaterialProperty.prototype, {
  /**
   * Gets a value indicating if this property is constant.  A property is considered
   * constant if getValue always returns the same result for the current definition.
   * @memberof PolylineFlowMaterialProperty.prototype
   * @type {Boolean}
   * @readonly
   */
  isConstant: {
    get: function () {
      return true
    }
  },
  /**
   * Gets the event that is raised whenever the definition of this property changes.
   * The definition is considered to have changed if a call to getValue would return
   * a different result for the same time.
   * @memberof PolylineFlowMaterialProperty.prototype
   * @type {Event}
   * @readonly
   */
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
  /**
   * Gets or sets the Property specifying the {@link Color} of the line.
   * @memberof PolylineFlowMaterialProperty.prototype
   * @type {Property}
   */
  color: Cesium.createPropertyDescriptor('color'),

  /**
   * Gets or sets the numeric Property specifying the strength of the glow, as a percentage of the total line width (less than 1.0).
   * @memberof PolylineFlowMaterialProperty.prototype
   * @type {Property}
   */
  flowLength: Cesium.createPropertyDescriptor('flowLength')
})

PolylineFlowMaterialProperty.prototype.getType = function (time) {
  return 'PolylineFlow'
}

PolylineFlowMaterialProperty.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }
  result.color = this._color
  result.flowLength = this._flowLength
  const totalTime = this._totalTime * 1000
  result.time = ((new Date().getTime() - this._time) % totalTime) / totalTime
  return result
}

/**
 * Compares this property to the provided property and returns
 * <code>true</code> if they are equal, <code>false</code> otherwise.
 *
 * @param {Property} [other] The other property.
 * @returns {Boolean} <code>true</code> if left and right are equal, <code>false</code> otherwise.
 */
PolylineFlowMaterialProperty.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof PolylineFlowMaterialProperty &&
      Cesium.Property.equals(this._color, other._color) &&
      Cesium.Property.equals(this._flowLength, other._flowLength) &&
      Cesium.Property.equals(this._totalTime, other._totalTime))
  )
}

Cesium.Material.PolylineFlowMaterialType = 'PolylineFlow'
Cesium.Material.PolylineFlowMaterialLinkSource =
  `uniform vec4 color;
  uniform float flowLength;
  uniform float time;

  czm_material czm_getMaterial(czm_materialInput materialInput)
  {
      czm_material material = czm_getDefaultMaterial(materialInput);

      vec2 st = materialInput.st;
      float s = clamp(st.s, time-flowLength, time)-(time-flowLength);
      // float glow = 0.1 / abs(st.t - 0.5) - (0.1 / 0.5);
      float flow = s*(1.0/flowLength);
      flow = fract(flow+0.5);
      // if(flow>0.99) {
      //   flow = 0.0;
      // }

      material.emission = color.rgb;
      material.alpha = flow;

      return material;
  }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.PolylineFlowMaterialType, {
  fabric: {
    type: Cesium.Material.PolylineFlowMaterialType,
    uniforms: {
      color: defaultColor,
      flowLength: defaultFlowLength,
      totalTime: defaultTotalTime,
      time: 0
    },
    source: Cesium.Material.PolylineFlowMaterialLinkSource
  },
  translucent: true
})
Cesium.PolylineFlowMaterialProperty = PolylineFlowMaterialProperty
