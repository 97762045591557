import MarkerStyle from './MarkerStyle'
import markerImage from '../../Assets/Images/marker.png'
/**
 * 公告牌标注style类
 */
class BillboardStyle extends MarkerStyle {
  /**
   * 公告牌标注样式类
   * @param {Object} options 参数
   * @param {String} image 公告板图片地址
   * @param {String} color 可选，公告板颜色,css格式
   * @param {Number} width 可选，公告板宽度
   * @param {Number} height 可选，公告板高度
   * @param {Number} scale 可选，缩放比例
   */
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'billboard'
    let _color = Cesium.defaultValue(_options.color, undefined)
    if (typeof _color === 'string') {
      _color = Cesium.Color.fromCssColorString(_color)
    }
    this._color = _color
    this._image = Cesium.defaultValue(_options.image, undefined)
    this._width = Cesium.defaultValue(_options.width, undefined)
    this._height = Cesium.defaultValue(_options.height, undefined)
    this._scale = Cesium.defaultValue(_options.scale, 1)
    this._heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.NONE)
    this._disableDepthTestDistance = Cesium.defaultValue(_options.disableDepthTestDistance, undefined)
    this._isCluster = Cesium.defaultValue(_options.isCluster, false)
  }

  get isCluster () {
    return this._isCluster
  }

  decorate (entity) {
    if (!entity.billboard) {
      return
    }
    if (this._image) {
      entity.billboard.image = this._image
    }
    if (this._color) {
      entity.billboard.color = this._color
    }
    entity.billboard.scale = this._scale
    entity.billboard.width = this._width
    entity.billboard.height = this._height
    entity.billboard.heightReference = this._heightReference
    entity.billboard.disableDepthTestDistance = this._disableDepthTestDistance
    entity.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM // 设置图标图片底部接触场景
  }

  equals (other) {
    return this === other || (
      other instanceof BillboardStyle &&
        this._type === other._type &&
        Cesium.Color.equals(this._color, other._color) &&
        this._image === other._image &&
        this._width === other._width &&
        this._height === other._height &&
        this._scale === other._scale &&
        this._heightReference === other._heightReference &&
        this._disableDepthTestDistance === other._disableDepthTestDistance &&
        this._isCluster === other._isCluster
    )
  }

  static Default () {
    return new BillboardStyle({
      color: Cesium.Color.fromCssColorString('#FF0000'),
      image: markerImage,
      width: 30,
      height: 30,
      scale: 1
    })
  }

  static Selected () {
    return new BillboardStyle({
      // color: Cesium.Color.fromCssColorString('#0076F6'),
      // image: markerImage,
      width: 30,
      height: 30,
      scale: 1.5
    })
  }

  static close () {
    return new BillboardStyle({
      image: require('../../Assets/Images/close.png'),
      color: Cesium.Color.fromCssColorString('#FF0000'),
      pixelOffset: new Cesium.Cartesian2(0, -10),
      width: undefined,
      heigth: undefined,
      heightReference: Cesium.HeightReference.NONE,
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    })
  }
}

export default BillboardStyle
