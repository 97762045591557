/**
 * 根据两点坐标，计算插值弧线
 * @param twoPoints
 * @returns {[]}
 */

export function animatedParabola (twoPoints) {
  const pointsArray = []
  const startPoint = [twoPoints[0], twoPoints[1], 0] // 起点的经度、纬度
  pointsArray.push(startPoint[0])
  pointsArray.push(startPoint[1])
  pointsArray.push(0)
  const step = 80 // 线的数量，越多则越平滑
  const heightProportion = 0.5 // 最高点和总距离的比值(即图中H比上AB的值)
  const dLon = (twoPoints[2] - startPoint[0]) / step // 经度差值
  const dLat = (twoPoints[3] - startPoint[1]) / step // 纬度差值
  const deltaLon = dLon * Math.abs(111000 * Math.cos(twoPoints[1])) // 经度差(米级)
  const deltaLat = dLat * 111000 // 纬度差(米),1纬度相差约111000米
  const endPoint = [0, 0, 0] // 定义一个端点（后面将进行startPoint和endPoint两点画线）
  const heigh = (step * Math.sqrt(deltaLon * deltaLon + deltaLat * deltaLat) * heightProportion).toFixed(0)
  const x2 = (10000 * Math.sqrt(dLon * dLon + dLat * dLat)) // 小数点扩大10000倍，提高精确度
  const a = (heigh / (x2 * x2)) // 抛物线函数中的a
  function y (x, height) { // 模拟抛物线函数求高度
    // 此处模拟的函数为y = H - a*x^2  (H为高度常数)
    return height - a * x * x
  }
  for (let i = 1; i <= step; i++) { // 逐“帧”画线
    endPoint[0] = startPoint[0] + dLon // 更新end点经度
    endPoint[1] = startPoint[1] + dLat // 更新end点纬度
    const x = x2 * (2 * i / step - 1) // 求抛物线函数x
    endPoint[2] = parseFloat((y(x, heigh)).toFixed(0)) // 求end点高度
    pointsArray.push(endPoint[0])
    pointsArray.push(endPoint[1])
    pointsArray.push(endPoint[2])
    // end点变为start点
    startPoint[0] = endPoint[0]
    startPoint[1] = endPoint[1]
    startPoint[2] = endPoint[2]
  }
  return pointsArray
}

/**
 * 生成不重复ID
 * @returns {string}
 */
export function generateId () {
  return (
    (Math.random() * 10000000).toString(16).substr(0, 4) +
    '-' +
    new Date().getTime() +
    '-' +
    Math.random().toString().substr(2, 5)
  )
}

/**
 * 获取渐变色图片
 * @param color
 * @returns {string}
 */
export function getColorRamp (color) {
  const color0 = Cesium.Color.fromCssColorString(color)
  color0.alpha = 0.9
  const color1 = Cesium.Color.fromCssColorString(color)
  color1.alpha = 0.6
  const color2 = Cesium.Color.fromCssColorString(color)
  color2.alpha = 0.1
  const ramp = document.createElement('canvas')
  ramp.width = 1
  ramp.height = 100
  const ctx = ramp.getContext('2d')
  const grd = ctx.createLinearGradient(0, 0, 0, 100)
  grd.addColorStop(1, color0.toCssColorString())
  grd.addColorStop(0.7, color1.toCssColorString())
  grd.addColorStop(0, color2.toCssColorString())
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, 1, 100)
  return ramp.toDataURL('image/png')
}

/**
 * 计算围墙的最大最小高度数组
 * @param positions
 * @param extrudedHeight
 * @returns {{minimumHeights: [], maximumHeights: []}}
 */
export function calMaxMinHeights (positions, extrudedHeight) {
  const minimumHeights = []
  const maximumHeights = []
  positions.forEach(item => {
    const position = cartesianToLonLat(item)
    minimumHeights.push(position.height)
    maximumHeights.push(position.height + extrudedHeight)
  })
  return { minimumHeights, maximumHeights }
}

/**
 * 笛卡尔坐标转经纬度
 * @param cartesian
 * @returns {{latitude: (Number|number|*), longitude: (Number|number|*), height: (*|number)}}
 */
export function cartesianToLonLat (cartesian) {
  const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
  const longitude = Cesium.Math.toDegrees(cartographic.longitude)
  const latitude = Cesium.Math.toDegrees(cartographic.latitude)
  return {
    longitude,
    latitude,
    height: cartographic.height > 0 ? cartographic.height : 0
  }
}
/**
 * entity导出为geoJson
 * @param entity {Object} entity
 * @returns {String} geoJson格式字符串
 */
export function entityExportGeoJson (entity) {
  const geoData = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: undefined,
      coordinates: []
    }
  }
  // let jsonData = {
  //   type: "FeatureCollection",
  //   features: []
  // }
  if (entity) {
    if (entity.point || entity.billboard) {
      const _pos = cartesianToLonLat(entity.position.getValue(Cesium.JulianDate.now()))
      // let geoData = JSON.parse(JSON.stringify(jsonTmp))
      geoData.geometry.type = 'Point'
      geoData.geometry.coordinates = [_pos.longitude, _pos.latitude, _pos.height]

      geoData.properties.id = entity.id
    } else if (entity.polygon) {
      geoData.geometry.type = 'Polygon'
      const positions = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
      const pointList = []
      for (let i = 0; i < positions.length; i++) {
        const point = positions[i]
        const _pos = cartesianToLonLat(point)
        pointList.push([_pos.longitude, _pos.latitude, _pos.height])
      }
      const length = pointList.length
      if (pointList[0][0] !== pointList[length - 1][0] || pointList[0][1] !== pointList[length - 1][1]) {
        pointList.push([pointList[0][0], pointList[0][1], pointList[0][2]])
      }
      geoData.geometry.coordinates.push(pointList)

      geoData.properties.id = entity.id
    } else if (entity.polyline) {
      geoData.geometry.type = 'LineString'
      const positions = entity.polyline.positions.getValue(Cesium.JulianDate.now())
      for (let i = 0; i < positions.length; i++) {
        const point = positions[i]
        const _pos = cartesianToLonLat(point)
        geoData.geometry.coordinates.push([_pos.longitude, _pos.latitude, _pos.height])
      }

      geoData.properties.id = entity.id
    }
    // jsonData.features.push(geoData)
  }
  return JSON.stringify(geoData)
}

// canvas 截图
export function screenCapture (_canvas, width, height) {
  // let _canvas = cesiumViewer.canvas;
  const MIME_TYPE = 'image/jpeg'
  const quality = 0.8
  // let imgData = _canvas.toDataURL(MIME_TYPE, quality);

  const canvas2 = document.createElement('canvas')
  canvas2.id = 'drawImg'
  canvas2.style.display = 'none'
  var ctx2 = canvas2.getContext('2d')
  canvas2.width = width || _canvas.width
  canvas2.height = height || _canvas.height
  document.body.appendChild(canvas2)
  ctx2.drawImage(_canvas, 0, 0, canvas2.width, canvas2.height)
  const imgData2 = canvas2.toDataURL(MIME_TYPE, quality)
  document.body.removeChild(canvas2)

  // const dlLink = document.createElement('a')
  // dlLink.download = 'screencapture.jpg'
  // dlLink.href = imgData2
  // dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':')
  // document.body.appendChild(dlLink)
  // dlLink.click()
  // document.body.removeChild(dlLink)

  return imgData2
}

/**
 * 圆转换为多边形点坐标
 * @param {{centerPosition:[],radius:Number,granularity:Number}} options
 * @returns {Array} 多边形点
 */
export function getEllipsePositions (options) {
  const _options = Cesium.defaultValue(options, {})
  const centerPosition = Cesium.defaultValue(_options.centerPosition, undefined)
  const radius = Cesium.defaultValue(_options.radius, 1)
  const granularity = Cesium.defaultValue(_options.granularity, Cesium.Math.RADIANS_PER_DEGREE)
  const cep = Cesium.EllipseGeometryLibrary.computeEllipsePositions({
    center: centerPosition,
    semiMajorAxis: radius,
    semiMinorAxis: radius,
    rotation: 0.0,
    granularity: granularity
  }, false, true)
  const positions = Cesium.Cartesian3.unpackArray(cep.outerPositions)
  return positions
}
/**
 * 距离转字符串
 * @param {Number} num 距离
 * @returns 距离字符串
 */
export function distanceToString (num) {
  let distacne = num
  if (distacne < 1000) {
    return distacne.toFixed(2) + '米'
  } else {
    distacne = distacne / 1000
    return distacne.toFixed(2) + '千米'
  }
}
/**
 * 采样点位高度
 * @param {*} viewer viewer
 * @param {*} lon 经度
 * @param {*} lat 纬度
 * @returns 高度
 */
export function samplePointHeight (viewer, lon, lat) {
  const point = Cesium.Cartographic.fromDegrees(lon, lat)
  return new Promise((resolve) => {
    viewer.scene.sampleHeightMostDetailed([point]).then((datas) => {
      resolve(datas && datas.length > 0 ? datas[0].height : 0)
    })
  })
}
