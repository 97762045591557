/**
 * 标注编辑基类
 */

import Tooltips from '../../Widget/Tooltips'

class BaseEditor {
  // DrawMode类型
  _type = undefined
  _context = undefined
  _viewer = undefined
  // 编辑的原始marker
  // _marker = undefined
  _markerId = undefined
  // 是否激活
  _actvie = false
  // 编辑的entity
  _viewEntity = undefined
  // 提示entity
  _tipsEntity = undefined
  // 提示文字位置
  #cursorPosition = Cesium.Cartesian3.ZERO
  // 鼠标左键是否按下
  _isPressLeft = false

  constructor (context, marker) {
    this._context = context
    this._viewer = this._context.viewer

    // this._tipsEntity = this._context.viewer.entities.add({
    //   position: new Cesium.CallbackProperty(() => {
    //     return this.#cursorPosition
    //   }, false),
    //   label: {
    //     text: '',
    //     font: '12px 黑体',
    //     pixelOffset: new Cesium.Cartesian2(18, 8),
    //     horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
    //     showBackground: true,
    //     backgroundColor: Cesium.Color.fromCssColorString('#272829'),
    //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
    //     backgroundPadding: new Cesium.Cartesian2(15, 7)
    //   }
    // })

    this._tooltips = new Tooltips(this._context, this._viewer)

    // 注册cesium 事件
    this._eventHandler = new Cesium.ScreenSpaceEventHandler(this._viewer.canvas)
    this._eventHandler.setInputAction((event) => { this.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_CLICK, event) }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    this._eventHandler.setInputAction((event) => { this.onCesiumEvent(Cesium.ScreenSpaceEventType.MOUSE_MOVE, event) }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    this._eventHandler.setInputAction((event) => { this.onCesiumEvent(Cesium.ScreenSpaceEventType.RIGHT_CLICK, event) }, Cesium.ScreenSpaceEventType.RIGHT_CLICK)
    this._eventHandler.setInputAction((event) => { this.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_DOWN, event) }, Cesium.ScreenSpaceEventType.LEFT_DOWN)
    this._eventHandler.setInputAction((event) => { this.onCesiumEvent(Cesium.ScreenSpaceEventType.LEFT_UP, event) }, Cesium.ScreenSpaceEventType.LEFT_UP)

    this.active = true
  }

  get active () {
    return this._actvie
  }

  set active (value) {
    this._actvie = value
  }

  get markerId () {
    return this._markerId
  }

  set markerId (value) {
    this._markerId = value
  }

  get pressLeft () {
    return this._isPressLeft
  }

  set pressLeft (value) {
    this._isPressLeft = value
    this._viewer.scene.screenSpaceCameraController.enableRotate = !value
    this._viewer.container.style.cursor = value ? 'crosshair' : 'default'
  }

  /**
   * 设置样式
   * @param {MarkerStyle[]} styles
   */
  setStyle (styles) {
    for (let i = 0; i < styles.length; i++) {
      const style = styles[i]
      if (style) {
        style.decorate(this._viewEntity)
      } else {
        console.error('setStyle error', style)
      }
    }
  }

  /**
   * 设置提示文字
   * @param {String} text 提示文字
   */
  setTipsText (text) {
    if (this._tipsEntity) {
      this._tipsEntity.label.text = text
    }
    if (this._tooltips) {
      this._tooltips.setText(text)
    }
  }

  flyto () {
    if (this._viewEntity) {
      const geoJson = this._context.utils.entityExportGeoJson(this._viewEntity)
      this._context.cameraManager.zoomToGeometry(JSON.parse(geoJson))
    }
  }

  // cesium事件
  onCesiumEvent (type, event) {
    if (type === Cesium.ScreenSpaceEventType.MOUSE_MOVE) {
      // const position = this._viewer.scene.pickPosition(event.endPosition)
      // if (!position) {
      //   return
      // }
      // this.#cursorPosition = position.clone()
      if (this._tooltips) {
        this._tooltips.setPosition(event.endPosition.x + 18, event.endPosition.y - 8)
      }
    }
  }

  destroy () {
    this.active = false
    if (this._tipsEntity) {
      this._viewer.entities.remove(this._tipsEntity)
      this._tipsEntity = null
    }
    if (this._viewEntity) {
      this._viewer.entities.remove(this._viewEntity)
      this._viewEntity = null
    }
    this._eventHandler = this._eventHandler && this._eventHandler.destroy()

    if (this._tooltips) {
      this._tooltips.destroy()
      this._tooltips = null
    }
  }
}

export default BaseEditor
