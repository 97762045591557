/**
 * 状态栏控件
 */

class StatusBar {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer

    const node = document.createElement('div')
    node.id = 'statueBarView'
    node.style = 'width: 100%; height: 30px; background: rgba(71, 71, 71, 0.8); white-space: nowrap; position: absolute; bottom: 0px; font-size: 14px; color:#FFFFFF; line-height:30px; z-index: 9999; text-align:left;'
    node.innerHTML = '<div id="statueFPSView" style="width:0px; display: inline-block;"></div><div id="statueCameraView" style="display: inline-block;"></div><div id="mousePointView" style="right:0px; position:absolute; display: inline-block;"></div>'

    this._viewerCameraInfoDiv = node
    const container = document.getElementsByClassName('cesium-widget')
    if (container && container[0]) {
      container[0].appendChild(this._viewerCameraInfoDiv)
    } else {
      this.viewer.container.appendChild(this._viewerCameraInfoDiv)
    }
    this._statueCameraView = document.getElementById('statueCameraView')
    this._mousePointView = document.getElementById('mousePointView')

    // 摄像机事件
    this.viewer.camera.changed.addEventListener(this.onCameraChanged, this)
  }

  get viewer () {
    return this._viewer
  }

  // 相机转动事件
  onCameraChanged () {
    if (this._viewerCameraInfoDiv !== undefined && this._viewerCameraInfoDiv.hidden === false) {
      this._statueCameraView.innerHTML = this.formatCameraInfo()
    }
  }

  formatCameraInfo () {
    const position = this._context.cameraManager.getCameraPosition()
    const orientation = this._context.cameraManager.getCameraDirection()
    const formatStr = '相机位置：' + '经度：' + position[0].toFixed(6) + '° ' + '纬度：' + position[1].toFixed(6) + '° ' +
    '高度：' + Math.floor(position[2]).toString() + '米 ' + '偏航角：' + orientation[0].toFixed(2) + '° ' +
    '俯仰角' + orientation[1].toFixed(2) + '° ' + '翻滚角' + orientation[2].toFixed(2) + '° '
    return formatStr
  }

  /**
   * 显示/隐藏状态栏
   * @param {Boolen} isShow 是否显示
   */
  showStatusBar (isShow) {
    if (!this._viewerCameraInfoDiv) {
      return
    }
    if (isShow) {
      this._viewerCameraInfoDiv.style.display = 'block'
    } else {
      this._viewerCameraInfoDiv.style.display = 'none'
    }
  }
}

export default StatusBar
