
/**
 * Canvas 标注拾取
 */
class CanvasPicker {
  constructor (context, viewer) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._viewer = viewer
    this._context = context
    // 创建事件处理类
    this._screenSpaceEventHandler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)
    // 创建canvas
    const c = document.createElement('canvas')
    this._canvas = c
    this._canvas.style.pointerEvents = 'none'
    // 需要相对于父节点，父节点的position属性必须为非static，否则可能出问题
    this._canvas.style = 'position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; pointer-events: none'
    // this._positions = [];
    this._dirty = true
    const ctx = this._canvas.getContext('2d')
    this._listener = viewer.scene.preUpdate.addEventListener(() => {
      if (c.width !== viewer.canvas.width) {
        c.width = viewer.canvas.width
        this._dirty = true
      }

      if (c.height !== viewer.canvas.height) {
        c.height = viewer.canvas.height
        this._dirty = true
      }

      if (this._dirty) {
        this._dirty = false

        // const r = viewer.scene.pixelRatio // pixelRatio = resolutionScale * devicePixelRatio;
        // redraw
        ctx.fillStyle = 'rgba(65, 156, 255, 0.3)'
        ctx.strokeStyle = 'rgb(65, 156, 255)'
        ctx.lineWidth = 2 // 设置线宽

        ctx.clearRect(0, 0, c.width, c.height)
        ctx.beginPath()
        // const ps = this._positions;
        // const psl = ps.length / 2 | 0;
        // for (let i = 0; i < psl; ++i) {
        //   ctx.lineTo(ps[i * 2 + 0] * r, ps[i * 2 + 1] * r);
        // }
        if (this.startPosition && this.endPosition) {
          const x = this.startPosition[0] < this.endPosition[0] ? this.startPosition[0] : this.endPosition[0]
          const y = this.startPosition[1] < this.endPosition[1] ? this.startPosition[1] : this.endPosition[1]
          const width = Math.abs(this.startPosition[0] - this.endPosition[0])
          const height = Math.abs(this.startPosition[1] - this.endPosition[1])
          ctx.rect(x, y, width, height)
          ctx.fill()
          ctx.stroke()
        }
      }
    })
    this.viewer.container.appendChild(this._canvas)
    this._addEscEventListener()

    // 创建event
    this._objectsPickedEvent = new Cesium.Event()
    this._pickEndEvent = new Cesium.Event()
    this._pickStartEvent = new Cesium.Event()

    // 拾取是否可用
    this._enabled = false
    // 开始位置
    this._startPosition = undefined
    // 结束位置
    this._endPosition = undefined
  }

  get viewer () {
    return this._viewer
  }

  /**
   * 开始位置
   */
  get startPosition () {
    return this._startPosition
  }

  set startPosition (v) {
    this._startPosition = v
  }

  /**
   * 结束位置
   */
  get endPosition () {
    return this._endPosition
  }

  set endPosition (v) {
    this._endPosition = v
  }

  get enabled () {
    return this._enabled
  }

  set enabled (value) {
    value = !!value
    if (this._enabled !== value) {
      value ? this._enableAction() : this._disableAction()
      this._enabled = value
    }
  }

  get objectsPickedEvent () {
    return this._objectsPickedEvent
  }

  // cesium 事件消息
  onCesiumEvent (type, event) {

  }

  /**
   * 按下esc, 取消当前绘制
   * @private
   */
  _addEscEventListener () {
    const that = this
    window.addEventListener('keydown', function (event) {
      switch (event.key) {
        case 'Escape':
          if (that.enabled) {
            that.enabled = false
          }
          break
        default:
          return false
      }
    }, true)
  }

  // 启用拾取功能
  _enableAction () {
    this.clear()
    this.viewer.container.style.cursor = 'crosshair'
    this.viewer.scene.screenSpaceCameraController.enableInputs = false
    this._screenSpaceEventHandler.setInputAction(this.leftDownAction.bind(this), Cesium.ScreenSpaceEventType.LEFT_CLICK)
    this._screenSpaceEventHandler.setInputAction(this.mouseMoveAction.bind(this), Cesium.ScreenSpaceEventType.MOUSE_MOVE)
  }

  // 禁用拾取功能
  _disableAction () {
    this.clear()
    this.viewer.container.style.cursor = 'default'
    this.viewer.scene.screenSpaceCameraController.enableInputs = true
    this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
    this._screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)
  }

  // 清除数据
  clear () {
    this.startPosition = undefined
    this.endPosition = undefined
    this._dirty = true
  }

  // 点击事件
  leftDownAction (event) {
    if (!this._enabled) {
      return
    }
    if (!this.startPosition) {
      this._pickStartEvent.raiseEvent()
      this.startPosition = [event.position.x, event.position.y]
    } else {
      this.endPosition = [event.position.x, event.position.y]
      this._dirty = true
      this._pickEndEvent.raiseEvent()
      const markers = this._context.markerManager.pickCanvas(this.startPosition, this.endPosition)
      this._objectsPickedEvent.raiseEvent(markers)
      this.enabled = false
    }
  }

  // 移动事件
  mouseMoveAction (event) {
    if (!this._enabled || !this.startPosition) {
      return
    }
    this.endPosition = [event.endPosition.x, event.endPosition.y]
    this._dirty = true
  }

  destroy () {

  }
}
export default CanvasPicker
