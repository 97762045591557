import MarkerStyle from './MarkerStyle'
/**
 * 多边形标注style类
 */
class PolygonStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'polygon'
    this._material = _options.material ? _options.material : Cesium.Color.fromCssColorString('#1890FF33')
    this._clampToGround = Cesium.defaultValue(_options.clampToGround, true)
    this._outline = Cesium.defaultValue(_options.outline, true)
    this._outlineWidth = Cesium.defaultValue(_options.outlineWidth, 1)
    this._outlineMaterial = Cesium.defaultValue(_options.outlineMaterial, Cesium.Color.fromCssColorString('#1890FF'))
    this._extrudedHeight = Cesium.defaultValue(_options.extrudedHeight, undefined)
    this._heightReference = Cesium.defaultValue(_options.heightReference, Cesium.HeightReference.CLAMP_TO_GROUND)
  }

  decorate (entity) {
    if (!entity.polygon) {
      return
    }
    entity.polygon.material = this._material
    entity.polygon.outline = false
    entity.polygon.extrudedHeight = this._extrudedHeight
    entity.polygon.heightReference = this._heightReference
    entity.polygon.perPositionHeight = !this._clampToGround
    if (entity.polyline) {
      entity.polyline.show = this._outline
      entity.polyline.width = this._outlineWidth
      entity.polyline.material = this._outlineMaterial
      entity.polyline.depthFailMaterial = this._outlineMaterial
    }
  }

  equals (other) {
    return this === other || (
      other instanceof PolygonStyle &&
        this._type === other._type &&
        this._clampToGround === other._clampToGround &&
        this._outline === other._outline &&
        this._outlineWidth === other._outlineWidth &&
        this._extrudedHeight === other._extrudedHeight &&
        this._heightReference === other._heightReference &&
        this._material.equals(other._material) &&
        Cesium.Color.equals(this._outlineMaterial, other._outlineMaterial)
    )
  }

  static Default () {
    return new PolygonStyle({
      material: Cesium.Color.fromCssColorString('#1890FF33'),
      outlineWidth: 4,
      outlineMaterial: Cesium.Color.fromCssColorString('#1890FF')
    })
  }

  static Measure () {
    return new PolygonStyle({
      material: Cesium.Color.fromCssColorString('#FFF2AB00'),
      outlineWidth: 4,
      clampToGround: false,
      outlineMaterial: Cesium.Color.fromCssColorString('#F2AB3F')
    })
  }

  static Selected () {
    return new PolygonStyle({
      material: Cesium.Color.fromCssColorString('#0FF2F580'),
      outlineWidth: 6,
      outlineMaterial: Cesium.Color.fromCssColorString('#1890FF')
    })
  }
}

export default PolygonStyle
