import request from '@/api/request'
const userName = sessionStorage.getItem('userName')

// 获取模型基础数据
export async function getModelBaseData() {
    const res = await request({
        url: '/static/data/base.json',
        method: 'get'
    })
    return res[userName]['modelOptions']
}

// 获取监控点位信息
export async function getLivePoints() {
    const res = await request({
        url: '/static/data/base.json',
        method: 'get'
    })
    return res[userName]['livePointList']
}

// 获取农场点位
export async function getFarmPoints() {
    const res = await request({
        url: '/static/data/base.json',
        method: 'get'
    })
    return res[userName]['farmPoints']
}