/**
 * 边缘发光多边形
 * @param {Canvas} [options.image] 滚动纹理
 * @param {Color} [options.rimColor] 发光颜色
 * @param {Number} [options.width] 一张图片宽度
 * @param {Number} [options.length] 图形长度
 */
function PolygonRimLightMaterialProperty (options) {
  this._definitionChanged = new Cesium.Event()

  const _options = Cesium.defaultValue(options, {})
  this._innerColor = Cesium.defaultValue(_options.innerColor, Cesium.Color.fromCssColorString('#88faff'))
  this._rimColor = Cesium.defaultValue(_options.rimColor, Cesium.Color.WHITE)
  this._rimWidth = Cesium.defaultValue(_options.rimWidth, 0.1)
}

/**
 * 绑定Cesium消息
 */
Object.defineProperties(PolygonRimLightMaterialProperty.prototype, {
  isConstant: {
    get: function () {
      return false
    }
  },
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
  color: Cesium.createPropertyDescriptor('color')
})

/**
 * 返回类型
 */
PolygonRimLightMaterialProperty.prototype.getType = function (time) {
  return Cesium.Material.PolygonRimLightMaterialType
}

PolygonRimLightMaterialProperty.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof PolygonRimLightMaterialProperty &&
      Cesium.Property.equals(this._innerColor, other._innerColor) &&
      Cesium.Property.equals(this._rimColor, other._rimColor) &&
      Cesium.Property.equals(this._rimWidth, other._rimWidth))
  )
}

/**
 * 给定时间获取时间属性返回值 由Cesium中Material调用
 * 功能描述：实现颜色改变
 * @param {JulianDate} time Cesium内置时间
 * @param {material} result  Cesium内置材质
 */
PolygonRimLightMaterialProperty.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }

  result.image = this._image
  result.innerColor = this._innerColor
  result.rimColor = this._rimColor
  result.rimWidth = this._rimWidth
  return result
}

// Cesium.PolygonRimLightMaterialProperty = PolygonRimLightMaterialProperty;
Cesium.Material.PolygonRimLightMaterialType = 'PolygonRimLightMaterial'
Cesium.Material.PolygonRimLightMaterialSource =
  `czm_material czm_getMaterial(czm_materialInput materialInput)
 {
    czm_material material = czm_getDefaultMaterial(materialInput);
    vec2 st = materialInput.st;
    float rimX = clamp(0.0, 1.0, abs(st.s - 0.5)-(1.0-rimWidth)/2.0);
    float rimY = clamp(0.0, 1.0, abs(st.t - 0.5)-(1.0-rimWidth)/2.0);
    float edge = max(rimX,rimY) * 10.0;
    vec4 emissionColor = edge * rimColor;
    vec4 diffuseColor = (1.0-edge) * innerColor;
    
    material.alpha = edge;
    material.emission = emissionColor.rgb;
    material.diffuse = diffuseColor.rgb;
    return material;
 }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.PolygonRimLightMaterialType, {
  fabric: {
    type: Cesium.Material.PolygonRimLightMaterialType,
    uniforms: {
      innerColor: Cesium.Color.fromCssColorString('#88faff'),
      rimColor: Cesium.Color.WHITE,
      rimWidth: 0.2
    },
    source: Cesium.Material.PolygonRimLightMaterialSource
  },

  translucent: function (material) {
    return true
  }
})

Cesium.PolygonRimLightMaterialProperty = PolygonRimLightMaterialProperty
