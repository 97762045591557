import DrawMode from './DrawMode' // 绘制模式
import ClampMode from '../Common/ClampMode' // 高度模式
import CustomEvent from '../Common/CustomEvent'
import * as turf from '@turf/turf'
import Tooltips from '../Widget/Tooltips'

const LEFT_CLICK = Cesium.ScreenSpaceEventType.LEFT_CLICK // 左键单击
const RIGHT_CLICK = Cesium.ScreenSpaceEventType.RIGHT_CLICK // 右键单击
const MOUSE_MOVE = Cesium.ScreenSpaceEventType.MOUSE_MOVE // 鼠标移动
const LEFT_DOUBLE_CLICK = Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK // 左键双击
/**
 * 鼠标绘制对象类
 */
class DrawHandler {
  constructor (context, viewer, options) {
    if (!(viewer instanceof Cesium.Viewer)) {
      throw new Error('Viewer 不是一个标准的Cesium Viewer')
    }
    this._viewer = viewer
    this._context = context

    // 是否在绘制状态
    this._isDrawState = false
    this._clampMode = ClampMode.Ground
    this._drawMode = undefined
    this._showMousePoint = false
    // 样式列表
    this._styleList = []

    this._activeEvt = new Cesium.Event()
    this._movingEvt = new Cesium.Event()
    this._drawEvt = new Cesium.Event()
    this._drawStartCall = undefined
    this._drawEndCall = undefined
    this._drawUpdateCall = undefined
    this._escCall = undefined
    this._cesiumHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas) // cesium原生场景鼠标事件
    this._entity = null // 记录当前绘制的对象
    this._positions = []
    this._cursorPosition = Cesium.Cartesian3.ZERO
    this._tipsEntity = undefined // 提示信息entity
    this._properties = undefined // entity自定义属性
    this._mousePointStyle = options.mousePointStyle || {
      color: Cesium.Color.YELLOW,
      pixelSize: 10,
      outlineColor: Cesium.Color.WHITE,
      outlineWidth: 2
    }
    this._mousePoint = this.viewer.entities.add(new Cesium.Entity({
      point: this.mousePointStyle,
      show: this.showMousePoint
    }))

    this._tooltips = new Tooltips(this._context, this._viewer)

    this._lineCallBackProperty = new Cesium.CallbackProperty(() => {
      return false
    }, false) // cesium原生回调，clock每帧都会触发
    this._regionCallBackProperty = new Cesium.CallbackProperty(() => {
      return false
    }, false) // cesium原生回调，clock每帧都会触发
    this._wallCallBackProperty = new Cesium.CallbackProperty(() => {
      return false
    }, false) // cesium原生回调，clock每帧都会触发
    this._addEscEventListener()
  }

  /**
   * 样式列表
   */
  get styleList () {
    return this._styleList
  }

  set styleList (v) {
    this._styleList = v
  }

  /**
   * 绘制对象的高度模式
   * @returns {number}
   */
  get clampMode () {
    return this._clampMode
  }

  set clampMode (v) {
    this._clampMode = v
  }

  get isDrawState () {
    return this._isDrawState
  }

  set isDrawState (v) {
    this._isDrawState = v
    this._context.dispatch(CustomEvent.DrawState, this._isDrawState)
  }

  /**
   * entity自定义属性
   * @returns {Object}
   */
  get properties () {
    return this._properties
  }

  set properties (v) {
    this._properties = v
  }

  /**
   * 绘制对象类型
   * @returns {*}
   */
  get drawMode () {
    return this._drawMode
  }

  set drawMode (v) {
    if (this._entity) {
      this.viewer.entities.remove(this._entity)
    }
    this._entity = null
    this.positions = []
    this._drawMode = v
  }

  /**
   * 绘制类激活事件，可用于修改鼠标样式，显示tooltip等
   */
  get activeEvt () {
    return this._activeEvt
  }

  get viewer () {
    return this._viewer
  }

  /**
   * 绘制对象移动事件，抛出鼠标位置和此时绘制的对象
   */
  get movingEvt () {
    return this._movingEvt
  }

  /**
   * 对象绘制结束事件，抛出鼠标右键此时位置和绘制的对象
   */
  get drawEvt () {
    return this._drawEvt
  }

  /**
   * 鼠标跟随点的样式
   * @returns {*|{color, outlineWidth: number, outlineColor, pixelSize: number}}
   */
  get mousePointStyle () {
    return this._mousePointStyle
  }

  set mousePointStyle (v) {
    this._mousePointStyle = v
  }

  /**
   * 跟随鼠标的点
   * @returns {*}
   */
  get mousePoint () {
    return this._mousePoint
  }

  /**
   * 是否显示跟随鼠标的点
   * @returns {boolean}
   */
  get showMousePoint () {
    return this._showMousePoint
  }

  set showMousePoint (v) {
    this.mousePoint.show = v
    this._showMousePoint = v
  }

  /**
   * 记录线，面对象绘制的坐标
   * @returns {[]}
   */
  get positions () {
    return this._positions
  }

  set positions (v) {
    this._positions = v
  }

  /**
   * 按下esc, 取消当前绘制
   * @private
   */
  _addEscEventListener () {
    const that = this
    window.addEventListener('keydown', event => {
      switch (event.key) {
        case 'Escape':
          if (!this._isDrawState) {
            return
          }
          if (that._entity) {
            that.viewer.entities.remove(that._entity)
          }
          if (this._escCall) {
            this._escCall()
          }
          that.viewer.trackedEntity = undefined // 取消追踪实体对象
          that._entity = null
          that.positions = []
          that.deactivate()
          break
        default:
          return false
      }
    }, true)
  }

  /**
   * 鼠标左键点击事件
   * @param e
   */
  leftClick (e) {
    // 屏幕坐标转笛卡尔坐标
    let position = this.viewer.scene.pickPosition(e.position)
    if (!position) {
      const ray = this.viewer.scene.camera.getPickRay(e.position)
      if (ray) {
        position = this.viewer.scene.globe.pick(ray, this.viewer.scene)
      }
    }
    // 处理点击到天空时的情况
    if (!position) {
      return
    }
    if (!this._entity) {
      switch (this.drawMode) {
        case DrawMode.Point:
          this.createPointOrMarker(position)
          break
        case DrawMode.Polyline:
          this.createPolyline(position)
          break
        case DrawMode.Polygon:
          this.createPolygon(position)
          break
        case DrawMode.Marker:
          this.createPointOrMarker(position)
          break
        case DrawMode.Wall:
          this.createWall(position)
          break
        case DrawMode.Circle:
          this.createCircle(position)
          break
        case DrawMode.Segment:
          this.createPolyline(position)
          break
        case DrawMode.Curve:
          this.createPolyline(position)
          break
      }
      if (this._entity) {
        for (let i = 0; i < this._styleList.length; i++) {
          this._styleList[i].decorate(this._entity)
        }
      }
      return
    }
    if (this.drawMode === DrawMode.Polyline ||
        this.drawMode === DrawMode.Polygon ||
        this.drawMode === DrawMode.Wall ||
        this.drawMode === DrawMode.Curve) {
      this.positions.push(position)
    }
  }

  /**
   * 鼠标移动事件
   * @param e
   */
  move (e) {
    // 屏幕坐标转笛卡尔坐标
    const position = this.viewer.scene.pickPosition(e.endPosition)
    // 处理点击到天空时的情况
    if (!position) {
      return
    }
    this._cursorPosition = position.clone()
    if (this._tooltips) {
      this._tooltips.setPosition(e.endPosition.x + 9, e.endPosition.y + 114)
    }

    // 抛出鼠标移动时的位置和此时绘制的对象
    this.movingEvt.raiseEvent(position, this._entity)
    // 修改鼠标跟随点的位置
    this.mousePoint.position = position
    switch (this.drawMode) {
      case DrawMode.Polyline:
        if (this._entity) {
          this.updatePolyline(position)
        }
        break
      case DrawMode.Polygon:
        if (this._entity) {
          this.updatePolygon(position)
        }
        break
      case DrawMode.Wall:
        if (this._entity) {
          this.updateWall(position)
        }
        break
      case DrawMode.Circle:
        if (this._entity) {
          this.updateCircle(position)
        }
        break
      case DrawMode.Segment:
        if (this._entity) {
          this.updatePolyline(position)
        }
        break
      case DrawMode.Curve:
        if (this._entity) {
          this.updateCurve(position)
        }
        break
    }
    if (this._drawUpdateCall && this._entity) {
      this._drawUpdateCall(position, this._entity)
    }
  }

  /**
   * 鼠标右键事件，线，面移除上一个点
   * @param e
   */
  removePoint (e) {
    if (this.drawMode === DrawMode.Polyline || this.drawMode === DrawMode.Polygon || this.drawMode === DrawMode.Curve) {
      if (this.positions.length >= 3) {
        // this.positions.pop()
        this.positions.splice(this.positions.length - 2, 1)
      } else {
        this.viewer.entities.remove(this._entity)
        this._entity = null
        this.positions = []
      }
    }
  }

  /**
   * 鼠标右键事件，用于判断线，面绘制对象结束
   * @param e
   */
  finishClick (e) {
    // 屏幕坐标转笛卡尔坐标
    const position = this.viewer.scene.pickPosition(e.position)
    // 处理点击到天空时的情况
    if (!position) {
      return
    }
    switch (this.drawMode) {
      case DrawMode.Point:
      case DrawMode.Marker:
        this.drawEvt.raiseEvent(position, undefined)
        if (this._drawEndCall) {
          this._drawEndCall(position, undefined)
        }
        break
      case DrawMode.Polyline:
        this.finishPolyline(position)
        break
      case DrawMode.Polygon:
        this.finishPolygon(position)
        break
      case DrawMode.Wall:
        this.finishWall(position)
        break
      case DrawMode.Circle:
        this.finishCircle(position)
        break
      case DrawMode.Segment:
        this.finishPolyline(position)
        break
      case DrawMode.Curve:
        this.finishCurve(position)
        break
    }
    this.deactivate()
  }

  /**
   * 绘制point类型对象
   * @param e
   */
  createPointOrMarker (position) {
    // 点默认高度为绝对高度
    let heightReference = Cesium.HeightReference.NONE
    // 当高度模式指定为贴地时，设置贴地模式
    if (this.clampMode === ClampMode.Ground) {
      heightReference = Cesium.HeightReference.CLAMP_TO_GROUND
    }
    const entity = this.drawMode === 0 ? new Cesium.Entity({
      point: {
        heightReference: heightReference
      },
      position: position
    }) : new Cesium.Entity({
      billboard: {
        heightReference: heightReference
      },
      position: position,
      properties: this.properties
    })
    this.viewer.entities.add(entity)
    for (let i = 0; i < this._styleList.length; i++) {
      this._styleList[i].decorate(entity)
    }
    // 抛出点绘制完成
    this.drawEvt.raiseEvent(position, entity)
    if (this._drawEndCall) {
      this._drawEndCall(position, entity)
    }
    this.deactivate()
  }

  /**
   * 绘制polyline类型对象
   */
  createPolyline (position) {
    if (this.drawMode === DrawMode.Segment && this.positions.length >= 1) { // 线段只绘制两个点
      return
    }
    // 根据设置的高度模式，设置线贴地，绝对高度，贴模型，贴地和贴模型
    const type = this.getClampType()
    this._entity = this.viewer.entities.add(new Cesium.Entity({
      polyline: {
        clampToGround: type.clampToGround,
        classificationType: type.classificationType
      },
      properties: this.properties
    }))
    this.positions.push(position)
  }

  /**
   * 绘制面对象，面对象不使用outline，直接使用闭合的polyline作为边线
   * @param position
   */
  createPolygon (position) {
    // 根据设置的高度模式，设置线贴地，绝对高度，贴模型，贴地和贴模型
    const type = this.getClampType()
    this._entity = this.viewer.entities.add(new Cesium.Entity({
      polyline: {
        material: Cesium.Color.fromCssColorString('#1890FF'),
        depthFailMaterial: Cesium.Color.fromCssColorString('#1890FF'),
        width: 4,
        clampToGround: type.clampToGround,
        classificationType: type.classificationType
      },
      polygon: {
        material: Cesium.Color.fromCssColorString('#1890FF33'),
        perPositionHeight: !type.clampToGround,
        clampToGround: type.clampToGround,
        classificationType: type.classificationType
      },
      properties: this.properties
    }))
    this.positions.push(position)
  }

  /**
   * 创建围墙对象
   * @param position
   */
  createWall (position) {
    const image = this._context.utils.getColorRamp('rgb(255,255,255)')
    const wallMaterial = new Cesium.ImageMaterialProperty({
      image: image,
      color: Cesium.Color.WHITE,
      transparent: true
    })
    this._entity = this.viewer.entities.add(new Cesium.Entity({
      wall: {
        material: wallMaterial
      }
    }))
    this.positions.push(position)
  }

  /**
   * 绘制圆
   * @param position
   */
  createCircle (position) {
    const type = this.getClampType()
    this._entity = this.viewer.entities.add(new Cesium.Entity({
      polyline: {
        clampToGround: type.clampToGround,
        classificationType: type.classificationType
      },
      polygon: {
        perPositionHeight: !type.clampToGround,
        clampToGround: type.clampToGround,
        classificationType: type.classificationType
      },
      label: {
        text: '',
        font: '12px 黑体',
        pixelOffset: new Cesium.Cartesian2(8, 8), // y大小根据行数和字体大小改变
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        showBackground: true,
        backgroundColor: Cesium.Color.fromCssColorString('#272829'),
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        backgroundPadding: new Cesium.Cartesian2(15, 7)

        // pixelOffset: new Cesium.Cartesian2(0, -50),
        // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        // verticalOrigin: Cesium.HorizontalOrigin.CENTER,
        // showBackground: false,
        // fillColor: Cesium.Color.fromCssColorString('#FFFFFF'),
        // outlineColor: Cesium.Color.fromCssColorString('#000000'),
        // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        // heightReference: Cesium.HeightReference.NONE,
        // disableDepthTestDistance: Number.POSITIVE_INFINITY,
        // outlineWidth: 30
      },
      properties: this.properties
    }))

    this._entity.position = position
    // let centerPos = this._entity.position.getValue(Cesium.JulianDate.now())
    const positions = this._context.utils.getEllipsePositions({
      centerPosition: position,
      radius: 0.1
    })
    this.positions = [].concat(positions)
  }

  /**
   * 更新线对象，isClose表示线是否闭合
   * @param position
   * @param isClose
   */
  updatePolyline (position, isClose) {
    if (this.positions.length === 1) {
      this.positions.push(position)
      // 使用callback回调来处理线节点，使连线效果更加柔滑
      this._lineCallBackProperty.setCallback(() => {
        if (isClose) {
          return this.positions.concat([this.positions[0]])
        }
        return this.positions
      }, false)
      this._entity.polyline.positions = this._lineCallBackProperty
    } else {
      this.positions.pop()
      this.positions.push(position)
    }
  }

  /**
   * 计算线条曲线
   * @param position 线段Cartesian3坐标
   * @return 曲线Cartesian3坐标
   */
  _calculateCurve (position) {
    const degreePoints = []
    position.forEach(item => {
      const point = this._context.utils.cartesianToLonLat(item)
      degreePoints.push([
        point.longitude,
        point.latitude
      ])
    })
    const line = turf.lineString(degreePoints)
    const curved = turf.bezierSpline(line)
    // {
    //   resolution: 10000,
    //   sharpness: 0.85
    // }
    const curveGid = curved.geometry.coordinates
    const curvePositions = []
    curveGid.forEach(item => {
      curvePositions.push(item[0])
      curvePositions.push(item[1])
      curvePositions.push(0)
    })
    return Cesium.Cartesian3.fromDegreesArrayHeights(curvePositions)
  }

  /**
   * 更新曲线对象，isClose表示线是否闭合
   * @param position
   * @param isClose
   */
  updateCurve (position, isClose) {
    if (this.positions.length === 1) {
      this.positions.push(position)
      // 使用callback回调来处理线节点，使连线效果更加柔滑
      this._lineCallBackProperty.setCallback(() => {
        if (isClose) {
          this.positions.concat([this.positions[0]])
        }
        return this._calculateCurve(this.positions)
      }, false)
      this._entity.polyline.positions = this._lineCallBackProperty
    } else {
      this.positions.pop()
      this.positions.push(position)
    }
  }

  /**
   * 更新线对象
   * @param position
   */
  updatePolygon (position) {
    this.updatePolyline(position, true)
    if (this.positions.length === 3) {
      this._regionCallBackProperty.setCallback(() => {
        return new Cesium.PolygonHierarchy(this.positions)
      }, false)
      this._entity.polygon.hierarchy = this._regionCallBackProperty
    }
  }

  /**
   * 更新围墙对象
   * @param position
   */
  updateWall (position) {
    if (this.positions.length === 1) {
      this.positions.push(position)
      // 使用callback回调来处理线节点，使连线效果更加柔滑
      this._wallCallBackProperty.setCallback(() => {
        const wallMaxMinHeight = this._context.utils.calMaxMinHeights(this.positions, 10)
        this._entity.wall.minimumHeights = wallMaxMinHeight.minimumHeights
        this._entity.wall.maximumHeights = wallMaxMinHeight.maximumHeights
        return this.positions
      }, false)
      this._entity.wall.positions = this._wallCallBackProperty
    } else {
      this.positions.pop()
      this.positions.push(position)
    }
  }

  /**
   * 更新圆对象
   * @param position
   */
  updateCircle (position) {
    if (this._entity.position) {
      // 计算半径
      const centerPos = this._entity.position.getValue(Cesium.JulianDate.now())
      const distance = Cesium.Cartesian3.distance(position, centerPos)

      // 计算圆坐标
      const positions = this._context.utils.getEllipsePositions({
        centerPosition: centerPos,
        radius: distance || 0.01
      })
      this.positions = [].concat(positions)

      // 更新线面
      this._lineCallBackProperty.setCallback(() => {
        return this.positions.concat([this.positions[0]])
      }, false)
      this._entity.polyline.positions = this._lineCallBackProperty
      this._regionCallBackProperty.setCallback(() => {
        return new Cesium.PolygonHierarchy(this.positions)
      }, false)
      this._entity.polygon.hierarchy = this._regionCallBackProperty
      this._entity.label.text = '半径：' + this._context.utils.distanceToString(distance)
      const gisPos = this._context.utils.cartesianToLonLat(centerPos)
      if (this._entity.properties.hasProperty('center_position_')) {
        this._entity.properties.removeProperty('center_position_')
      }
      this._entity.properties.addProperty('center_position_', [gisPos.longitude, gisPos.latitude, gisPos.height])
      if (this._entity.properties.hasProperty('radius_')) {
        this._entity.properties.removeProperty('radius_')
      }
      this._entity.properties.addProperty('radius_', distance)
      // this._entity.properties.center_position_ = [gisPos.longitude, gisPos.latitude, gisPos.height]
      // this._entity.properties.radius = distance
    }
  }

  /**
   * 线对象结束绘制
   * @param position
   */
  finishPolyline (position) {
    if (!this._entity) {
      this.positions = []
      this.drawEvt.raiseEvent(this.positions, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, undefined)
      }
      return
    }
    if (this.drawMode === DrawMode.Polyline) {
      this.positions.pop()
      // 双击，移除两个点
      this.positions.pop()
    }

    this._entity.polyline.positions = this.positions
    if (this.positions.length > 1) {
      this.drawEvt.raiseEvent(this.positions, this._entity)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, this._entity)
      }
    } else {
      this.viewer.entities.remove(this._entity)
      if (this._escCall) {
        this._escCall()
      }
    }
    this._entity = null
    this.positions = []
  }

  /**
   * 区线对象结束绘制
   * @param position
   */
  finishCurve (position) {
    if (!this._entity) {
      this.positions = []
      this.drawEvt.raiseEvent(this.positions, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, undefined)
      }
      return
    }
    this.positions.pop()
    // 双击，移除两个点
    this.positions.pop()

    this._entity.polyline.positions = this.positions
    if (this.positions.length > 1) {
      this.drawEvt.raiseEvent(this.positions, this._entity)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, this._entity)
      }
    } else {
      this.viewer.entities.remove(this._entity)
      if (this._escCall) {
        this._escCall()
      }
    }
    this._entity = null
    this.positions = []
  }

  /**
   * 结束绘制面对象
   * @param position
   */
  finishPolygon (position) {
    if (!this._entity) {
      this.positions = []
      this.drawEvt.raiseEvent(this.positions, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, undefined)
      }
      return
    }
    this.positions.pop()
    // 双击，移除两个点
    this.positions.pop()
    this._entity.polyline.positions = this.positions.concat([this.positions[0]])
    this._entity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.positions)
    if (this.positions.length > 2) {
      this.drawEvt.raiseEvent(this.positions, this._entity)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, this._entity)
      }
    } else {
      this.viewer.entities.remove(this._entity)
      if (this._escCall) {
        this._escCall()
      }
    }
    this._entity = null
    this.positions = []
  }

  /**
   * 围墙结束绘制
   * @param position
   */
  finishWall (position) {
    if (!this._entity) {
      this.positions = []
      this.drawEvt.raiseEvent(this.positions, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, undefined)
      }
      return
    }
    this.positions.pop()
    // 双击，移除两个点
    this.positions.pop()
    this._entity.wall.positions = this.positions
    const wallMaxMinHeight = this._context.utils.calMaxMinHeights(this.positions, 10)
    this._entity.wall.minimumHeights = wallMaxMinHeight.minimumHeights
    this._entity.wall.maximumHeights = wallMaxMinHeight.maximumHeights
    if (this.positions.length > 1) {
      this.drawEvt.raiseEvent(this.positions, this._entity)
      if (this._drawEndCall) {
        this._drawEndCall(this.positions, this._entity)
      }
    } else {
      this.viewer.entities.remove(this._entity)
    }
    this._entity = null
    this.positions = []
  }

  /**
   * 围墙圆绘制
   * @param position
   */
  finishCircle (position) {
    if (!this._entity) {
      this.positions = []
      this.drawEvt.raiseEvent(position, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(position, undefined)
      }
      return
    }

    const centerPos = this._entity.position.getValue(Cesium.JulianDate.now())
    const distance = Cesium.Cartesian3.distance(position, centerPos)
    if (distance <= 0) {
      this.viewer.entities.remove(this._entity)
      this._entity = undefined
      this.positions = []
      this.drawEvt.raiseEvent(position, undefined)
      if (this._drawEndCall) {
        this._drawEndCall(position, undefined)
      }
      return
    }
    const gisPos = this._context.utils.cartesianToLonLat(centerPos)
    if (!this._entity.properties.hasProperty('center_position_')) {
      this._entity.properties.addProperty('center_position_', [gisPos.longitude, gisPos.latitude, gisPos.height])
    }
    if (!this._entity.properties.hasProperty('radius_')) {
      this._entity.properties.addProperty('radius_', distance)
    }

    this._entity.label = null
    this._entity.polyline.positions = this.positions.concat([this.positions[0]])
    this._entity.polygon.hierarchy = new Cesium.PolygonHierarchy(this.positions)
    if (this.positions.length > 2) {
      this.drawEvt.raiseEvent(position, this._entity)
      if (this._drawEndCall) {
        this._drawEndCall(position, this._entity)
      }
    } else {
      this.viewer.entities.remove(this._entity)
      if (this._escCall) {
        this._escCall()
      }
    }
    this._entity = null
    this.positions = []
  }

  /**
   * 根据设置获取高度模式
   * @returns {{classificationType: null, clampToGround: boolean}}
   */
  getClampType () {
    let classificationType
    let clampToGround = true
    switch (this.clampMode) {
      case ClampMode.Space:
        clampToGround = false
        break
      case ClampMode.Ground:
        classificationType = Cesium.ClassificationType.TERRAIN
        break
      case ClampMode.Model:
        classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
        break
      case ClampMode.GroundAndModel:
        classificationType = Cesium.ClassificationType.BOTH
        break
    }
    return { classificationType, clampToGround }
  }

  /**
   * 激活注册鼠标事件
   */
  activate () {
    if (this._entity) {
      this.viewer.entities.remove(this._entity)
    }
    this.isDrawState = true
    this._entity = null
    this.positions = []
    this._activeEvt.raiseEvent(true)
    this._context.canvasZoom.enabled = true
    this.viewer.container.style.cursor = 'crosshair'
    if (this.drawMode === DrawMode.Point || this.drawMode === DrawMode.Marker) {
      this.showTips('点击鼠标左键进行绘制')
    } else if (this.drawMode === DrawMode.Polygon || this.drawMode === DrawMode.Polyline || this.drawMode === DrawMode.Polygon) {
      this.showTips('单击鼠标左键添加点<br>单击右键删除点<br>双击左键结束绘制')
    } else {
      this.showTips('点击鼠标左键进行绘制<br>双击鼠标左键结束绘制')
    }
    this._cesiumHandler.setInputAction(this.leftClick.bind(this), LEFT_CLICK)
    this._cesiumHandler.setInputAction(this.move.bind(this), MOUSE_MOVE)
    // this._cesiumHandler.setInputAction(this.finishClick.bind(this), RIGHT_CLICK)
    this._cesiumHandler.setInputAction(this.removePoint.bind(this), RIGHT_CLICK)
    this._cesiumHandler.setInputAction(this.finishClick.bind(this), LEFT_DOUBLE_CLICK)
  }

  /**
   * 注销鼠标事件
   */
  deactivate () {
    this._context.canvasZoom.enabled = false
    this._activeEvt.raiseEvent(false)
    this.viewer.container.style.cursor = 'default'
    this.hideTips()
    this._cesiumHandler.removeInputAction(LEFT_CLICK)
    this._cesiumHandler.removeInputAction(MOUSE_MOVE)
    // this._cesiumHandler.removeInputAction(RIGHT_CLICK)
    this._cesiumHandler.removeInputAction(LEFT_DOUBLE_CLICK)
    this.mousePoint.position = new Cesium.Cartesian3(0, 0, 0)

    this.isDrawState = false
    // if (this._entity) {
    //   this.viewer.entities.remove(this._entity)
    // }
    // this._entity = null
    // this.positions = []
  }

  /**
   * 显示标绘提示信息
   * @param {String} text 提示信息
   */
  showTips (text) {
    // if (!this._cursorPosition) { return }
    // if (!this._tipsEntity) {
    //   const self = this
    //   this._tipsEntity = this.viewer.entities.add({
    //     position: new Cesium.CallbackProperty(() => {
    //       return self._cursorPosition
    //     }, false),
    //     label: {
    //       text: '',
    //       font: '12px 黑体',
    //       pixelOffset: new Cesium.Cartesian2(8, 8), // y大小根据行数和字体大小改变
    //       horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
    //       showBackground: true,
    //       backgroundColor: Cesium.Color.fromCssColorString('#272829'),
    //       disableDepthTestDistance: Number.POSITIVE_INFINITY,
    //       backgroundPadding: new Cesium.Cartesian2(15, 7)
    //     }
    //   })
    // }
    // this._tipsEntity.label.text = text
    if (this._tooltips) {
      this._tooltips.setText(text)
    }
  }

  hideTips () {
    if (this._tipsEntity) {
      this.viewer.entities.remove(this._tipsEntity)
      this._tipsEntity = undefined
    }
    if (this._tooltips) {
      this._tooltips.setText('')
    }
  }

  // 退出绘制状态
  quitDraw () {
    if (!this._isDrawState) {
      return
    }
    if (this._entity) {
      this.viewer.entities.remove(this._entity)
    }
    if (this._escCall) {
      this._escCall()
    }
    this.viewer.trackedEntity = undefined // 取消追踪实体对象
    this._entity = null
    this.positions = []
    this.deactivate()
  }

  /**
   * 绘制标注
   * @param {DrawMode} type 绘制类型
   * @param {Object} options 绘制选项
   */
  draw (type, options) {
    this.deactivate()
    this.drawMode = type
    const _options = Cesium.defaultValue(options, {})
    this.clampMode = Cesium.defaultValue(_options.clampMode, ClampMode.GroundAndModel)
    this._markerOptions = Cesium.defaultValue(_options.markerOptions, {})
    this.styleList = Cesium.defaultValue(_options.styleList, [])
    this._drawStartCall = Cesium.defaultValue(_options.drawStartCall, undefined)
    this._drawEndCall = Cesium.defaultValue(_options.drawEndCall, undefined)
    this._drawUpdateCall = Cesium.defaultValue(_options.drawUpdateCall, undefined)
    this._escCall = Cesium.defaultValue(_options.escCall, undefined)
    this.properties = Cesium.defaultValue(_options.properties, {})
    this.activate()
    if (this._drawStartCall) {
      this._drawStartCall()
    }
    return this.quitDraw.bind(this)
  }

  // cesium 事件消息
  onCesiumEvent (type, event) {

  }

  destroy () {

  }
}
export default DrawHandler
