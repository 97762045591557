/**
 * 圆标注编辑类
 */
import BaseEditor from './BaseEditor'
import DrawMode from '../../DrawHandler/DrawMode'
import CustomEvent from '../../Common/CustomEvent'

class CircleEditor extends BaseEditor {
  // 圆坐标信息
  #gisPosition = {
    centerPosition: undefined,
    radius: 0
  }

  // 选中节点类型 1圆心，2边界节点
  #selectedNodeType = -1

  // 中心节点
  #centerNodeEntity = undefined
  // 边界节点
  #borderNodeEntity = undefined

  constructor (context, gisPosition) {
    super(context)
    this._type = DrawMode.Circle
    this.#gisPosition = gisPosition

    function _updatePolygonPositions () {
      const centerCartesian3 = Cesium.Cartesian3.fromDegrees(this.#gisPosition.centerPosition[0], this.#gisPosition.centerPosition[1], this.#gisPosition.centerPosition[2] ? this.#gisPosition.centerPosition[2] : 0)
      const _position = this._context.utils.getEllipsePositions({
        centerPosition: centerCartesian3,
        radius: this.#gisPosition.radius
      })
      return new Cesium.PolygonHierarchy(_position)
    }
    function _updatePolylinePositions () {
      const centerCartesian3 = Cesium.Cartesian3.fromDegrees(this.#gisPosition.centerPosition[0], this.#gisPosition.centerPosition[1], this.#gisPosition.centerPosition[2] ? this.#gisPosition.centerPosition[2] : 0)
      const _position = this._context.utils.getEllipsePositions({
        centerPosition: centerCartesian3,
        radius: this.#gisPosition.radius
      })
      return [].concat(_position, _position[0])
    }

    this._viewEntity = this._context.viewer.entities.add({
      polygon: {
        hierarchy: new Cesium.CallbackProperty(_updatePolygonPositions.bind(this), false),
        material: Cesium.Color.fromCssColorString('#0FF2F580'),
        outline: false
      },
      polyline: {
        positions: new Cesium.CallbackProperty(_updatePolylinePositions.bind(this), false),
        material: Cesium.Color.fromCssColorString('#1890FF'),
        width: 4,
        clampToGround: true,
        zIndex: -1
      }
    })

    const centerCartesian3 = Cesium.Cartesian3.fromDegrees(this.#gisPosition.centerPosition[0], this.#gisPosition.centerPosition[1], this.#gisPosition.centerPosition[2] ? this.#gisPosition.centerPosition[2] : 0)
    this.#centerNodeEntity = this._context.viewer.entities.add({
      position: this._viewer.scene.clampToHeight(centerCartesian3),
      point: {
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        pixelSize: 12,
        outlineWidth: 1,
        color: Cesium.Color.fromCssColorString('#F54949'),
        outlineColor: Cesium.Color.fromCssColorString('#ffffff')
      }
    })
    this.#borderNodeEntity = this._context.viewer.entities.add({
      position: this._viewer.scene.clampToHeight(this._viewEntity.polyline.positions.getValue(Cesium.JulianDate.now())[0]),
      point: {
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        pixelSize: 12,
        outlineWidth: 1,
        color: Cesium.Color.fromCssColorString('#222222'),
        outlineColor: Cesium.Color.fromCssColorString('#bbbbbb')
      }
    })
  }

  // 圆心改变
  _changeCenterPosition (position) {
    this.#centerNodeEntity.position = this._viewer.scene.clampToHeight(position)
    const _pos = this._context.utils.cartesianToLonLat(position)
    this.#gisPosition.centerPosition = [_pos.longitude, _pos.latitude, _pos.height]
    this.#borderNodeEntity.position = this._viewer.scene.clampToHeight(this._viewEntity.polyline.positions.getValue(Cesium.JulianDate.now())[0])
  }

  // 半径改变
  _chagnRadius (position) {
    // this.#borderNodeEntity.position = position
    const _centerPos = Cesium.Cartesian3.fromDegrees(this.#gisPosition.centerPosition[0], this.#gisPosition.centerPosition[1], this.#gisPosition.centerPosition[2] ? this.#gisPosition.centerPosition[2] : 0)
    this.#gisPosition.radius = Cesium.Cartesian3.distance(_centerPos, position)
    this.#borderNodeEntity.position = this._viewer.scene.clampToHeight(this._viewEntity.polyline.positions.getValue(Cesium.JulianDate.now())[0])
  }

  // cesium事件
  onCesiumEvent (type, event) {
    if (!this._actvie) {
      return
    }
    if (type === Cesium.ScreenSpaceEventType.LEFT_DOWN) {
      const object = this._context.viewer.scene.pick(event.position)
      if (object !== undefined && object.id !== undefined && object.id instanceof Cesium.Entity) {
        if (object.id.id === this.#centerNodeEntity.id) {
          this.#selectedNodeType = 1
          this.pressLeft = true
        } else if (object.id.id === this.#borderNodeEntity.id) {
          this.#selectedNodeType = 2
          this.pressLeft = true
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.MOUSE_MOVE) {
      if (this.#selectedNodeType > 0) {
        this.setTipsText('释放后完成修改')
        const position = this._context.viewer.scene.pickPosition(event.endPosition)
        if (!position) {
          return
        }
        if (this.#selectedNodeType === 1) {
          this._changeCenterPosition(position)
        } else if (this.#selectedNodeType === 2) {
          this._chagnRadius(position)
        }
        if (this._markerId) {
          this._context.dispatch(CustomEvent.MarkerPositionChanged, {
            id: this._markerId,
            position: this.getGisPosition()
          })
        }
      } else {
        const object = this._context.viewer.scene.pick(event.endPosition)
        if (object !== undefined && object.id !== undefined && object.id instanceof Cesium.Entity) {
          if (object.id.id === this.#centerNodeEntity.id) {
            this.setTipsText('拖动修改圆心位置')
          } else if (object.id.id === this.#borderNodeEntity.id) {
            this.setTipsText('拖动修改圆半径')
          } else {
            this.setTipsText('')
          }
        } else {
          this.setTipsText('')
        }
      }
    } else if (type === Cesium.ScreenSpaceEventType.RIGHT_CLICK) {

    } else if (type === Cesium.ScreenSpaceEventType.LEFT_UP) {
      this.pressLeft = false
      this.#selectedNodeType = -1
    }
    super.onCesiumEvent(type, event)
  }

  /**
   * 获取标注三维坐标
   * @returns 标注三维坐标
   */
  getPosition () {
    return this._viewEntity.polygon.hierarchy.positions.getValue(Cesium.JulianDate.now())
  }

  /**
   * 获取标注gis坐标
   * @returns 标注gis坐标
   */
  getGisPosition () {
    return this.#gisPosition
  }

  /**
   * 设置标注gis坐标
   * @param {Array} gisPosition 标注gis坐标
   */
  setGisPosition (gisPosition) {
    this.#gisPosition = JSON.parse(JSON.stringify(gisPosition))
    let centerPos = Cesium.Cartesian3.fromDegrees(this.#gisPosition.centerPosition[0], this.#gisPosition.centerPosition[1], this.#gisPosition.centerPosition[2])
    centerPos = this._viewer.scene.clampToHeight(centerPos) ?? centerPos
    this.#centerNodeEntity.position = centerPos
    let borderPos = this._viewEntity.polyline.positions.getValue(Cesium.JulianDate.now())[0]
    borderPos = this._viewer.scene.clampToHeight(borderPos) ?? borderPos
    this.#borderNodeEntity.position = borderPos
  }

  destroy () {
    if (this.#centerNodeEntity) {
      this._viewer.entities.remove(this.#centerNodeEntity)
      this.#centerNodeEntity = null
    }
    if (this.#borderNodeEntity) {
      this._viewer.entities.remove(this.#borderNodeEntity)
      this.#borderNodeEntity = null
    }
    super.destroy()
  }

  static fromMarker (context, marker) {
    let pos = marker.getProperties('center_position_')
    pos = typeof pos === 'string' ? JSON.parse(pos) : pos
    const gisPos = {
      centerPosition: pos,
      radius: parseFloat(marker.getProperties('radius_'))
    }
    const editor = new CircleEditor(context, gisPos)
    editor.markerId = marker.id
    if (!marker.selected) {
      editor.setStyle(marker.curStyles)
    } else {
      editor.setStyle(marker.lastStyles)
    }
    return editor
  }

  static fromGeoJson (context, geoJson) {
    const coordinates = JSON.parse(JSON.stringify(geoJson.geometry.coordinates))
    if (!coordinates[2] && geoJson.properties.height_) {
      coordinates[2] = JSON.parse(geoJson.properties.height_)[0]
    }
    const gisPos = {
      centerPosition: geoJson.properties.centerPosition,
      radius: geoJson.properties.centerPosition.radius
    }
    const editor = new CircleEditor(context, gisPos)
    editor.markerId = geoJson.properties.mark_id_
    return editor
  }
}

export default CircleEditor
