/**
 * duration 当前站点到下一站点时间
 * waitTime 站点停留时间
 * point 站点位置
 * heading 站点方位角
 * pitch 站点俯仰角
 * roll 翻滚角
 * name 站点名称
 */

class RouteStop {
  constructor (options) {
    this._duration = options.duration || 2
    this._waitTime = options.waitTime || 1
    this._point = options.point
    this._direction = options.direction
    this._heading = options.heading || 0
    this._pitch = options.pitch || 0
    this._roll = options.roll || 0
    this._name = options.name || ''
    this._speed = options.speed || 0
  }

  get duration () {
    return this._duration
  }

  set duration (v) {
    if (typeof v !== 'number') {
      throw new Error('duration不是数值类型')
    }
    if (v <= 0) {
      throw new Error('duration需要大于0')
    }
    this._duration = v
  }

  get speed () {
    return this._speed
  }

  set speed (v) {
    if (typeof v !== 'number') {
      throw new Error('speed不是数值类型')
    }
    if (v < 0) {
      throw new Error('speed需要大于0')
    }
    this._speed = v
  }

  get waitTime () {
    return this._waitTime
  }

  set waitTime (v) {
    if (typeof v !== 'number') {
      throw new Error('waitTime不是数值类型')
    }
    if (v <= 0) {
      throw new Error('waitTime需要大于0')
    }
    this._waitTime = v
  }

  get point () {
    return this._point
  }

  set point (v) {
    if (!(v instanceof Cesium.Cartesian3)) {
      throw new Error('point不是标准的Cesium.Cartesian3')
    }
    this._point = v
  }

  get direction () {
    return this._direction
  }

  set direction (v) {
    this._direction = v
  }

  get heading () {
    return this._heading
  }

  set heading (v) {
    if (typeof v !== 'number') {
      throw new Error('heading不是数值类型')
    }
    this._heading = v
  }

  get pitch () {
    return this._pitch
  }

  set pitch (v) {
    if (typeof v !== 'number') {
      throw new Error('pitch不是数值类型')
    }
    this._pitch = v
  }

  get roll () {
    return this._roll
  }

  set roll (v) {
    if (typeof v !== 'number') {
      throw new Error('roll不是数值类型')
    }
    this._roll = v
  }

  get name () {
    return this._name
  }

  set name (v) {
    this._name = v
  }
}
export default RouteStop
