import request from '@/api/request'
const userName = sessionStorage.getItem('userName')

// 获取农场名称
export async function getFarmInfo() {
    const res = await request({
        url: '/static/data/base.json',
        method: 'get'
    })
    return res[userName]['baseInfo']
}