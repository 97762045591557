import * as turf from '@turf/turf'
// 旋转动画
class RotateAnimation {
  constructor (viewer, options) {
    this._viewer = viewer
    const _options = Cesium.defaultValue(options, {})
    this._center = Cesium.defaultValue(_options.center, [0, 0]) // 圆心
    this._period = Cesium.defaultValue(_options.period, 4) // 旋转一圈周期(s)
    this._frequency = Cesium.defaultValue(_options.frequency, 16) // 频率(ms)
    this._isAutoPlay = Cesium.defaultValue(_options.isAutoPlay, true) // 是否自动播放
  }

  play () {
    if (this._timer) {
      return
    }
    const speed = 2 * Math.PI / (this._period * 1000) * this._frequency
    this.curAngle = 0
    this._timer = setInterval(() => {
      this.curAngle += speed
      if (this.curAngle > 2 * Math.PI) {
        this.curAngle = this.curAngle - (2 * Math.PI)
      }
    }, this._frequency)
    this.playPolygon()
  }

  playPolygon () {
    if (!this.marker || !this.marker.entity || !this.marker.entity.polygon) {
      return
    }
    const geoStr = this.marker.toGeoJson()
    if (!geoStr) {
      return
    }
    const geoJson = JSON.parse(geoStr)
    const options = { pivot: this._center }
    const self = this
    this.marker.entity.polygon.hierarchy = new Cesium.CallbackProperty(() => {
      const degree = Cesium.Math.toDegrees(self.curAngle)
      const rotatedPoly = turf.transformRotate(geoJson, degree, options)
      const coord = rotatedPoly.geometry.coordinates[0]
      const pos = []
      for (let i = 0; i < coord.length - 1; i++) {
        pos.push(Cesium.Cartesian3.fromDegrees(coord[i][0], coord[i][1], coord[i][2]))
      }
      return new Cesium.PolygonHierarchy(pos)
    }, false)
  }

  stopPolygon () {
    this.marker.entity.polygon.hierarchy = this.marker.entity.polygon.hierarchy.getValue(Cesium.JulianDate.now())
  }

  stop () {
    if (!this._timer) {
      return
    }
    clearInterval(this._timer)
    this._timer = null
    this.stopPolygon()
  }

  /**
   * 绑定标注
   * @param {Marker} marker 标注
   */
  bindMarker (marker) {
    this.marker = marker
    if (this._isAutoPlay) {
      this.play()
    }
  }

  /**
   * 销毁特效
   */
  destroy () {
    this.stop()
    this.marker = null
  }
}

export default RotateAnimation
