import * as turf from '@turf/turf'

const Cesium = window.Cesium

/**
 * 贴地采样
 */
class ClampSample {
  _clamp = undefined
  constructor (viewer, entity) {
    if (entity.position instanceof Cesium.SampledPositionProperty) {
      const positionProperty = entity.position
      entity.orientation = new Cesium.VelocityOrientationProperty(positionProperty)
      const objectsToExclude = [entity]
      this._clamp = viewer.scene.postRender.addEventListener(() => {
        const position = positionProperty.getValue(viewer.clock.currentTime)
        entity.position = viewer.scene.clampToHeight(position, objectsToExclude)
      })
    } else {
      throw new Error('entity.position 属性必须是 Cesium.SampledPositionProperty 类型')
    }
  }

  destroy () {
    this._clamp && this._clamp()
    this._clamp = undefined
  }
}

/**
 * 第一人称跟踪
 */
class FirstPersonTrace {
  _entity = undefined
  _trace = undefined
  constructor (viewer, entity) {
    this._entity = entity
    this._entity.show = false

    this._trace = viewer.scene.preUpdate.addEventListener(() => {
      const center = entity.position.getValue(viewer.clock.currentTime)
      const orientation = entity.orientation.getValue(viewer.clock.currentTime)
      let transform = Cesium.Transforms.eastNorthUpToFixedFrame(center)
      transform = Cesium.Matrix4.fromRotationTranslation(Cesium.Matrix3.fromQuaternion(orientation), center)
      viewer.camera.lookAtTransform(transform, new Cesium.Cartesian3(-90, 0, 0.2))
    })
  }

  destroy () {
    this._trace && this._trace()
    this._trace = undefined

    this._entity && (this._entity.show = true)
    this._entity = undefined
  }
}

class FlyPathManager {
  _ds = undefined
  _context = undefined
  _viewer = undefined
  _lastCameraPos = undefined
  _clamp = undefined
  /**
   * 跟踪视角处理器
   */
  _trace = undefined

  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer
  }

  /**
* 开始飞行路径
* @param {Object} options
*/
  async play (options) {
    const opts = {
      /**
      * 飞行方式，time （按飞行时长）、distance (按飞行距离)
      */
      flyWay: 'time',
      /**
      * 持续时长，单位s ，flyWay = 'time' 时有效
      */
      duration: 60,
      /**
    * 速度 km/h，flyWay = 'distance' 时有效
    */
      speed: 60,
      /**
      * 飞行路径 [lng,lat,alt]
      */
      paths: [],
      /**
      * 是否贴地飞行，NONE（无）、CLAMP_TO_MODEL（贴模型）、CLAMP_TO_GROUND（贴地）
      */
      clampToGround: 'NONE',
      /**
      * 飞行漫游视角：track（跟踪视角）、side（侧方视角）
      */
      perspective: 'track',
      /**
      * 时间倍频
      */
      multiplier: 1,
      /**
      * 飞行模型gltf地址（必须存在）
      */
      gltf: '',
      /**
      * 合并 czml
      * @param {*} czml
      * @returns
      */
      merge: function (czml) {
        return czml
      },
      /**
      * 播放停止回调函数
      */
      onStop: function () {
        console.log('clock stop')
      },
      ...options
    }
    if (!opts.gltf) throw new Error('gltf 必须存在')
    if (!opts.paths || opts.paths.length < 2) throw new Error('paths 长度必须大于等于2')

    this._lastCameraPos = {
      position: this._viewer.scene.camera.position,
      direction: this._viewer.scene.camera.direction,
      up: this._viewer.scene.camera.up,
      heading: this._viewer.scene.camera.heading,
      pitch: this._viewer.scene.camera.pitch,
      roll: this._viewer.scene.camera.roll
    }

    this.paths = opts.paths

    const ds = await Cesium.CzmlDataSource.load(this._createCzml(opts, opts.merge))

    this._viewer.dataSources.add(ds)

    this._destroyClampSample()
    this._destroyFirstPersonTrace()

    if (opts.clampToGround === 'CLAMP_TO_MODEL') {
      opts.clampToGround = 'NONE'
      this._clamp = new ClampSample(this._viewer, ds.entities.getById('path'))
    }

    if (opts.onStop) {
      const remove = this._viewer.clock.onStop.addEventListener(() => {
        // 先触发，后移除事件
        opts.onStop()
        remove()
      })
    }

    this._ds = ds
    this.perspective = opts.perspective
    return ds
  }

  /**
  * 暂停播放
  */
  pause (animate = false) {
    if (this._viewer.clock.shouldAnimate) this._viewer.clock.shouldAnimate = false
  }

  /**
  * 恢复
  */
  restore () {
    if (!this._viewer.clock.shouldAnimate) this._viewer.clock.shouldAnimate = true
  }

  /**
* 停止飞行漫游
*/
  stop () {
    this._destroyClampSample()
    this._destroyFirstPersonTrace()
    this._viewer.dataSources.remove(this._ds, true)
    this._viewer.cameraEntity = undefined
    this._ds = undefined
  }

  /**
  * 飞行漫游视角：track（跟踪视角）、side（侧方视角）、first（第一人称视角）
  */
  set perspective (str) {
    if (!this._ds) return

    this._destroyFirstPersonTrace()

    if (/^t/i.test(str)) {
      // track（跟踪视角）
      const entity = this._ds.entities.getById('path')
      this._viewer.trackedEntity = entity
    } else if (/^s/i.test(str)) {
      // track（侧方视角）
      this._viewer.trackedEntity = undefined
      const geoJson = turf.lineString(this.paths)
      this._context.cameraManager.zoomToGeometry(geoJson, {
        scale: 0.35,
        offset: Cesium.HeadingPitchRoll.fromDegrees(0, -10, 0)
      })
    } else if (/^f/i.test(str)) {
      // first（第一人称视角）
      const entity = this._ds.entities.getById('path')
      this._viewer.trackedEntity = entity

      this._trace = new FirstPersonTrace(this._viewer, entity)
    }
  }

  set multiplier (v) {
    this._viewer.clock.multiplier = v
  }

  /**
* 创建Czml
* @param {*} options
*/
  _createCzml (options, cb) {
    const { paths, flyWay, multiplier, speed, gltf, clampToGround } = options
    let { duration } = options
    const calcDistance = function (arr1, arr2) {
      const left = Cesium.Cartesian3.fromDegrees(arr1[0], arr1[1], arr1[2], Cesium.Ellipsoid.WGS84)
      const right = Cesium.Cartesian3.fromDegrees(arr2[0], arr2[1], arr2[2], Cesium.Ellipsoid.WGS84)
      return Cesium.Cartesian3.distance(left, right)
    }
    // 总长度，单位m
    const distance = paths.reduce(function (total, val, index, arr) {
      if (index === 0) return total
      return total + calcDistance(val, arr[index - 1])
    }, 0)
    const cartographicDegrees = []
    if (/^t/i.test(flyWay)) {
      // time （按飞行时长）
    } else if (/^d/i.test(flyWay)) {
      // distance (按飞行速度)
      // 通过总长度与飞行速度计算飞行时长
      duration = distance / (speed * 1000 / 3600)
    } else {
      throw new Error(`${flyWay} 不能被争取处理`)
    }
    for (let i = 0, t = 0; i < paths.length; i++) {
      const path = paths[i]
      if (i === 0) {
        Array.prototype.push.apply(cartographicDegrees, [t, path[0], path[1], path[2] || 0])
        continue
      }
      // 计算两点间的距离
      const d2 = calcDistance(path, paths[i - 1])
      // 算法原理：当前两点间距离与总距离的比值乘以总时长，得到占比时长
      t += d2 / distance * duration
      Array.prototype.push.apply(cartographicDegrees, [t, path[0], path[1], path[2] || 0])
    }
    // debugger
    const originTime = Cesium.JulianDate.fromDate(new Date())
    const startTime = Cesium.JulianDate.toIso8601(originTime)
    const endTime = Cesium.JulianDate.toIso8601(Cesium.JulianDate.addSeconds(originTime, duration, new Cesium.JulianDate()))
    const czml = [
      {
        id: 'document',
        version: '1.0',
        clock: {
          interval: startTime + '/' + endTime,
          currentTime: startTime,
          multiplier,
          step: 'SYSTEM_CLOCK_MULTIPLIER',
          range: 'CLAMPED'
        }
      },
      {
        id: 'path',
        availability: startTime + '/' + endTime,
        model: {
          gltf,
          scale: 1,
          minimumPixelSize: 36,
          maximumScale: 80,
          heightReference: clampToGround || 'NONE',
          clampAnimations: false
        },
        viewFrom: {
          cartesian: [-80, -50, 20]
        },
        // viewFrom: {
        //   cartesian: [-2080, -1715, 779]
        // },
        position: {
          epoch: startTime,
          interpolationAlgorithm: 'LAGRANGE',
          interpolationDegree: 1,
          cartographicDegrees
        },
        orientation: {
          velocityReference: '#position'
        }
      }
    ]
    return cb ? cb(czml) : czml
  }

  /**
  * 销毁贴地采样
  */
  _destroyClampSample () {
    this._clamp && this._clamp.destroy()
    this._clamp = undefined
  }

  /**
  * 销毁第一人称视角跟踪
  */
  _destroyFirstPersonTrace () {
    this._trace && this._trace.destroy()
    this._trace = undefined
  }

  /**
   * 第一人称视角跟踪
   */
  #firstPersonTraceHandler (scens, cc2, cc3) {
    // const center = this.nodeAnimationModel.position.getValue(
    //   viewer.clock.currentTime
    // )
    // const orientation = this.nodeAnimationModel.orientation.getValue(
    //   viewer.clock.currentTime
    // )
    // let transform = Cesium.Transforms.eastNorthUpToFixedFrame(center)
    // transform = Cesium.Matrix4.fromRotationTranslation(Cesium.Matrix3.fromQuaternion(orientation), center)
    // viewer.camera.lookAtTransform(transform, new Cesium.Cartesian3(-100, 0, 50))
    console.log('cccccc')
  }
}

export default FlyPathManager
