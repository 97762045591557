
var defaultColor = Cesium.Color.WHITE
var defaultFlowLength = 0.1
var defaultTotalTime = 2

/**
 * 流动线条纹理
 * @constructor
 *
 * @param {Object} [options] Object with the following properties:
 * @param {Property} [options.color=Color.WHITE] 线条颜色
 * @param {Property} [options.flowLength=0.1] 流动可见区域长度.
 * @param {Property} [options.totalTime=2] 流动一个周期消耗时间
 */
function PolylineFlowMaterialProperty2 (options) {
  this._definitionChanged = new Cesium.Event()

  const _options = Cesium.defaultValue(options, {})
  this._image = Cesium.defaultValue(_options.image, '../Assets/Textures/baise.png')
  this._color = Cesium.defaultValue(_options.color, Cesium.Color.WHITE)
  this._horizontalSpeed = Cesium.defaultValue(_options.horizontalSpeed, 100) / 1000
  this._verticalSpeed = Cesium.defaultValue(_options.verticalSpeed, 100) / 1000
  this._horizontalRepeat = Cesium.defaultValue(_options.horizontalRepeat, 1)
  this._verticalRepeat = Cesium.defaultValue(_options.verticalRepeat, 1)

  this.time = new Date().getTime()
}

Object.defineProperties(PolylineFlowMaterialProperty2.prototype, {
  /**
   * Gets a value indicating if this property is constant.  A property is considered
   * constant if getValue always returns the same result for the current definition.
   * @memberof PolylineFlowMaterialProperty2.prototype
   * @type {Boolean}
   * @readonly
   */
  isConstant: {
    get: function () {
      return true
    }
  },
  /**
   * Gets the event that is raised whenever the definition of this property changes.
   * The definition is considered to have changed if a call to getValue would return
   * a different result for the same time.
   * @memberof PolylineFlowMaterialProperty2.prototype
   * @type {Event}
   * @readonly
   */
  definitionChanged: {
    get: function () {
      return this._definitionChanged
    }
  },
})

PolylineFlowMaterialProperty2.prototype.getType = function (time) {
  return 'PolylineFlow2'
}

PolylineFlowMaterialProperty2.prototype.getValue = function (time, result) {
  if (!Cesium.defined(result)) {
    result = {}
  }
  result.image = this._image
  result.color = this._color
  result.horizontalRepeat = this._horizontalRepeat
  result.verticalRepeat = this._verticalRepeat

  // result.width = this._width
  // result.length = this._length
  // result.speed = this._speed
  if (!this._horizontalSpeed) {
    result.horizontalTime = 0.0
  } else {
    const horizontalTime = (1 / this._horizontalSpeed) * 1000
    result.horizontalTime = ((new Date().getTime() - this.time) % horizontalTime) / horizontalTime
  }

  if (!this._verticalSpeed) {
    result.verticalTime = 0.0
  } else {
    const verticalTime = (1 / this._verticalSpeed) * 1000
    result.verticalTime = ((new Date().getTime() - this.time) % verticalTime) / verticalTime
  }

  // const totalTime = this._totalTime
  // result.time = ((new Date().getTime() - this.time) % totalTime) / totalTime
  return result
}

/**
 * Compares this property to the provided property and returns
 * <code>true</code> if they are equal, <code>false</code> otherwise.
 *
 * @param {Property} [other] The other property.
 * @returns {Boolean} <code>true</code> if left and right are equal, <code>false</code> otherwise.
 */
PolylineFlowMaterialProperty2.prototype.equals = function (other) {
  return (
    this === other ||
    (other instanceof PolylineFlowMaterialProperty2 && Cesium.Color.equals(this._color, other._color))
  )
}

Cesium.Material.PolylineFlowMaterialType2 = 'PolylineFlow2'
Cesium.Material.PolylineFlowMaterialLinkSource2 =
  `uniform sampler2D image;
  uniform vec4 color;
  uniform float horizontalRepeat;
  uniform float verticalRepeat;
  uniform float horizontalTime;
  uniform float verticalTime;

  czm_material czm_getMaterial(czm_materialInput materialInput)
  {
      czm_material material = czm_getDefaultMaterial(materialInput);

      vec2 st = materialInput.st;
      float s = fract((st.s - horizontalTime)/(1.0/horizontalRepeat));
      float t = fract((st.t - verticalTime)/(1.0/verticalRepeat));
      
      vec4 colorImage = texture2D(image, vec2(s,t));

      material.alpha = colorImage.a * color.a;
      material.diffuse = colorImage.rgb * color.rgb;

      // material.alpha = 1.0;
      // if(colorImage.a<=0.1)
      //   material.diffuse = colorImage.rgb * color.rgb;
      // else
      //   material.diffuse = vec3(1.0,1.0,1.0);

      return material;
  }`

Cesium.Material._materialCache.addMaterial(Cesium.Material.PolylineFlowMaterialType2, {
  fabric: {
    type: Cesium.Material.WallCommonMaterailType,
    uniforms: {
      image: '../Assets/Textures/baise.png',
      color: Cesium.Color.WHITE,
      horizontalSpeed: 0,
      verticalSpeed: 0,
      horizontalRepeat: 1,
      horizontalTime: 0,
      verticalRepeat: 1,
      verticalTime: 0
    },
    source: Cesium.Material.PolylineFlowMaterialLinkSource2
  },
  translucent: true
})
Cesium.PolylineFlowMaterialProperty2 = PolylineFlowMaterialProperty2
