/**
 * 热力图
 */
import CesiumHeatmap from './CesiumHeatmap'

class HeatMap {
  /**
   *
   * @param {Cesium.Viewer} viewer cesium viewer
   * @param {Object} options 配置参数
   * @param {Object} options.bounds 热力图显示区域
   * @param {Number} options.valueMin 数据最小值
   * @param {Number} options.valueMax 数据最大值
   * @param {Number} options.blur 模糊度
   * @param {Number} options.radius 半径
   * @param {Number} options.minOpacity 最小透明度
   * @param {Number} options.maxOpacity 最大透明度
   * @param {String} options.xField 数据经度字段名称
   * @param {String} options.yField 数据纬度字段名称
   * @param {String} options.valueField 数据热力值字段名称
   * @param {Number} options.layerType 图层显示位置，0模型与影像都显示，1只影像层显示，2只模型上显示，
   */
  constructor (viewer, options) {
    const _options = Cesium.defaultValue(options, {})
    this._viewer = viewer
    this._bounds = Cesium.defaultValue(_options.bounds, { west: 95.68306, east: 109.27943, south: 25.334721, north: 35.62204 })
    this._valueMin = Cesium.defaultValue(_options.valueMin, 0)
    this._valueMax = Cesium.defaultValue(_options.valueMax, 100)
    this._blur = Cesium.defaultValue(_options.blur, 0.75)
    this._radius = Cesium.defaultValue(_options.radius, 40)
    this._minOpacity = Cesium.defaultValue(_options.minOpacity, 0)
    this._maxOpacity = Cesium.defaultValue(_options.maxOpacity, 0.5)
    this._xField = Cesium.defaultValue(_options.xField, 'x')
    this._yField = Cesium.defaultValue(_options.yField, 'y')
    this._valueField = Cesium.defaultValue(_options.valueField, 'value')
    this._gradient = Cesium.defaultValue(_options.gradient, {
      '.3': 'blue',
      '.65': 'yellow',
      '.8': 'orange',
      '.95': 'red'
    })
    this._layerType = Cesium.defaultValue(_options.layerType, 0) // 设置热力图图层位置，0模型与影像都显示，1只影像层显示，2只模型上显示，

    // 初始化热力图
    this._heatMap = CesiumHeatmap.create(this._viewer, this._bounds, {
      blur: this._blur,
      radius: this._radius,
      minOpacity: this._minOpacity,
      maxOpacity: this._maxOpacity,
      xField: this._xField,
      yField: this._yField,
      valueField: this._valueField,
      gradient: this._gradient
    })

    // if(this._layerType === 1) {
    //   CesiumHeatmap.defaults.useEntitiesIfAvailable = false
    // } else {
    //   CesiumHeatmap.defaults.useEntitiesIfAvailable = true
    // }
  }

  /**
   * 设置热力图数据
   * @param {Object[]} data 热力图数据
   */
  setData (data) {
    // let data = [{"x":104.059896,"y":30.584782,"value":76},{"x":104.051,"y":30.51,"value":21},]
    this._heatMap.setWGS84Data(this._valueMin, this._valueMax, data)
    // this._viewer.scene.camera.flyTo({
    //   destination: Cesium.Rectangle.fromDegrees(this._bounds.west, this._bounds.south, this._bounds.east, this._bounds.north)
    // })

    // 设置热力图显示类型
    if (this._layerType === 2) {
      this._heatMap._layer.rectangle.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
    } else if (this._layerType === 1) {
      this._heatMap._layer.rectangle.classificationType = Cesium.ClassificationType.TERRAIN
    } else {
      this._heatMap._layer.rectangle.classificationType = Cesium.ClassificationType.BOTH
    }
  }

  /**
   * 获取热力图图层
   * @returns {Cesium.Entity|Cesium.ImageryLayer} 热力图entity
   */
  getLayer () {
    return this._heatMap._layer
  }

  /**
   * 销毁热力图
   */
  destroy () {
    this._viewer.entities.remove(this._heatMap._layer)
    this._heatMap = null
  }

  /**
   * 设置热力图样式
   * @param {*} options
   */
  setStyle (options) {
    if (!this._heatMap || !this._heatMap._heatmap) {
      return
    }
    // const _options = Cesium.defaultValue(options, {})
    // _options.gradient = Cesium.defaultValue(options.gradient, this._gradient)
    // _options.radius = Cesium.defaultValue(options.radius, this._radius)
    // if (options.radius) {
    //   this._radius = options._radius
    // }
    if (options.gradient) {
      this._gradient = options.gradient
    }

    this._heatMap._heatmap.configure(options)
  }

  /**
   * 获取热力图样式
   * @returns 热力图样式
   */
  getStyle () {
    return {
      radius: this._radius,
      gradient: this._gradient
    }
  }

  show (isShow) {
    this._heatMap._layer.show = isShow
  }
}

export default HeatMap
