import MarkerStyle from './MarkerStyle'
/**
 * 文字标注style类
 */
class LabelStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'label'
    this._font = _options.font ? _options.font : '12px Arial'
    this._style = Cesium.defaultValue(_options.style, Cesium.LabelStyle.FILL)
    this._pixelOffset = _options.pixelOffset ? _options.pixelOffset : new Cesium.Cartesian2(0, -50)
    this._eyeOffset = Cesium.defaultValue(_options.eyeOffset, Cesium.Cartesian3.ZERO)
    this._horizontalOrigin = Cesium.defaultValue(_options.horizontalOrigin, Cesium.HorizontalOrigin.CENTER)
    this._verticalOrigin = Cesium.defaultValue(_options.verticalOrigin, Cesium.VerticalOrigin.CENTER)
    this._showBackground = Cesium.defaultValue(_options.showBackground, true)
    this._backgroundPadding = Cesium.defaultValue(_options.backgroundPadding, new Cesium.Cartesian2(15, 7))
    this._fillColor = _options.fillColor ? _options.fillColor : Cesium.Color.BLACK
    this._backgroundColor = _options.backgroundColor ? _options.backgroundColor : Cesium.Color.fromCssColorString('#272829')
    this._outlineColor = Cesium.defaultValue(_options.outlineColor, Cesium.Color.fromCssColorString('#000000'))
    this._outlineWidth = Cesium.defaultValue(_options.outlineWidth, 1)
    this._backgroundImage = Cesium.defaultValue(_options.backgroundImage, undefined)
  }

  decorate (entity) {
    if (!entity.label) {
      return
    }
    entity.label.style = this._style
    entity.label.font = this._font
    entity.label.pixelOffset = this._pixelOffset
    entity.label.fillColor = this._fillColor
    entity.label.eyeOffset = this._eyeOffset
    entity.label.backgroundColor = this._backgroundColor
    entity.label.outlineColor = this._outlineColor
    entity.label.outlineWidth = this._outlineWidth
    entity.label.showBackground = this._showBackground
    entity.label.backgroundImage = this._backgroundImage
    entity.label.backgroundPadding = this._backgroundPadding
  }

  equals (other) {
    return this === other || (
      other instanceof LabelStyle &&
        this._type === other._type &&
        this._font === other._font &&
        this._style === other._style &&
        this._pixelOffset === other._pixelOffset &&
        this._eyeOffset === other._eyeOffset &&
        this._horizontalOrigin === other._horizontalOrigin &&
        this._verticalOrigin === other._verticalOrigin &&
        this._showBackground === other._showBackground &&
        Cesium.Color.equals(this._fillColor, other._fillColor) &&
        Cesium.Color.equals(this._backgroundColor, other._backgroundColor) &&
        Cesium.Color.equals(this._outlineColor, other._outlineColor) &&
        this._outlineWidth === other._outlineWidth
    )
  }

  static Default () {
    return new LabelStyle({
      font: '12px Arial',
      pixelOffset: new Cesium.Cartesian2(0, -50),
      eyeOffset: new Cesium.Cartesian3(0, 0, 1)
    })
  }

  static Selected () {
    return new LabelStyle({
      font: '12px Arial',
      pixelOffset: new Cesium.Cartesian2(0, -50),
      eyeOffset: new Cesium.Cartesian3(0, 0, 1)
    })
  }

  static Fill () {
    return new LabelStyle({
      font: '12px Arial',
      pixelOffset: new Cesium.Cartesian2(0, -50),
      eyeOffset: new Cesium.Cartesian3(0, 0, 1),
      showBackground: false,
      fillColor: Cesium.Color.fromCssColorString('#FFFFFF'),
      outlineColor: Cesium.Color.fromCssColorString('#000000'),
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      outlineWidth: 30
    })
  }
}

export default LabelStyle
