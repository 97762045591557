/**
 * 绘制模式
 * Point 绘制点
 * Polyline 绘制线
 * Polygon 绘制面
 * Marker 绘制标记
 * Circle 圆
 * Segment 线段
 * Curve 曲线
 * Hybird 混合
 * @type {{Polyline: number, Point: number, Marker: number, Polygon: number, Wall: number, Circle: number, Segment: number, Curve: number, Hybird: number}}
 */
const DrawMode = {
  Point: 1,
  Polyline: 2,
  Polygon: 3,
  Marker: 4,
  Wall: 5,
  Circle: 6,
  Segment: 7,
  Curve: 8,
  Hybird: 10000
}
export default Object.freeze(DrawMode)
