import MarkerStyle from './MarkerStyle'
/**
 * 围墙标注style类
 */
class HeatStyle extends MarkerStyle {
  constructor (options) {
    super()
    const _options = options || {}
    this._type = 'heatMap'
    this.radius = Cesium.defaultValue(_options.radius, 50)
    this.gradient = Cesium.defaultValue(_options.gradient, undefined)
  }

  decorate (entity) {

  }

  equals (other) {
    return this === other || (
      other instanceof HeatStyle &&
        this._type === other._type &&
        this.radius === other.radius &&
        this.gradient === other.gradient
    )
  }

  static Default () {
    return new HeatStyle({

    })
  }

  static Selected () {
    return new HeatStyle({

    })
  }
}

export default HeatStyle
