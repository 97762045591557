/**
 * 飞行路线类
 * @param {Object} options 可选参数
  * @param {String} options.name 线路名称
  * @param {Date} options.originTime 起始时间
  * @param {Boolean} options.isClampToGround 是否贴地飞行
  * @param {Number} options.multiplier 时间加速比例
  * @param {Array} options.positions 路径经纬度数组[[经度,纬度,高度],[经度,纬度,高度]]
  * @param {Array} options.times 路径时间数组
  * @param {Boolean} options.isLoop 是否循环
  * @param {Boolean} options.isShowPath 是否显示路径
  * @param {Boolean} options.isTrack 是否跟踪模型
  * @param {String} options.modelUrl 模型地址
  * @param {Number} options.maximumScale 放大倍数
  * @param {Boolean} options.isShowModel 是否显示模型
  * @param {Number} options.smooth 路径平滑度
 */
const Cesium = window.Cesium

class FlyPath {
  constructor (options = {}) {
    const _options = Cesium.defaultValue(options, {})
    this.name = Cesium.defaultValue(_options.name, '自定义路径')
    this.positions = Cesium.defaultValue(_options.positions, [])
    this.times = Cesium.defaultValue(_options.times, [])
    this.originTime = Cesium.JulianDate.fromDate(Cesium.defaultValue(_options.originTime, new Date()), new Cesium.JulianDate())
    // let duration = Cesium.defaultValue( _options.duration, 60*1);
    this.multiplier = Cesium.defaultValue(_options.multiplier, 1)
    this.isClampToGround = Cesium.defaultValue(_options.isClampToGround, false)
    this.heightReference = 'NONE'
    if (this.isClampToGround === true) { this.heightReference = 'CLAMP_TO_GROUND' }
    this.isLoop = Cesium.defaultValue(_options.isLoop, true)
    this.clockRange = 'UNBOUNDED'// CLAMPED
    if (this.isLoop === true) { this.clockRange = 'LOOP_STOP' }
    this.isShowPath = Cesium.defaultValue(_options.isShowPath, false)
    this.isTrack = Cesium.defaultValue(_options.isTrack, true)
    this.modelUrl = Cesium.defaultValue(_options.modelUrl, '')
    this.maximumScale = Cesium.defaultValue(_options.maximumScale, 100)
    this.smooth = Cesium.defaultValue(_options.smooth, 1)
    this.pointSize = 15
    this.isShowModel = Cesium.defaultValue(_options.isShowModel, true)
    if (this.isShowModel === false) {
      this.maximumScale = 0
      this.pointSize = 0
    }
  }

  /**
   * 根据选项创建czml对象
   */
  getCzmlObject () {
    const startTime = Cesium.JulianDate.toIso8601(this.originTime)
    // let endTime = Cesium.JulianDate.toIso8601( Cesium.JulianDate.addSeconds(originTime, duration, new Cesium.JulianDate()));
    const modelPositons = []
    const polylinePositions = []
    let totalTime = 0
    for (let i = 0; i < this.positions.length; i++) {
      const point = this.positions[i]
      totalTime = totalTime + this.times[i]
      modelPositons.push(totalTime)
      for (let j = 0; j < point.length; j++) {
        modelPositons.push(point[j])
        polylinePositions.push(point[j])
      }
      // totalTime = times[i];
    }
    const endTime = Cesium.JulianDate.toIso8601(Cesium.JulianDate.addSeconds(this.originTime, totalTime, new Cesium.JulianDate()))

    const head =
    {
      id: 'document',
      name: this.name,
      version: '1.0',
      clock:
      {
        interval: startTime + '/' + endTime,
        currentTime: startTime,
        multiplier: this.multiplier,
        range: this.clockRange
      }
    }
    const polyline =
    {
      id: 'polyline',
      polyline:
      {
        show: this.isShowPath,
        material: {
          polylineOutline: {
            color: {
              rgba: [255, 0, 255, 255]
            },
            outlineColor: {
              rgba: [0, 255, 255, 255]
            },
            outlineWidth: 1
          }
        },
        width: 2,
        positions: { cartographicDegrees: polylinePositions },
        clampToGround: this.isClampToGround
      }
    }
    const point = {
      id: 'point',
      availability: startTime + '/' + endTime,
      position: {
        interpolationAlgorithm: 'LAGRANGE',
        interpolationDegree: 1,
        epoch: startTime,
        cartographicDegrees: modelPositons
      },
      orientation:
      {
        velocityReference: '#position'
      },
      properties:
      {
        track: this.isTrack,
        smooth: this.smooth
      }
    }
    if (this.modelUrl !== '') {
      point.model = {
        show: true,
        gltf: this.modelUrl,
        minimumPixelSize: 100,
        maximumScale: this.maximumScale
      }
    } else {
      point.point = {
        show: true,
        pixelSize: this.pointSize,
        heightReference: this.heightReference,
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    }
    return [head, polyline, point]
  }
}

export default FlyPath
