import LayerDefine from './LayerDefine'
import AmapImageryProvider from './Amap/AmapImageryProvider'
// import BaiduImageryProvider from './Amap/BaiduImageryProvider'

class ImageryManager {
  constructor (context, viewer) {
    this._context = context
    this._viewer = viewer

    // 底图影像id
    this._baseLayerId = undefined
    // 标注层影像id
    this._labelLayerId = undefined
    // 影像自增id
    this._incremnetLayerId = LayerDefine.CustomLayerBegin
  }

  get baseLayerId () {
    return this._baseLayerId
  }

  get labelLayerId () {
    return this._labelLayerId
  }

  /**
   * 切换底图影像
   * @param {Number} layerId 影像id
   */
  switchBaseLayer (layerId) {
    if (this._baseLayerId !== undefined) {
      this.removeLayerByLayerId(this._baseLayerId)
    }
    let layer
    switch (layerId) {
      case LayerDefine.DefaultLocalImager:
        // this.AddTMSMapImagery( {
        //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
        //   id: LayerDefine.DefaultLocalImager,
        //   fileExtension: "jpg",
        //   name: '本地默认底图',
        // })
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.TileMapServiceImageryProvider({
          url: '/static/Cesium/Assets/Textures/NaturalEarthII',
        }))
        layer.layerId = LayerDefine.DefaultLocalImager
        break
      case LayerDefine.GoogleImgLayer: // 谷歌影像
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'http://mt1.google.cn/vt/lyrs=s&hl=zh-CN&x={x}&y={y}&z={z}&s=Gali'
        }), 0)
        layer.layerId = LayerDefine.GoogleImgLayer
        break
      case LayerDefine.GoogleVecLayer: // 谷歌矢量
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'http://mt1.google.cn/vt/lyrs=m@207000000&hl=zh-CN&gl=CN&src=app&x={x}&y={y}&z={z}&s=Galile'
        }), 0)
        layer.layerId = LayerDefine.GoogleVecLayer
        break
      case LayerDefine.AmapImgLayer: // 高德影像
        // layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        //   url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}'
        // }), 0)
        layer = this._viewer.imageryLayers.addImageryProvider(new AmapImageryProvider({
          style: 'img', // style: img、elec、cva
          crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
        }))
        layer.layerId = LayerDefine.AmapImgLayer
        break
      case LayerDefine.AmapVecLayer: // 高德矢量
        // layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
        //   url: 'http://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
        // }), 0)
        layer = this._viewer.imageryLayers.addImageryProvider(new AmapImageryProvider({
          style: 'elec', // style: img、elec、cva
          crs: 'WGS84' // 使用84坐标系，默认为：GCJ02
        }))
        layer.layerId = LayerDefine.AmapVecLayer
        break
      case LayerDefine.TencentVecLayer: // 腾讯矢量
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'https://rt0.map.gtimg.com/tile?z={z}&x={x}&y={reverseY}&styleid=1000&scene=0&version=347'
        }), 0)
        layer.layerId = LayerDefine.TencentVecLayer
        break
      case LayerDefine.TianDiMapImgLayer: // 天地图影像
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
          url: 'http://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
          '&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
          '&style=default&format=tiles&tk=859e010cf86575ec92544de21ad4472e',
          subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
          tilingScheme: new Cesium.GeographicTilingScheme(),
          tileMatrixLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
          maximumLevel: 17,
          layer: 'img',
          style: 'default',
          tileMatrixSetID: 'c'
        }), 0)
        layer.layerId = LayerDefine.TianDiMapImgLayer
        break
      case LayerDefine.TianDiMapVecLayer: // 天地图矢量
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
          url: 'http://{s}.tianditu.gov.cn/vec_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
            '&LAYER=vec&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
            '&style=default&format=tiles&tk=859e010cf86575ec92544de21ad4472e',
          subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
          tilingScheme: new Cesium.GeographicTilingScheme(),
          tileMatrixLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
          maximumLevel: 17,
          layer: 'vec',
          style: 'default',
          tileMatrixSetID: 'c'
        }), 0)
        layer.layerId = LayerDefine.TianDiMapVecLayer
        break
      case LayerDefine.BaiduVecLayer: // 百度矢量
        break
      case LayerDefine.BingMapImgVecLayer: // Bing影像
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.BingMapsImageryProvider({
          url: 'https://dev.virtualearth.net',
          key: 'AiotD4tAe47BWAde1EMW_KjA2T1ukjITgJlOhlvwQg0B6j00IkLJKkkPkAq4I5j_ '
        }), 0)
        layer.layerId = LayerDefine.BingMapImgVecLayer
        break
      case LayerDefine.ArcGisMapImgVecLayer: // ArcGis影像
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.ArcGisMapServerImageryProvider({
          url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer'
        }), 0)
        layer.layerId = LayerDefine.ArcGisMapImgVecLayer
        break
    }
    this._baseLayerId = layerId
    // 默认添加天地标注
    // if (layerId !== LayerDefine.AmapVecLayer) {
    //   this.switchLabelLayer(LayerDefine.TianDiMapLabelLayer)
    // }
  }

  /**
   * 切换标注层影像
   * @param {Number} layerId 影像id
   */
  switchLabelLayer (layerId) {
    if (this._labelLayerId !== undefined) {
      this.removeLayerByLayerId(this._labelLayerId)
    }
    const layerLength = this._viewer.imageryLayers.length
    let layerIndex = 0
    if (layerLength > 0) {
      layerIndex = 1
    }
    let layer
    switch (layerId) {
      case LayerDefine.TianDiMapLabelLayer: // 天地图标注层
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
          url: 'http://{s}.tianditu.gov.cn/cva_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
          '&LAYER=cva&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
          '&style=default&format=tiles&tk=859e010cf86575ec92544de21ad4472e',
          subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
          tilingScheme: new Cesium.GeographicTilingScheme(),
          tileMatrixLabels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
          maximumLevel: 17,
          layer: 'cva',
          style: 'default',
          tileMatrixSetID: 'c'
        }), layerIndex)
        layer.layerId = LayerDefine.TianDiMapLabelLayer
        break
      case LayerDefine.AmapLabelLayer: // 高德地图标注
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'https://webst02.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}'
        }), layerIndex)
        layer.layerId = LayerDefine.AmapLabelLayer
        break
      case LayerDefine.GoogleLabelLayer: // 谷歌地图标注
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'http://mt1.google.cn/vt/lyrs=h&hl=zh-CN&gl=cn&x={x}&y={y}&z={z}'
        }), layerIndex)
        layer.layerId = LayerDefine.GoogleLabelLayer
        break
      case LayerDefine.TencentLabelLayer: // 腾讯地图标注
        layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
          url: 'http://rt0.map.gtimg.com/tile?z={z}&x={x}&y={reverseY}&type=vector&styleid=2'
        }), layerIndex)
        layer.layerId = LayerDefine.TencentLabelLayer
        break
    }
    this._labelLayerId = layerId
  }

  /**
   * 获取底图影像
   * @returns
   */
  getBaseLayer () {
    if (this._baseLayerId !== undefined) {
      return this.getLayerById(this._baseLayerId)
    }
    return undefined
  }

  /**
   * 获取标注层影像
   * @returns
   */
  getLabelLayer () {
    if (this._labelLayerId) {
      return this.getLayerById(this._labelLayerId)
    }
    return undefined
  }

  /**
   * 通过影像id获取影像层
   * @param {Number} layerId 影像id
   * @returns {Cesium.ImageryLayer} 影像
   */
  getLayerById (layerId) {
    for (let i = 0; i < this._viewer.imageryLayers._layers.length; i++) {
      const layer = this._viewer.imageryLayers._layers[i]
      if (layer.layerId === layerId) {
        return layer
      }
    }
    return undefined
  }

  /**
   * 通过影像id移除影像
   * @param {Number} layerId 影像id
   */
  removeLayerByLayerId (layerId) {
    for (let i = 0; i < this._viewer.imageryLayers._layers.length; i++) {
      const layer = this._viewer.imageryLayers._layers[i]
      if (layer.layerId === layerId) {
        this._viewer.imageryLayers.remove(layer)
      }
    }
  }

  /**
   * 移除所有影像
   */
  removeAllImageryLayer () {
    this._viewer.imageryLayers.removeAll()
    this._incremnetLayerId = LayerDefine.CustomLayerBegin
  }

  autoIncrementLayerId () {
    this._incremnetLayerId++
    return this._incremnetLayerId
  }

  /**
   * 添加瓦片地图
   * @param {Object} options 可选参数
   * @param {Object} options.url 地图影像地址
   * @returns {ImageryLayer} 地图影像层
   */
  addUrlTemplateImagery (options) {
    const layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider(options))
    layer.layerId = this.autoIncrementLayerId()
    return layer
  }

  /**
   * 添加WMTS影像图层
   * @param {Object} options 可选参数
   * @param {Object} options.url 地图影像地址
   * @param {String} options.format 要从服务器检索的图像的MIME类型,默认值'image/jpeg'
   * @param {String} options.layer WMTS请求的层名
   * @param {String} options.style WMTS请求的样式名称
   * @param {String} options.tileMatrixSetID 用于WMTS请求的TileMatrixSet的标识符
   * @param {String} options.tileMatrixLabels TileMatrix中用于WMTS请求的标识符列表，每个TileMatrix级别一个。
   * @param {Number} options.tileWidth 可选，瓦片宽度，默认256
   * @param {Number} options.tileHeight 可选，瓦片高度，默认256
   * @returns {ImageryLayer} 地图影像层
   */
  addWMTSImageryMapLayer (options) {
    const layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider(options))
    layer.layerId = this.autoIncrementLayerId()
    return layer
  }

  /**
   * 添加WMS影像图层
   * @param {Object} options 参数
   * @param {Object} options.url 地图影像地址
   * @param {String} options.layer 要包含的层，用逗号分隔。
   * @param {Object} options.parameters 可选，在GetMap URL中传递给WMS服务器的其他参数。
   * @param {Number} options.tileWidth 可选，瓦片宽度，默认256
   * @param {Number} options.tileHeight 可选，瓦片高度，默认256
   * @param {String} options.crs 可选，CRS规范，与WMS规范>= 1.3.0一起使用。
   * @param {String} options.srs 可选，SRS规范，用于WMS规范1.1.0或1.1.1 。
   * @returns {ImageryLayer} 地图影像层
   */
  addWMSImageryMapLayer (options) {
    const layer = this._viewer.imageryLayers.addImageryProvider(new Cesium.WebMapServiceImageryProvider(options))
    layer.layerId = this.autoIncrementLayerId()
    return layer
  }

  destroy () {

  }
}

export default ImageryManager
