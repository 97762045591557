/**
 * div提示框
 */

class Tooltips {
  #context = undefined
  #div = undefined
  #viewer = undefined

  constructor (context, viewer) {
    this.#context = context
    this.#viewer = viewer

    const node = document.createElement('div')
    node.className = 'cesium-tooltips'
    node.style = 'position: absolute;'
    node.innerHTML = '<span></span>'

    this.#div = node
    const container = document.getElementsByClassName('cesium-widget')
    if (container && container[0]) {
      container[0].appendChild(this.#div)
    } else {
      this.viewer.container.appendChild(this.#div)
    }
  }

  setText (text) {
    this.#div.querySelector('span').innerHTML = text
    this.show(!!text)
  }

  setPosition (x, y) {
    this.#div.style.left = x + 'px'
    this.#div.style.top = y + 'px'
  }

  show (isShow) {
    if (isShow) {
      this.#div.style.display = 'block'
    } else {
      this.#div.style.display = 'none'
    }
  }

  destroy () {
    this.#div.remove()
    this.#div = null
  }
}

export default Tooltips
