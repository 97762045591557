/**
 * 绘制对象的高度模式
 * Ground 贴地模式
 * Model 贴模型模式
 * Space 空间模式
 * GroundAndModel 同时贴地和模型
 * @type {{Ground: number, Model: number, Space: number}}
 */
const ClampMode = {
  Ground: 0,
  Model: 1,
  Space: 2,
  GroundAndModel: 3
}
export default ClampMode
